import React from 'react';
import {DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import {ReactComponent as DateSvg} from '../../image/svg/calendar.svg';
import {DATE_FORMAT} from '../../utils/dictionary';
import useSetStateInputsValue from '../../hooks/useSetStateInputsValue';

interface IDatePicker {
    date?: string;
    placeholder?: string;
    label?: string;
    error?: string;
    required?: boolean;
    storeKey: string;
    isTouched?: boolean;
    setIsTouched?: (flag: boolean) => void;
}

const AntDatePicker = ({date, placeholder, label, error, required, storeKey, isTouched, setIsTouched}: IDatePicker) => {
    const {internalValue, setInternalValue} = useSetStateInputsValue(storeKey, date);

    const onChange = (_: dayjs.Dayjs, dateString: string | string[]) => {
        setInternalValue(dateString as string);
        if (!isTouched && setIsTouched) {
            setIsTouched(true);
        }
    };

    const isValidDate = (dateValue: string): dayjs.Dayjs | null => {
        return dayjs(dateValue, DATE_FORMAT).isValid() ? dayjs(dateValue, DATE_FORMAT) : null;
    };

    const handleBlur = () => {
        if (setIsTouched) {
            setIsTouched(true);
        }
    };

    const disabledDate = (current: dayjs.Dayjs) => {
        return current && current > dayjs().endOf('day');
    };

    return (
        <div className='h-[40px] w-full relative flex flex-col mb-6'>
            {label && (
                <span className='input-label z-[1000]'>
                    {label} {required && <span className='text-sm text-red'>&nbsp;*</span>}
                </span>
            )}
            <DatePicker
                onBlur={handleBlur}
                onChange={onChange}
                locale={locale}
                value={isValidDate(internalValue)}
                suffixIcon={<DateSvg />}
                format={DATE_FORMAT}
                className='ant-placeholder-color'
                placeholder={placeholder}
                style={{width: '100%', height: '40px'}}
                disabledDate={disabledDate}
            />
            {error && <p className=' absolute text-xs text-red top-[40px]'>{error}</p>}
        </div>
    );
};

export default AntDatePicker;
