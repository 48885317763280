import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { ApiKeysType } from '../../models/models';
import useAppSelector from '../../hooks/redux';
import useDataSourceParams from '../../hooks/useDataSourceParams';
import { ReactComponent as ArrowSvg } from '../../image/svg/arrow.svg';
import { EnterClick } from '../../utils/func';

const arrayList = [{ value: 100 }, { value: 250 }, { value: 500 }, { value: 1000 }];
const arrayListDefault = [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }];

interface ISelectList {
    apiKey: ApiKeysType;
    countListPagination?: boolean;
}

const SelectList = ({ apiKey, countListPagination }: ISelectList) => {
    const [isOpen, setIsOpen] = useState(false);
    const [top, setTop] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const refParent = useRef<HTMLDivElement>(null);
    const { setPerPage } = useDataSourceParams(apiKey);
    const perPage = useAppSelector((state) => state.list[apiKey].pagination.per_page);
    const [value, setValue] = useState('');

    const [coords, setCoords] = useState({
        top: 0,
        left: 0,
    });

    const handleButton = (e: React.MouseEvent<HTMLElement>) => {
        const position = (e.target as HTMLElement).getBoundingClientRect();
        setCoords({
            left: position.x + position.width / 2,
            top: position.y + window.scrollY + 21,
        });
        setIsOpen(!isOpen);
    };

    const windowHeight = window.innerHeight;
    const clientHeight = document.getElementById('height')?.clientHeight;
    const closeHandler = () => setIsOpen(false);
    useOnClickOutside(ref, closeHandler);

    useEffect(() => {
        const heightPage = clientHeight && clientHeight > windowHeight ? clientHeight : windowHeight;
        if (coords.top + 162 > heightPage) {
            setTop(true);
        } else {
            setTop(false);
        }
    }, [coords, windowHeight, clientHeight]);

    const onClick = (item: number) => {
        if (perPage !== item) {
            setPerPage(item);
            setIsOpen(false);
            setValue('');
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (Number(newValue) > 1000) {
            setValue('1000');
        }
    };

    const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (EnterClick(e)) {
            const enteredValue = e.currentTarget.value.trim();
            const numericValue = Number(enteredValue);
            if (enteredValue === '' || numericValue < 100) {
                setPerPage(100);
                setValue('100');
            } else if (numericValue > 1000) {
                setPerPage(1000);
                setValue('1000');
            } else if (!isNaN(numericValue)) {
                setPerPage(numericValue);
                setValue(String(numericValue));
            }
        }
    };

    const arrayFlagPagination = countListPagination ? arrayList : arrayListDefault;

    return (
        <div className='flex items-center'>
            <div ref={refParent}>
                <div ref={ref} className='flex items-center justify-center relative'>
                    <div
                        className='cursor-pointer flex items-center'
                        role='presentation'
                        onClick={(e) => handleButton(e)}
                    >
                        <span className='text-sm text-[#4A5568] mr-2'>Показать: </span>
                        <span className='pagination-select-text'>
                            <span>{perPage}</span>
                            <ArrowSvg className='ml-1.5 w-[12px] h-[7px] text-blue hover:text-blueHover' />
                        </span>
                    </div>
                    {isOpen && (
                        <div
                            style={{ top: top ? -150 : 35 }}
                            className={`absolute right-0 w-[120px] h-auto border-[2px] border-blue rounded-lg bg-white`}
                        >
                            <ul className='px-4 py-2'>
                                {arrayFlagPagination.map((item) => (
                                    <li
                                        role='presentation'
                                        key={item.value}
                                        onClick={() => onClick(item.value)}
                                        className={`flex items-center px-2 py-1 border-b-[2px] cursor-pointer border-backgroundModal last:border-b-0 hover:bg-lightBlue ${
                                            perPage === item.value && 'bg-lightBlue'
                                        }`}
                                    >
                                        {item.value}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            {countListPagination && (
                <input
                    className='ml-4 border-[2px] text-sm border-grayDisabled outline-none rounded-lg pl-1 w-[80px] focus:border-blue hover:border-blue'
                    type='number'
                    value={value}
                    placeholder='Показать'
                    onKeyDown={handleKeyPress}
                    onChange={handleInputChange}
                />
            )}
        </div>
    );
};

export default SelectList;
