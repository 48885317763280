import React from 'react';
import './stepper.css';
import { IAnimalRegistration } from '../../models/base/animals';

interface IProps {
    items?: IAnimalRegistration;
}

const register = (item?: IAnimalRegistration) => {
    if (item?.date_verification) {
        return (
            <li className='c-timeline__item'>
                <div
                    className={`${item?.date_verification ? 'c-timeline__content-active' : 'c-timeline__content'}`}
                >
                    <h3 className='text-sm text-gray'>Дата проверки</h3>
                    <p className='text-base text-black'>
                        {item?.date_verification || '-'}
                    </p>
                </div>
            </li>
        )
    } else {
        return (
            <li className='c-timeline__item'>
                <div className={`${item?.date_response ? 'c-timeline__content-active' : 'c-timeline__content'}`}>
                    <h3 className='text-sm text-gray'>Дата проверки</h3>
                    <p className='text-base text-black'>{item?.date_response || '-'}</p>
                </div>
            </li>
        )
    }
};

const StepperVertical = ({ items }: IProps) => {
    return (
        <div className='max-w-[1000px]'>
            <ol className='c-timeline'>
                <li className='c-timeline__item'>
                    <div className={`${items?.date_created ? 'c-timeline__content-active' : 'c-timeline__content'}`}>
                        <h3 className='text-sm text-gray'>Дата создания карточки</h3>
                        <p className='text-base text-black'>{items?.date_created || '-'}</p>
                    </div>
                </li>
                <li className='c-timeline__item'>
                    <div className={`${items?.date_send ? 'c-timeline__content-active' : 'c-timeline__content'}`}>
                        <h3 className='text-sm text-gray'>Дата отправки</h3>
                        <p className='text-base text-black'>{items?.date_send || '-'}</p>
                    </div>
                </li>
                {register(items)}
                <li className='c-timeline__item'>
                    <div
                        className={`${items?.date_registration ? 'c-timeline__content-active' : 'c-timeline__content'}`}
                    >
                        <h3 className='text-sm text-gray'>Дата регистрации животного</h3>
                        <p className='text-base text-black'>
                            {items?.date_registration || '-'}
                        </p>
                    </div>
                </li>
            </ol>
        </div>
    );
};

export default StepperVertical;
