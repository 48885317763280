import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiKeysType, IList, IParams, IServerResponsePagination } from '../../models/models';
import { IUserAllInfo } from '../../models/base/user';
import { usersApi } from '../users/users.api';
import { animalsApi } from '../animals/animals.api';
import { IApplication } from '../../models/base/applications';
import { IAnimal } from '../../models/base/animals';
import { applicationsApi } from '../applications/applications.api';

export const LS_AUTH_KEY = 'svr-auth';

const listTamplate = {
    list: [],
    params: {
        cur_page: 1,
        per_page: 10,
    },
    hiddenParam: {},
    pagination: {
        max_page: 0,
        total_records: 0,
        cur_page: 0,
        per_page: 0,
    },
    filters: {},
    search: {},
    loading: false,
};
export interface ListsState {
    users: IList<IUserAllInfo>;
    animals: IList<IAnimal>;
    applications: IList<IApplication>;
    applicationAnimals: IList<IAnimal>;
}

const initialState: ListsState = {
    users: listTamplate,
    animals: {
        ...listTamplate,
        params: {
            cur_page: 1,
            per_page: 100,
        },
    },
    applications: listTamplate,
    applicationAnimals: listTamplate,
};

export const ListSlice = createSlice({
    name: 'lists',
    initialState,
    reducers: {
        setList(
            state,
            action: PayloadAction<{ key: ApiKeysType; list: any[]; pagination: IServerResponsePagination }>,
        ) {
            state[action.payload.key].list = action.payload.list;
            state[action.payload.key].pagination = action.payload.pagination;
        },
        setParams(state, action: PayloadAction<{ key: ApiKeysType; params: IParams }>) {
            state[action.payload.key].params = action.payload.params;
        },
        setFilters(
            state,
            action: PayloadAction<{ storeKey: keyof ListsState; filterKey: string; filterValue: string | number }>,
        ) {
            state[action.payload.storeKey].filters[action.payload.filterKey] = action.payload.filterValue;
        },
        deleteFilter(state, action: PayloadAction<{ storeKey: keyof ListsState; filterKey: string }>) {
            delete state[action.payload.storeKey].filters[action.payload.filterKey];
        },

        setSearchValue(state, action: PayloadAction<{ storeKey: keyof ListsState; key: string; value: string }>) {
            state[action.payload.storeKey].search[action.payload.key] = action.payload.value;
        },
        deleteSearchValues(state, action: PayloadAction<{ storeKey: keyof ListsState }>) {
            state[action.payload.storeKey].search = {};
        },

        setListFilters(state, action: PayloadAction<{ key: ApiKeysType; params: { [key: string]: string | number } }>) {
            state[action.payload.key].filters = action.payload.params;
        },
        setHiddenParam(state, action: PayloadAction<{ key: ApiKeysType; filterKey: string; filterValue: string }>) {
            state[action.payload.key].hiddenParam[action.payload.filterKey] = action.payload.filterValue;
        },
        deleteHiddenParam(state, action: PayloadAction<{ key: ApiKeysType; filterKey: string }>) {
            delete state[action.payload.key].hiddenParam[action.payload.filterKey];
        },
        setLoading(state, action: PayloadAction<{ key: ApiKeysType; loading: boolean }>) {
            state[action.payload.key].loading = action.payload.loading;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(usersApi.endpoints.getUsers.matchFulfilled, (state, action) => {
                state.users.list = action.payload.data;
                state.users.pagination = action.payload.pagination;
                state.users.params = action.meta.arg.originalArgs;
            })
            .addMatcher(animalsApi.endpoints.getAnimals.matchFulfilled, (state, action) => {
                state.animals.list = action.payload.data;
                state.animals.pagination = action.payload.pagination;
                state.animals.params = action.meta.arg.originalArgs;
            })
            .addMatcher(animalsApi.endpoints.getAnimalsFromApplication.matchFulfilled, (state, action) => {
                state.applicationAnimals.list = action.payload.data;
                state.applicationAnimals.pagination = action.payload.pagination;
                state.applicationAnimals.params = action.meta.arg.originalArgs;
            })
            .addMatcher(applicationsApi.endpoints.getApplications.matchFulfilled, (state, action) => {
                state.applications.list = action.payload.data;
                state.applications.pagination = action.payload.pagination;
                state.applications.params = action.meta.arg.originalArgs;
            });
    },
});

export const listActions = ListSlice.actions;
export const listReducer = ListSlice.reducer;
