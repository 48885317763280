import React from 'react';

const Home = () => (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M26.8802 17.7281L26.9142 26.4836C26.9142 26.6367 26.9045 26.7789 26.8899 26.9266V27.8125C26.8899 29.0211 26.0197 30 24.9454 30H24.1676C24.1141 30 24.0606 29.9508 24.0072 29.9945C23.9391 29.9508 23.871 30 23.803 30H21.0563C19.982 30 19.1118 29.0211 19.1118 27.8125V23C19.1118 22.032 18.4167 21.25 17.5562 21.25H14.445C13.5845 21.25 12.8893 22.032 12.8893 23V27.8125C12.8893 29.0211 12.0192 30 10.9448 30H8.22734C8.15442 30 8.0815 29.9945 8.00858 29.9891C7.95025 29.9945 7.89191 30 7.83358 30H7.05577C5.9819 30 5.11124 29.0211 5.11124 27.8125V21.6875C5.11124 21.6383 5.1127 21.5836 5.11562 21.5344V17.7281H3.55805C2.68156 17.7281 2 16.957 2 15.9727C2 15.4805 2.14603 15.043 2.48662 14.6602L14.9505 2.43837C15.2908 2.0548 15.6797 2 16.02 2C16.3603 2 16.7492 2.10959 17.0458 2.38358L22.2231 7.50703V5.5C22.2231 4.53367 22.9182 3.75 23.7787 3.75H25.3343C26.1948 3.75 26.8899 4.53367 26.8899 5.5V12.1172L29.4567 14.6602C29.8456 15.043 30.0449 15.4805 29.9914 15.9727C29.9914 16.957 29.2622 17.7281 28.4358 17.7281H26.8802Z'
            fill='#BDDDFF'
        />
    </svg>
);

export default Home;
