import React, { useCallback, useState } from 'react';
import ModalConfirm from '../../ModalConfirm';
import { ReactComponent as CameraSvg } from '../../../image/svg/camera.svg';
import TrashSvg from '../../../image/svg/TrashSvg';
import { useLazyAnimalDeletePhotoQuery, useLazyAddAnimalPhotoQuery } from '../../../store/animals/animals.api';
import { IAnimalMarkItems } from '../../../models/base/animals';

interface IImageSingle {
    item: IAnimalMarkItems;
}

const ImageSingleDownload = ({ item }: IImageSingle) => {
    const [markPhoto] = useLazyAddAnimalPhotoQuery();
    const [markDeletePhoto, {isLoading}] = useLazyAnimalDeletePhotoQuery();
    const [image, setImage] = useState<string>(item.photo);

    const handleImageChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (e.target.files && e.target.files[0]) {
                const file = e.target.files[0];
                setImage(URL.createObjectURL(file));

                const formData = new FormData();
                formData.append('mark_id', item.mark_id.toString());
                formData.append('mark_photo', file);
                markPhoto(formData);
                e.target.value = '';
            }
        },
        [item.mark_id, markPhoto],
    );

    const onDelete = useCallback(() => {
        if (item.mark_id) {
            markDeletePhoto(item.mark_id);
            setImage('');
        }
    }, [markDeletePhoto, item.mark_id]);

    return (
        <div className='rounded-lg'>
            <div className='relative'>
                <div className='relative download__container'>
                    <div className='download__box'>
                        <label htmlFor={`input__file_${item.mark_id}`} className='label-single-photo'>
                            <input
                                name='file'
                                type='file'
                                id={`input__file_${item.mark_id}`}
                                className='absolute hidden opacity-0'
                                onChange={handleImageChange}
                            />
                            {image ? (
                                <img
                                    className='rounded-lg bg-no-repeat bg-borderGray object-cover hover:opacity-80 min-w-[240px] min-h-[115px] max-w-[240px] max-h-[115px]'
                                    src={image}
                                    alt='user'
                                />
                            ) : (
                                <span className='flex items-center justify-center rounded-lg bg-borderGray hover:opacity-80 min-w-[240px] min-h-[115px] max-w-[240px] max-h-[115px]'>
                                    <CameraSvg />
                                </span>
                            )}
                            <span className='text-sm mt-[10px] text-center text-blue hover:underline hover:text-darkBlue'>
                                Добавить фото
                            </span>
                        </label>
                        {image && (
                            <ModalConfirm
                                textHeader='Подтвердите удаление фотографии'
                                onClick={onDelete}
                                type='danger'
                                textButtonConf='Удалить'
                            >
                                <button
                                    aria-label='button'
                                    className='button-delete-photo-single top-[4px] right-[38%]'
                                    type='button'
                                    disabled={isLoading}
                                >
                                    <TrashSvg classSvg='icon-trash' color='#E53E3E' />
                                </button>
                            </ModalConfirm>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageSingleDownload;
