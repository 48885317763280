import dayjs from 'dayjs';
import { LS_AUTH_KEY, KeyCode } from './dictionary';

export const getTokenFromLocalStorage = () => {
    const ret = localStorage.getItem(`${LS_AUTH_KEY}-user_token`);
    ret?.replace(/"/g, '');
    return ret;
};

export function EnterClick(evt: any) {
    if (evt.code === KeyCode.ENTER || evt.code === KeyCode.NUM_ENTER) {
        return true;
    }
}

export function prepareDate(date: string | undefined) {
    date = date ? dayjs(date).format('DD.MM.YYYY') : '';
    return date;
}
