import React from 'react';
import Cross from '../image/svg/Cross';
import useTableFilter from '../hooks/useTableUserFilter';
import { ApiKeysType } from '../models/models';

interface IButtonClearFilterProps {
    storeKey: ApiKeysType;
}

const ButtonClearFilter: React.FC<IButtonClearFilterProps> = ({ storeKey }) => {
    const { handlerFilterReset } = useTableFilter(storeKey);

    return (
        <button className='button-filter' type='button' onClick={handlerFilterReset}>
            <Cross classSvg='filter-svg' color='#B1BBCA' width='12' height='12' />
            <span className='whitespace-nowrap pl-1'>очистить фильтр</span>
        </button>
    );
};

export default ButtonClearFilter;
