import React from 'react';

interface IClipboardList {
    classSvg?: string;
}
const ClipboardList = ({classSvg}:IClipboardList) => (
    <svg className={classSvg} width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M23.5 5.79131H19.3333C19.3333 3.9783 17.8385 2.50427 16 2.50427C14.1615 2.50427 12.6667 3.9783 12.6667 5.79131H8.5C7.11979 5.79131 6 6.89555 6 8.25659V26.3353C6 27.6963 7.11979 28.8006 8.5 28.8006H23.5C24.8802 28.8006 26 27.6963 26 26.3353V8.25659C26 6.89555 24.8802 5.79131 23.5 5.79131ZM11 24.2809C10.3073 24.2809 9.75 23.7313 9.75 23.0483C9.75 22.3652 10.3073 21.8156 11 21.8156C11.6927 21.8156 12.25 22.3652 12.25 23.0483C12.25 23.7313 11.6927 24.2809 11 24.2809ZM11 19.3503C10.3073 19.3503 9.75 18.8008 9.75 18.1177C9.75 17.4346 10.3073 16.8851 11 16.8851C11.6927 16.8851 12.25 17.4346 12.25 18.1177C12.25 18.8008 11.6927 19.3503 11 19.3503ZM11 14.4198C10.3073 14.4198 9.75 13.8702 9.75 13.1871C9.75 12.5041 10.3073 11.9545 11 11.9545C11.6927 11.9545 12.25 12.5041 12.25 13.1871C12.25 13.8702 11.6927 14.4198 11 14.4198ZM16 4.55867C16.6927 4.55867 17.25 5.10822 17.25 5.79131C17.25 6.4744 16.6927 7.02395 16 7.02395C15.3073 7.02395 14.75 6.4744 14.75 5.79131C14.75 5.10822 15.3073 4.55867 16 4.55867ZM22.6667 23.4591C22.6667 23.6851 22.4792 23.87 22.25 23.87H14.75C14.5208 23.87 14.3333 23.6851 14.3333 23.4591V22.6374C14.3333 22.4114 14.5208 22.2265 14.75 22.2265H22.25C22.4792 22.2265 22.6667 22.4114 22.6667 22.6374V23.4591ZM22.6667 18.5286C22.6667 18.7546 22.4792 18.9395 22.25 18.9395H14.75C14.5208 18.9395 14.3333 18.7546 14.3333 18.5286V17.7068C14.3333 17.4808 14.5208 17.2959 14.75 17.2959H22.25C22.4792 17.2959 22.6667 17.4808 22.6667 17.7068V18.5286ZM22.6667 13.598C22.6667 13.824 22.4792 14.0089 22.25 14.0089H14.75C14.5208 14.0089 14.3333 13.824 14.3333 13.598V12.7763C14.3333 12.5503 14.5208 12.3654 14.75 12.3654H22.25C22.4792 12.3654 22.6667 12.5503 22.6667 12.7763V13.598Z'
            fill='#BDDDFF'
        />
    </svg>
);

export default ClipboardList;
