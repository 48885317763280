import React from 'react';
import useAppSelector from '../../hooks/redux';
import ProfileHerriot from './ProfileHerriot';
import Input from '../Inputs/Input';
import {ROLES} from '../../utils/dictionary';
import ProfileChangePassword from "./ProfileChangePassword";

const ProfileLogin = () => {
    const email = useAppSelector((state) => state.users.currentUser.user_email);
    const currentUserId = useAppSelector((state) => state.users.currentUser.user_id);
    const authId = useAppSelector((state) => state.auth.user.user_id);
    const currentUserHerriot = useAppSelector((state) => state.users.currentUser.user_herriot_data);
    const rolesList = useAppSelector((state) => state.auth.user.user_roles_list);

    return (
        <div className='profile-container'>
            <form className='mb-6'>
                <h2 className='text-base text-gray mb-5 w-full min-w-[100px]'>Вход в систему</h2>
                <Input label='Email' type='email' storeKey='user_email__user_edit' value={email} disabled />
                <ProfileChangePassword/>
            </form>
            {currentUserId === Number(authId) && <ProfileHerriot />}
            {rolesList.includes(ROLES.Admin) && currentUserId !== Number(authId) && (
                <>
                    <h2 className='text-base text-gray mb-5 w-full min-w-[100px]'>Данные системы Хорриот</h2>
                    {currentUserHerriot.login !== '' && currentUserHerriot.password !== '' ? (
                        <span className='block text-sm text-black mb-5'>Данные предоставлены</span>
                    ) : (
                        <span className='block text-sm text-black mb-5'>Данные не предоставлены</span>
                    )}
                </>
            )}
        </div>
    );
};

export default ProfileLogin;
