import React from 'react';
import ChooseCompany from './ChooseMultiSelect/ChooseCompany';
import ChooseDistrict from './ChooseMultiSelect/ChooseDistrict';
import ProfileOrganizationList from './ProfileOrganizationList';
import useAppSelector from '../../hooks/redux';
import DictionarysValue from '../DictionarysValue';
import {ROLES} from '../../utils/dictionary';
import ChooseRegion from './ChooseMultiSelect/ChooseRegion';

const ProfileOrganization = () => {
    const authUserRoles = useAppSelector((state) => state.auth.user.user_roles_list);
    const currentUserRoles = useAppSelector((state) => state.users.currentUser.user_roles_list);
    const currentUserDistricts = useAppSelector((state) => state.users.currentUser.user_districts_list);
    const currentUserRegions = useAppSelector((state) => state.users.currentUser.user_regions_list);

    return (
        <div className='profile-container'>
            <h2 className='text-base text-gray mb-5 min-w-[100px]'>Организация</h2>
            {currentUserRoles.includes(ROLES.VetCompany) && authUserRoles.includes(ROLES.Admin) && (
                <>
                    <ChooseCompany />
                </>
            )}

            {currentUserRoles.includes(ROLES.VetCompany) && !authUserRoles.includes(ROLES.Admin) && (
                <>
                    <ProfileOrganizationList />
                </>
            )}

            {currentUserRoles.includes(ROLES.VetDistrict) && authUserRoles.includes(ROLES.Admin) && (
                <>
                    <h2 className='text-base text-gray mb-5 min-w-[100px]'>Районы</h2>
                    <ChooseDistrict />
                </>
            )}
            {currentUserRoles.includes(ROLES.VetDistrict) && !authUserRoles.includes(ROLES.Admin) && (
                <>
                    <h2 className='text-base text-gray mb-5 min-w-[100px]'>Районы</h2>
                    <div className='mt-4'>
                        <div>
                            <DictionarysValue
                                idArray={currentUserDistricts}
                                keyDictionary='user_districts_list'
                                keyValue='district_name'
                            />
                        </div>
                    </div>
                </>
            )}
            {currentUserRoles.includes(ROLES.VetRegion) && authUserRoles.includes(ROLES.Admin) && (
                <>
                    <h2 className='text-base text-gray mb-5 min-w-[100px]'>Регионы</h2>
                    <ChooseRegion />
                </>
            )}
            {currentUserRoles.includes(ROLES.VetRegion) && !authUserRoles.includes(ROLES.Admin) && (
                <>
                    <h2 className='text-base text-gray mb-5 min-w-[100px]'>Регионы</h2>
                    <div className='mt-4'>
                        <div>
                            <DictionarysValue
                                idArray={currentUserRegions}
                                keyDictionary='user_districts_list'
                                keyValue='district_name'
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProfileOrganization;
