import React from 'react';
import InputDefault from '../../../Inputs/InputDefault';
import SelectMarkStatus from "../../AnimalsMark/SelectMarkStatus";
import SelectMarkToolLocation from "../../AnimalsMark/SelectMarkToolLocation";
import SelectMarkToolType from "../../AnimalsMark/SelectMarkToolType";
import SelectMarkTypeId from "./SelectMarkTypeId";

interface IModalInputAdd {
    indexElement: number;
}

const ModalInputAdd:React.FC<IModalInputAdd> = ({indexElement}) => {
    return (
        <div className="grid grid-cols-5 gap-2 w-full">
            <SelectMarkTypeId required label='Вид номера' storeKey={`mark_type_id__all__${indexElement}`} />
            <SelectMarkStatus label='Вид маркировки' storeKey={`mark_status__all__${indexElement}`} />
            <SelectMarkToolType label='Тип средства' storeKey={`mark_tool_type__all__${indexElement}`}/>
            <SelectMarkToolLocation label='Место нанесения' storeKey={`mark_tool_location__all__${indexElement}`}/>
            <InputDefault label='Описание' type='text' storeKey={`description__all__${indexElement}`} className="w-full"/>
        </div>
    );
};

export default ModalInputAdd;

