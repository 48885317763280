import React from 'react';
import Pagination from '../Pagination/Pagination';
import Loader from '../Loader';
import { API_KEYS, ROUTES } from '../../utils/dictionary';
import useAppSelector from '../../hooks/redux';
import TableSearch from '../TableUser/TableSearch';
import TableMenu from '../TableUser/TableMenu';
import TableTitle from '../TableUser/TableTitle';
import TableLink from '../TableUser/TableLink';
import ApplicationsTableFilter from './ApplicationsTableFilter';
import { IApplication } from '../../models/base/applications';
import ApplicationsStatus from './ApplicationsStatus';

const apiKey = API_KEYS.Applications;

const ApplicationsTable = () => {
    const { loading, list: applicationsData } = useAppSelector((state) => state.list.applications);

    return (
        <div className='table-container'>
            <div className='table-wrapper'>
                <TableSearch apiKey={apiKey} />
                <ApplicationsTableFilter />
            </div>
            <Loader isLoading={loading}>
                <div className='max-w-full overflow-y-auto'>
                    <table className='table-content'>
                        <thead className='table-thead'>
                            <tr>
                                <TableTitle classTitle='w-[4%] pl-5' title='№' />
                                <TableTitle classTitle='w-[14%]' title='ID пользователя' />
                                <TableTitle
                                    classTitle='w-[12%]'
                                    title='Заявка пользователя'
                                    filterKey='last_name'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[14%]'
                                    title='Район'
                                    filterKey='region_name'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[14%]'
                                    title='Хозяйство'
                                    filterKey='company_name_short'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[14%]'
                                    title='Дата заявки'
                                    filterKey='application_date_create'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[14%]'
                                    title='Дата регистрации'
                                    filterKey='application_date_horriot'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[10%]'
                                    title='Статус заявки'
                                    filterKey='application_status'
                                    apiKey={apiKey}
                                />
                                <th scope='col' className='w-[4%] font-normal px-2 py-0 '>
                                    <span className='hidden'>Операции</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='table-tbody'>
                            {applicationsData?.map((application: IApplication) => {
                                return (
                                    <tr key={application.application_id} className='table-tbody-tr'>
                                        <th scope='row' className='table-tbody-th'>
                                            {application.application_id}
                                        </th>
                                        <td className='table-tbody-td'>
                                            <span>{application.user_id}</span>
                                        </td>
                                        <td className='table-tbody-td'>{application.user_name}</td>
                                        <td className='table-tbody-td'>{application.district_name}</td>
                                        <td className='table-tbody-td'>{application.company_name_short}</td>
                                        <td className='table-tbody-td'>{application.application_date_create}</td>
                                        <td className='table-tbody-td'>{application.application_date_horriot}</td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td className='table-tbody-td'>
                                            <ApplicationsStatus status={application.application_status} />
                                        </td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td>
                                            <div className='flex pr-2 justify-end items-center relative'>
                                                <TableMenu>
                                                    <TableLink
                                                        title='К заявке'
                                                        link={`${ROUTES.Application}${application.application_id}`}
                                                    />
                                                </TableMenu>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Loader>
            <div className='px-5'>{applicationsData && <Pagination apiKey={apiKey} />}</div>
        </div>
    );
};

export default ApplicationsTable;
