import React from "react";
import user from "../../../image/user-big.png";
import useAppSelector from "../../../hooks/redux";

const UserPhoto = () => {
    const userAvatarBig = useAppSelector((state) => state.users.currentUser.user_avatar_big);
    return(
        <div className='py-2 rounded-lg'>
                <div className='relative'>
                    <div className='relative download__container'>
                        <div className='download__box'>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor='input__file' className='label-photo'>
                                <span className='input__file-icon-wrapper'>
                                    {userAvatarBig ? (
                                        <img
                                            className='rounded-[50%] w-[100px] h-[100px]'
                                            src={userAvatarBig}
                                            alt='user'
                                        />
                                    ) : (
                                        <img
                                            className='rounded-[50%] w-[100px] h-[100px]'
                                            src={user}
                                            alt='user'
                                        />
                                    )}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default UserPhoto;