import React from 'react';
import {Link} from "react-router-dom";

interface ITableLink {
    title?: string;
    link: string
}

const TableLink = ({title, link}: ITableLink) => {
    return (
        <Link to={link}>
            <li role='presentation'
                className='text-sm text-black hover:bg-ghostBlue cursor-pointer py-[4px] px-2'>
                {title}
            </li>
        </Link>
    );
};

export default TableLink;
