import React, {useState} from 'react';
import MultiSelect from '../../Inputs/MultiSelect';
import useAppSelector from '../../../hooks/redux';
import {IChangeParamsFunc} from "../../../models/models";
import {useLazyAddUserLocationQuery, useLazyDeleteUserLocationQuery} from "../../../store/users/users.api";
import {useGetRegionsListQuery} from "../../../store/directories/directories.api";
import useDebounceString from '../../../hooks/debounceString';

const ChooseRegion = () => {
    const userId = useAppSelector((state) => state.users.currentUser.user_id);

    const currentUserRegions = useAppSelector((state) => state.users.currentUser.user_regions_list);
    const currentUserRegionsDictionary = useAppSelector((state) => state.dictionary.current?.user_regions_list);
    const [searchRegion, setSearchRegion] = useState('');
    const debouncedValue = useDebounceString(searchRegion, 500)

    const [addLocation] = useLazyAddUserLocationQuery();
    const [deleteLocation] = useLazyDeleteUserLocationQuery();
    const {data} = useGetRegionsListQuery(debouncedValue);

    const setRegion: IChangeParamsFunc = (id, addFlag) => {
        const params = {
            user_id: userId,
            region_id: id,
        };

        if (addFlag) {
            addLocation(params);
        } else {
            deleteLocation(params);
        }
    };

    return (
        <>
            <MultiSelect
                placeholder='Привязанные регионы'
                initValue={currentUserRegions}
                list={data?.data}
                dictionaryKey={'region_id'}
                dictionaryValue={'region_name'}
                callback={setRegion}
                setSearch={setSearchRegion}
                searchValue={searchRegion}
                usersDictionaryList={currentUserRegionsDictionary}
            />
        </>
    );
};

export default ChooseRegion;
