import React, { useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import TableAnimals from '../components/Animals/AnimalsTableRegistry/AnimalsTable';
import useDataSourceParams from '../hooks/useDataSourceParams';
import { API_KEYS } from '../utils/dictionary';
import useAppSelector from '../hooks/redux';

const apiKey = API_KEYS.Animals;

const AnimalsPage = () => {
    //   const authUserDictionary = useAppSelector((state) => state.dictionary.authUser);
    const params = useAppSelector((state) => state.list.animals.params);

    const { init } = useDataSourceParams(apiKey);

    useEffect(() => {
        init(params);
    }, []);

    return (
        <div className='pt-5 px-4 pb-4'>
            <div className='flex items-center justify-between'>
                <Breadcrumbs title='Реестр животных' caption='Реестр животных' />
                {/* <button */}
                {/*    className='button-blue' */}
                {/*    type='button' */}
                {/* > */}
                {/*    <IconDownload colorIcon='#fff' /> */}
                {/*    <span className='ml-[10px]'>Выгрузить</span> */}
                {/* </button> */}
            </div>
            <TableAnimals />
        </div>
    );
};

export default AnimalsPage;
