import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserAllInfo } from '../../models/base/user';
import { usersApi } from './users.api';

interface UsersState {
    currentUser: IUserAllInfo;
    edit: boolean;
}

const initialState: UsersState = {
    currentUser: {
        user_id: 0,
        user_first: '',
        user_middle: '',
        user_last: '',
        user_status: 'enabled',
        user_avatar_small: '',
        user_avatar_big: '',
        user_date_created: '',
        user_date_block: '',
        user_step_status: '',
        user_companies_count: 0,
        user_companies_locations_list: [],
        user_roles_list: [],
        user_districts_list: [],
        user_regions_list: [],
        user_phone: '',
        user_email: '',
        user_herriot_data: {
            login: '',
            password: '',
            apikey: '',
            web_login: '',
            issuerid: '',
            serviceid: '',
        },

        user_districts_list_name: '',
        user_companies_locations_list_name: '',
        user_roles_list_name: '',
    },
    edit: false,
};

export const UsersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        addCurrentUser(state, action: PayloadAction<any>) {
            state.currentUser = action.payload;
        },
        updateCurrentUser(state, action: PayloadAction<any>) {
            state.currentUser = { ...state.currentUser, ...action.payload };
        },
        setCurrentUserStatus(state, action: PayloadAction<'disabled' | 'enabled'>) {
            state.currentUser.user_status = action.payload;
        },
        setEdit(state, action: PayloadAction<boolean>) {
            state.edit = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(usersApi.endpoints.setUserStatus.matchFulfilled, (state, action) => {
                state.currentUser.user_status = action.meta.arg.originalArgs.user_status;
            })
            .addMatcher(usersApi.endpoints.getUser.matchFulfilled, (state, action) => {
                state.currentUser = action.payload.data;
            })
            .addMatcher(usersApi.endpoints.setUserAvatar.matchFulfilled, (state, action) => {
                state.currentUser.user_avatar_big = action.payload.data.user_avatar_big;
                state.currentUser.user_avatar_small = action.payload.data.user_avatar_small;
            })
            .addMatcher(usersApi.endpoints.deleteUserAvatar.matchFulfilled, (state, action) => {
                state.currentUser.user_avatar_big = action.payload.data.user_avatar_big;
                state.currentUser.user_avatar_small = action.payload.data.user_avatar_small;
            })
            .addMatcher(usersApi.endpoints.editUser.matchFulfilled, (state, action) => {
                state.currentUser = { ...state.currentUser, ...action.meta.arg.originalArgs };
            })
            .addMatcher(usersApi.endpoints.addUserRole.matchFulfilled, (state, action) => {
                state.currentUser.user_roles_list = action.payload.data.user_roles_list;
            })
            .addMatcher(usersApi.endpoints.deleteUserRole.matchFulfilled, (state, action) => {
                state.currentUser.user_roles_list = action.payload.data.user_roles_list;
            })
            .addMatcher(usersApi.endpoints.addUserCompany.matchFulfilled, (state, action) => {
                state.currentUser.user_companies_locations_list = action.payload.data.user_companies_locations_list;
            })
            .addMatcher(usersApi.endpoints.deleteUserCompany.matchFulfilled, (state, action) => {
                state.currentUser.user_companies_locations_list = action.payload.data.user_companies_locations_list;
            })
            .addMatcher(usersApi.endpoints.addUserLocation.matchFulfilled, (state, action) => {
                state.currentUser.user_districts_list = action.payload.data.user_districts_list;
                state.currentUser.user_regions_list = action.payload.data.user_regions_list;
            })
            .addMatcher(usersApi.endpoints.deleteUserLocation.matchFulfilled, (state, action) => {
                state.currentUser.user_districts_list = action.payload.data.user_districts_list;
                state.currentUser.user_regions_list = action.payload.data.user_regions_list;
            })
            .addMatcher(usersApi.endpoints.addHerriotData.matchFulfilled, (state, action) => {
                state.currentUser.user_herriot_data = action.payload.data.user_herriot_data;
            });
    },
});

export const usersActions = UsersSlice.actions;
export const usersReducer = UsersSlice.reducer;
