import { createApi } from '@reduxjs/toolkit/query/react';
import { IAnimal } from '../../models/base/animals';
import { IParams, IServerResponse } from '../../models/models';
import animalService from './animal.service';
import { baseQuery } from '../middleware';

export const animalsApi = createApi({
    reducerPath: 'animals/api',
    tagTypes: ['Animals'],
    baseQuery,
    endpoints: (build) => ({
        getAnimals: build.query<IServerResponse<IAnimal[]>, IParams>({
            query: (params) => ({
                url: `animals/list`,
                method: 'GET',
                params,
            }),
            transformResponse: (response: IServerResponse<IAnimal[]>) => {
                return animalService.prepareAnimalsList(response);
            },
            providesTags: (result) =>
                result?.data
                    ? [
                          ...result.data.map((item) => ({
                              type: 'Animals' as const,
                              id: item.main?.animal_id,
                          })),
                          { type: 'Animals', id: 'LIST' },
                      ]
                    : [{ type: 'Animals', id: 'LIST' }],
        }),
        getAnimalsFromApplication: build.query<IServerResponse<IAnimal[]>, IParams>({
            query: (params) => ({
                url: `animals/list`,
                method: 'GET',
                params,
            }),
            transformResponse: (response: IServerResponse<IAnimal[]>) => {
                return animalService.prepareAnimalsList(response);
            },
            providesTags: (result) =>
                result?.data
                    ? [
                          ...result.data.map((item) => ({
                              type: 'Animals' as const,
                              id: item.main?.animal_id,
                          })),
                          { type: 'Animals', id: 'LIST' },
                      ]
                    : [{ type: 'Animals', id: 'LIST' }],
        }),
        getAnimal: build.query<IServerResponse<IAnimal>, any>({
            query: ({ id }) => ({
                url: `animals/data/?animal_id=${id}`,
                method: 'POST',
            }),
        }),
        getAnimalPart: build.query<IServerResponse<IAnimal>, any>({
            query: ({ id, app, body }) => ({
                url: `animals/data/?animal_id=${id}&aplication_id=${app}`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: IServerResponse<IAnimal>) => {
                response.data = animalService.prepareAnimalData(response.data, response.dictionary);
                return response;
            },
        }),
        editAnimal: build.mutation<any, any>({
            query: ({ body, id }) => ({
                url: `animals/${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: [{ type: 'Animals', id: 'LIST' }],
        }),
        deleteAnimal: build.mutation<any, any>({
            query: ({ id }) => ({
                url: `animals/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Animals', id: 'LIST' }],
        }),
        animalsMarkEdit: build.query<any, any>({
            query: (params) => ({
                url: `animals/mark_edit`,
                method: 'POST',
                params,
            }),
        }),
        animalsMarkEditGroup: build.query<any, { updates: any[]; animal_id?: number[]; params?: any }>({
            query: ({ updates, animal_id, params }) => ({
                url: `animals/mark_edit_group`,
                method: 'POST',
                params,
                body: {
                    updates,
                    animal_id,
                },
            }),
        }),
        animalsSuperviseObjectEditGroup: build.query<any, { updates: any[]; animal_id?: number[]; params?: any }>({
            query: ({ updates, animal_id, params }) => ({
                url: `animals/animal_object_edit_group`,
                method: 'POST',
                params,
                body: {
                    ...updates,
                    animal_id,
                },
            }),
        }),
        addAnimalPhoto: build.query<any, object>({
            query: (body: { mark_id: number; mark_photo: any }) => ({
                url: `animals/mark_photo_edit`,
                method: 'POST',
                body,
            }),
        }),
        animalDeletePhoto: build.query<any, number | string>({
            query: (id: number | string) => ({
                url: `animals/mark_photo_delete/${id}`,
                method: 'POST',
            }),
        }),
        editAnimalKeepingObject: build.query<any, any>({
            query: (body) => ({
                url: `animals/animal_keeping_object_edit/`,
                method: 'POST',
                body,
            }),
        }),
        editAnimalKeepingType: build.query<any, any>({
            query: (body) => ({
                url: `animals/animal_keeping_type_edit/`,
                method: 'POST',
                body,
            }),
        }),
        editAnimalKeepingPurpose: build.query<any, any>({
            query: (body) => ({
                url: `animals/animal_keeping_purpose_edit/`,
                method: 'POST',
                body,
            }),
        }),
        editAnimalBirthObject: build.query<any, any>({
            query: (body) => ({
                url: `animals/animal_birth_object_edit/`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useLazyGetAnimalsQuery,
    useLazyGetAnimalsFromApplicationQuery,
    useLazyAddAnimalPhotoQuery,
    useLazyAnimalDeletePhotoQuery,
    useLazyGetAnimalPartQuery,
    useLazyAnimalsMarkEditQuery,
    useLazyEditAnimalKeepingObjectQuery,
    useLazyEditAnimalBirthObjectQuery,
    useLazyAnimalsMarkEditGroupQuery,
    useLazyEditAnimalKeepingPurposeQuery,
    useLazyEditAnimalKeepingTypeQuery,
    useLazyAnimalsSuperviseObjectEditGroupQuery,
} = animalsApi;
