import {IDictionary, IServerResponse} from "../../models/models";
import {IUserAllInfo} from "../../models/base/user";

const usersService = {
    prepareUsersList: (response: IServerResponse<IUserAllInfo[]>) => {
        response.data.map(user => {
            user = usersService.prepareUserData(user, response.dictionary)
            return user
        })

        return response
    },
    prepareUserData: (data: IUserAllInfo, dictionary: IDictionary) => {
        const user_companies_locations_list_name: string[] = []
        data.user_companies_locations_list.map((company_id) => {
            if (dictionary.user_companies_locations_list) {
                user_companies_locations_list_name
                    .push(`${dictionary.user_companies_locations_list[company_id]?.company_name_short} 
                    (${dictionary.user_companies_locations_list[company_id]?.region_name}, 
                     ${dictionary.user_companies_locations_list[company_id]?.district_name})`)
            }
            return company_id
        })
        data.user_companies_locations_list_name = user_companies_locations_list_name.join(', ')

        const user_roles_list_name: string[] = []
        data.user_roles_list.map((role_id) => {
            if (dictionary.user_roles_list) {
                user_roles_list_name
                    .push(`${dictionary.user_roles_list[role_id]?.role_name_short}`)
            }
            return role_id
        })
        data.user_roles_list_name = user_roles_list_name.join(', ')

        const user_districts_list_name: string[] = []
        data.user_districts_list.map((district_id) => {
            if (dictionary.user_districts_list) {
                user_districts_list_name
                    .push(`${dictionary.user_districts_list[district_id]?.district_name}`)
            }
            return district_id
        })
        data.user_districts_list_name = user_districts_list_name.join(', ')

        const user_regions_list_name: string[] = []
        data.user_regions_list.map((region_id) => {
            if (dictionary.user_regions_list) {
                user_regions_list_name
                    .push(`${dictionary.user_regions_list[region_id]?.region_name}`)
            }
            return region_id
        })
        data.user_regions_list_name = user_regions_list_name.join(', ')

        return data
    },
}

export default usersService