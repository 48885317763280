import React, { useCallback } from 'react';
import useAppSelector from '../../../hooks/redux';
import { useLazyEditAnimalBirthObjectQuery, useLazyGetAnimalPartQuery } from '../../../store/animals/animals.api';
import {
    useGetCompanyObjectsListQuery,
    useSetFavoriteCompanyMutation,
    useUnsetFavoriteCompanyMutation,
} from '../../../store/companies/company.api';
import SelectWithFavorites from '../../Inputs/SelectWithFavorites';

interface IAnimalBirthSelect {
    id?: string;
    description?: boolean;
    storeKey?: string;
    onChange?: boolean;
    listOrCurrent?: boolean;
}
const AnimalBirthSelect = ({
    id,
    description = true,
    storeKey,
    onChange = true,
    listOrCurrent = false,
}: IAnimalBirthSelect) => {
    const paramsQuery = {
        id,
        body: { data_sections: ['main', 'base', 'mark', 'genealogy', 'vib', 'registration', 'history'] },
    };

    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);
    const animalsList = useAppSelector((state) => state.list.animals);
    const brithCompany = listOrCurrent
        ? animalsList.list[0]?.main?.animal_keeping_company
        : currentAnimal.base?.animal_keeping_company;
    const [editAnimalBirthObject] = useLazyEditAnimalBirthObjectQuery();
    const [setFavoriteCompany] = useSetFavoriteCompanyMutation();
    const [unsetFavoriteCompany] = useUnsetFavoriteCompanyMutation();
    const [getAnimal] = useLazyGetAnimalPartQuery();
    const { data } = useGetCompanyObjectsListQuery(brithCompany);

    const setCmpObjectsBirthSrv = useCallback(
        async (id: number) => {
            await editAnimalBirthObject({
                animal_id: currentAnimal.base?.animal_id,
                company_object_id: id,
            });
            getAnimal(paramsQuery);
        },
        [editAnimalBirthObject, getAnimal, paramsQuery, currentAnimal.base?.animal_id],
    );

    const setFavorite = useCallback((item: any) => {
        if (item.company_object_is_favorite) {
            unsetFavoriteCompany({
                company_id: item.company_id,
                company_object_id: item.company_object_id,
            });
        } else {
            setFavoriteCompany({
                company_id: item.company_id,
                company_object_id: item.company_object_id,
            });
        }
    }, []);

    return (
        <>
            <span className='text-gray text-xs mb-1'>Место рождения</span>
            <SelectWithFavorites
                placeholder='Статус записи'
                initValue={currentAnimal.base?.animal_birth_object_obj?.company_object_id}
                storeKey={storeKey ?? 'animal_birth_object_obj__select'}
                // array={cmpObjectsBirth}
                array={data?.data}
                itemKey={'company_object_id'}
                itemValue={'company_object_full_name'}
                onChange={onChange ? setCmpObjectsBirthSrv : undefined}
                setIsFavorite={setFavorite}
                favoriteKey={'company_object_is_favorite'}
            />
            {description && (
                <span className='text-sm text-black bg-inherit w-full italic'>
                    {currentAnimal.base?.animal_birth_object_obj?.company_object_approval_number || '-'}
                    <br />
                    {currentAnimal.base?.animal_birth_object_obj?.company_object_address_view}
                </span>
            )}
        </>
    );
};

export default AnimalBirthSelect;
