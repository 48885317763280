import React from 'react';
import ModalConfirm from './ModalConfirm';
// import {useLazyLogoutTestQuery} from '../store/auth/authTest.api';
import { useLazyLogoutQuery } from '../store/auth/auth.api';

interface ILogoutButton {
    styleButton: string;
}

const LogoutButton = ({ styleButton }: ILogoutButton) => {
    const [fetchLogout, { isLoading }] = useLazyLogoutQuery();

    const logoutUser = () => {
        fetchLogout();
    };

    return (
        <ModalConfirm
            type='danger'
            textButtonConf='Подтвердить'
            textHeader='Вы уверены что хотите выйти?'
            onClick={() => logoutUser()}
            isLoading={isLoading}
        >
            <button
                type='button'
                className={`py-2 w-full text-base rounded-lg h-[40px] ${styleButton}`}
            >
                Выйти
            </button>
        </ModalConfirm>
    );
};

export default LogoutButton;
