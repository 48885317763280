import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import logo from '../image/logo.png';
import Home from '../image/svg/Home';
import Reestr from '../image/svg/Reestr';
import ClipboardList from '../image/svg/ClipboardList';
import Group from '../image/svg/Group';
// import Analytics from '../image/svg/Analytics';
// import Info from '../image/svg/Info';
import {ROLES, ROUTES} from '../utils/dictionary';
import NavigationNotify from './Notifications/NavigationNotify';
import useAppSelector from '../hooks/redux';

interface INavigate {
    open: boolean;
    setOpen: (flag: boolean | ((prevOpen: boolean) => boolean)) => void;
}

const Navigation = ({open, setOpen}: INavigate) => {
    const {pathname} = useLocation();
    const authUserRoles = useAppSelector((state) => state.auth.user.user_roles_list);
    return (
        <section className='sidebar'>
            <div className={`bg-blue min-h-screen relative ${open ? 'w-64' : 'w-12'} duration-500`}>
                <div role={'presentation'} className='py-3 flex justify-start px-2' onClick={() => setOpen((prevOpen: boolean) => !prevOpen)}>
                    <button type='button' aria-label='burger' className={`burger ${open && 'active'}`} />
                </div>
                <div className='sidebar-container'>
                    <Link style={{display: 'none'}} to='/' className='sidebar-link hover:bg-darkBlue group'>
                        <div>
                            <Home />
                        </div>
                        <h2
                            className={`text-white whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                                }`}
                        >
                            Главная
                        </h2>
                        <h2
                            className={`${open && 'hidden'
                                } absolute z-[1000] left-48 bg-blueHover whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1.5 group-hover:left-14 group-hover:w-fit`}
                        >
                            Главная
                        </h2>
                    </Link>
                    <Link
                        to={ROUTES.Applications}
                        className={`sidebar-link group ${pathname.includes(ROUTES.Applications) && 'bg-darkBlue'
                            } hover:bg-darkBlue`}
                    >
                        <ClipboardList
                            classSvg={`icon-sidebar min-w-[32px] min-h-[32px] ${pathname.includes(ROUTES.Applications) && 'icon-sidebar-active'
                                }`}
                        />
                        <h2
                            className={`text-white whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                                }`}
                        >
                            Реестр заявок
                        </h2>
                        <h2
                            className={`${open && 'hidden'
                                } absolute z-[1000] left-48 bg-blueHover whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1.5 group-hover:left-14 group-hover:w-fit`}
                        >
                            Реестр заявок
                        </h2>
                    </Link>
                    <Link
                        to={ROUTES.Animals}
                        className={`sidebar-link group ${pathname.includes(ROUTES.Animals) && 'bg-darkBlue'
                            } hover:bg-darkBlue`}
                    >
                        <Reestr
                            classSvg={`icon-sidebar min-w-[32px] min-h-[32px] ${pathname.includes(ROUTES.Animals) && 'icon-sidebar-active'
                                }`}
                        />
                        <h2
                            className={`text-white whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                                }`}
                        >
                            Реестр Животных
                        </h2>
                        <h2
                            className={`${open && 'hidden'
                                } absolute z-[1000] left-48 bg-blueHover whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1.5 group-hover:left-14 group-hover:w-fit`}
                        >
                            Реестр Животных
                        </h2>
                    </Link>
                    {authUserRoles.includes(ROLES.Admin) && (
                        <Link
                            to={ROUTES.Users}
                            className={`sidebar-link group ${pathname.includes(ROUTES.Users) && 'bg-darkBlue'
                                } hover:bg-darkBlue`}
                        >
                            <Group
                                classSvg={`icon-sidebar min-w-[32px] min-h-[32px] ${pathname.includes(ROUTES.Users) && 'icon-sidebar-active'
                                    }`}
                            />
                            <h2
                                className={`text-white whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                                    }`}
                            >
                                Реестр участников СВР
                            </h2>
                            <h2
                                className={`${open && 'hidden'
                                    } absolute z-[1000] left-48 bg-blueHover whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1.5 group-hover:left-14 group-hover:w-fit`}
                            >
                                Реестр участников СВР
                            </h2>
                        </Link>
                    )}
                    {/* <Link */}
                    {/*    style={{ display: 'none' }} */}
                    {/*    to='/analytics' */}
                    {/*    className='group flex items-center text-sm gap-3.5 font-medium px-2 py-2 hover:bg-darkBlue' */}
                    {/* > */}
                    {/*    <div> */}
                    {/*        <Analytics /> */}
                    {/*    </div> */}
                    {/*    <h2 */}
                    {/*        className={`text-white whitespace-pre duration-500 ${ */}
                    {/*            !open && 'opacity-0 translate-x-28 overflow-hidden' */}
                    {/*        }`} */}
                    {/*    > */}
                    {/*        Отчеты и Аналитика */}
                    {/*    </h2> */}
                    {/*    <h2 */}
                    {/*        className={`${ */}
                    {/*            open && 'hidden' */}
                    {/*        } absolute z-50 left-48 bg-black whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:w-fit`} */}
                    {/*    > */}
                    {/*        Отчеты и Аналитика */}
                    {/*    </h2> */}
                    {/* </Link> */}
                    {/* <Link */}
                    {/*    style={{ display: 'none' }} */}
                    {/*    to='/auth' */}
                    {/*    className='group flex items-center text-sm gap-3.5 font-medium px-2 py-2 hover:bg-darkBlue' */}
                    {/* > */}
                    {/*    <div> */}
                    {/*        <Info /> */}
                    {/*    </div> */}
                    {/*    <h2 */}
                    {/*        className={`text-white whitespace-pre duration-500 ${ */}
                    {/*            !open && 'opacity-0 translate-x-28 overflow-hidden' */}
                    {/*        }`} */}
                    {/*    > */}
                    {/*        Логи */}
                    {/*    </h2> */}
                    {/*    <h2 */}
                    {/*        className={`${ */}
                    {/*            open && 'hidden' */}
                    {/*        } absolute z-50 left-48 bg-black whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:w-fit`} */}
                    {/*    > */}
                    {/*        Логи */}
                    {/*    </h2> */}
                    {/* </Link> */}
                </div>
                <div className={`absolute bottom-0 ${open ? 'w-64' : 'w-12'}`}>
                    <NavigationNotify open={open} />
                    <Link to='/' className='group flex items-center text-sm gap-3.5 font-medium px-2 py-2'>
                        <img src={logo} alt='Plinor' width={32} height={32} />
                        <h2
                            className={`text-white whitespace-pre uppercase duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'
                                }`}
                        >
                            Плинор
                        </h2>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Navigation;
