import React from 'react';

interface IFilterSvg {
    classSvg?: string;
}
const FilterSvg = ({classSvg}:IFilterSvg) => {
    return (
        <svg className={classSvg} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M21.0616 2H2.93859C2.10586 2 1.68566 3.01039 2.2757 3.60043L9.49999 10.8258V18.875C9.49999 19.1809 9.64925 19.4676 9.89988 19.643L13.0249 21.8298C13.6414 22.2613 14.5 21.8239 14.5 21.0617V10.8258L21.7245 3.60043C22.3133 3.01156 21.896 2 21.0616 2Z'
                fill='#2B6CB0'
            />
        </svg>
    );
};

export default FilterSvg;
