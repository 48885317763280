import React from 'react';
import Pagination from '../Pagination/Pagination';
import TableTitle from './TableTitle';
import TableStatus from './TableStatus';
import TableMenu from './TableMenu';
import Loader from '../Loader';
import { IUserAllInfo } from '../../models/base/user';
import TableLink from './TableLink';
import TableDelete from './TableDelete';
import { API_KEYS, ROUTES } from '../../utils/dictionary';
import useAppSelector from '../../hooks/redux';
import TableSearch from './TableSearch';
import TableMemberId from './TableMemberId';
import TableUserFilter from './TableUserFilter';
import { useDeleteUserMutation } from '../../store/users/users.api';
import ModalConfirm from '../ModalConfirm';

const apiKey = API_KEYS.Users;

const TableUser = () => {
    const { loading, params: currentParrams, list: usersData } = useAppSelector((state) => state.list.users);

    const [deleteUser, { isLoading }] = useDeleteUserMutation();

    // const debouncedisFetching = useDebounceBoolean(loading, 100);

    return (
        <div className='table-container'>
            <div className='table-wrapper'>
                <TableSearch apiKey={apiKey} />
                <TableUserFilter />
            </div>
            <Loader isLoading={loading}>
                <div className='max-w-full overflow-y-auto'>
                    <table className='table-content'>
                        <thead className='table-thead'>
                            <tr>
                                <TableTitle classTitle='w-[4%] pl-5' title='№' />
                                <TableTitle
                                    classTitle='w-[6%]'
                                    title='ID участника'
                                    filterKey='user_id'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[10%]'
                                    title='ФИО'
                                    filterKey='user_full_name'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[9%]'
                                    title='Регион'
                                    filterKey='region_name'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[9%]'
                                    title='Район'
                                    filterKey='district_name'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[6%]'
                                    title='Дата Регистраци'
                                    filterKey='user_date_created'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[6%]'
                                    title='Дата блокировки'
                                    filterKey='user_date_block'
                                    apiKey={apiKey}
                                />
                                <TableTitle classTitle='w-[6%]' title='Количество назнач. ХС' />
                                <TableTitle
                                    classTitle='w-[28%]'
                                    title='Хозяйствующие субъекты'
                                    filterKey='company_name_short'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[10%]'
                                    title='Роль'
                                    filterKey='role_name_short'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[4%]'
                                    title='Статус записи'
                                    filterKey='user_status'
                                    apiKey={apiKey}
                                />
                                <th scope='col' className='w-[4%] font-normal px-2 py-0 '>
                                    <span className='hidden'>Операции</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='table-tbody'>
                            {usersData?.map((user: IUserAllInfo, index: number) => {
                                return (
                                    <tr key={user.user_id} className='table-tbody-tr'>
                                        <th scope='row' className='table-tbody-th'>
                                            {(currentParrams.cur_page - 1) * currentParrams.per_page + index + 1}
                                        </th>
                                        <TableMemberId userId={user.user_id} />
                                        <td className='table-tbody-td'>
                                            {user.user_last} {user.user_first} {user.user_middle}
                                        </td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td className='table-tbody-td'>{user.user_regions_list_name}</td>
                                        <td className='table-tbody-td'>{user.user_districts_list_name}</td>
                                        <td className='table-tbody-td'>
                                            <div className='flex items-center justify-center'>
                                                {user.user_date_created}
                                            </div>
                                        </td>
                                        <td className='table-tbody-td'>
                                            <div className='flex items-center justify-center'>
                                                {user.user_date_block}
                                            </div>
                                        </td>
                                        <td className='table-tbody-td'>
                                            <span className='flex justify-center'>{user.user_companies_count}</span>
                                        </td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td className='table-tbody-td'>{user.user_companies_locations_list_name}</td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td className='table-tbody-td'>{user.user_roles_list_name}</td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td className='table-tbody-td'>
                                            <TableStatus status={user.user_status === 'enabled'} />
                                        </td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td>
                                            <div className='flex pr-2 justify-end items-center relative'>
                                                <TableMenu>
                                                    <TableLink title='Паспорт' link={`${ROUTES.User}${user.user_id}`} />
                                                    <ModalConfirm
                                                        textHeader='Подтвердите удаление пользователя'
                                                        onClick={() => deleteUser(user.user_id)}
                                                        type='danger'
                                                        textButtonConf='Удалить'
                                                        isLoading={isLoading}
                                                    >
                                                        <TableDelete colorLi='text-red' />
                                                    </ModalConfirm>
                                                </TableMenu>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Loader>
            <div className='px-5'>
                {/* {usersData && <Pagination dataSourcesInfo={usersData} setCurrentPage={handlerCurPageChange} setPerPage={handlerPerPageChange} currentPage={curPage} perPage={perPage} />} */}
                {/* {usersData && <Pagination dataSourcesInfo={pagination} apiKey={apiKey} />} */}
                {usersData && <Pagination apiKey={apiKey} />}
            </div>
        </div>
    );
};

export default TableUser;
