import React from 'react';

const Spinner = () => {
    return (
        <div className='w-full h-full fixed z-[1000] bg-[rgba(0_0_0_0.3)]'>
            <div className='lds-ring top-0 left-[45%] w-20 h-20 '>
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default Spinner;
