import { ReactComponent as ErrorIcon } from '../../image/svg/error-notif.svg';
import { ReactComponent as AcceptedIcon } from '../../image/svg/accepted.svg';
import { ReactComponent as InfoIcon } from "../../image/svg/info.svg";
import { ReactComponent as WarningIcon } from "../../image/svg/warning.svg";
import {NotificationTypeEnum} from "./types";

// eslint-disable-next-line import/prefer-default-export
export const icons = {
    [NotificationTypeEnum.Error]: ErrorIcon,
    [NotificationTypeEnum.Success]: AcceptedIcon,
    [NotificationTypeEnum.Info]: InfoIcon,
    [NotificationTypeEnum.Warning]: WarningIcon,
};
