import { useEffect } from 'react';
import { KeyCode } from '../utils/dictionary';

function useCloseEscape(close: boolean, onClose: (flag: boolean) => void) {
    useEffect(() => {
        const handleEsc = (e: KeyboardEvent) => {
            if (e.code === KeyCode.ESCAPE) {
                onClose(false);
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [close]);
}

export default useCloseEscape;
