import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { authActions } from './auth/auth.slice';
import { usersActions } from './users/users.slice';
import { animalsActions } from './animals/animals.slice';
import { AppDispatch } from '.';
import { dictionaryActions } from './dictionary.slice';
import { inputValuesActions } from './inputValues/inputValues.slice';
import { listActions } from './lists/lists.slice';
// import {companiesActions} from "./companies/company.slice";

const actions = {
    ...authActions,
    ...usersActions,
    ...animalsActions,
    // ...companiesActions,
    ...dictionaryActions,
    ...inputValuesActions,
    ...listActions,
};

const useActions = () => {
    const dispatch = useDispatch<AppDispatch>();
    return useMemo(() => bindActionCreators(actions, dispatch), []);
};

export default useActions;
