import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../utils/dictionary';
import LogoutButton from '../LogoutButton';
import useAppSelector from '../../hooks/redux';
import { useLazySetParticipationQuery } from '../../store/auth/auth.api';
import { IParticipationParams } from '../../models/auth';
import { IUserCompany, IUserDistrict, IUserRegion } from '../../models/models';

interface INavProfilePopup {
    setIsOpen: (flag: boolean) => void;
}

const NavProfilePopup = ({ setIsOpen }: INavProfilePopup) => {
    const [role, setRole] = useState<number>(0);
    const rolesList = useAppSelector((state) => state.auth.user.user_roles_list);
    const companiesList = useAppSelector((state) => state.auth.user.user_companies_locations_list);
    const districtsList = useAppSelector((state) => state.auth.user.user_districts_list);
    const regionsList = useAppSelector((state) => state.auth.user.user_regions_list);
    const rolesDictionary = useAppSelector((state) => state.dictionary.authUser?.user_roles_list);
    const companiesDictionary = useAppSelector((state) => state.dictionary.authUser?.user_companies_locations_list);
    const districtsDictionary = useAppSelector((state) => state.dictionary.authUser?.user_districts_list);
    const regionsDictionary = useAppSelector((state) => state.dictionary.authUser?.user_regions_list);

    const rolesNoAdmin = rolesList;

    const [setParticipation] = useLazySetParticipationQuery();

    const handlerChangeRole = (roleId: number) => {
        setRole(roleId);
        if (roleId === 1) {
            const params: IParticipationParams = {
                participation_item_id: 1,
                participation_type: 'admin',
            };
            setParticipation(params);
        }
    };

    const handlerParticipation = (
        item: IUserCompany | IUserDistrict | IUserRegion,
        key: 'company' | 'region' | 'district',
    ) => {
        if (!item.active) {
            let params: IParticipationParams = {
                participation_item_id: '',
                participation_type: 'company',
            };
            switch (key) {
                case 'company':
                    params = {
                        participation_item_id: (item as IUserCompany).company_location_id,
                        participation_type: key,
                    };
                    break;
                case 'district':
                    params = {
                        participation_item_id: (item as IUserDistrict).district_id,
                        participation_type: key,
                    };
                    break;
                case 'region':
                    params = {
                        participation_item_id: (item as IUserRegion).region_id,
                        participation_type: key,
                    };
                    break;

                default:
                    break;
            }
            setParticipation(params);
        }
    };

    useEffect(() => {
        if (rolesDictionary) {
            Object.values(rolesDictionary).forEach((item) => {
                if (item?.active) {
                    setRole(item.role_id);
                }
            });
        }
    }, [rolesDictionary]);

    const handleTitle = (region: string, dictionary: string) => {
        if (region && dictionary) {
            return (
                <span className='text-xs text-gray'>
                    {' '}
                    ({dictionary}, {region})
                </span>
            );
        } else if (region) {
            return <span className='text-xs text-gray'> ({region})</span>;
        } else if (dictionary) {
            return <span className='text-xs text-gray'> ({dictionary})</span>;
        }
        return <></>;
    };

    return (
        <div className='absolute duration-200 z-[999] min-w-[296px] top-[48px] right-[57px] pt-[16px] px-[14px] pb-[6px] rounded-lg border-blue border-[2px] bg-white'>
            <Link
                onClick={() => setIsOpen(false)}
                className='block text-center w-[265px] m-auto text-blue leading-[20px] mb-2 text-base link'
                to={ROUTES.Profile}
            >
                Перейти на профиль пользователя
            </Link>
            <div className='flex justify-end mb-[5px]'>
                <div className='flex flex-col max-h-[170px] overflow-y-auto'>
                    {companiesDictionary &&
                        role === 2 &&
                        companiesList.map((item, index) => (
                            <span
                                role='presentation'
                                onClick={() => handlerParticipation(companiesDictionary[item], 'company')}
                                key={index}
                                className={`px-4 pt-[6px] pb-[6px] w-full text-sm text-black hover:bg-lightBlue ${
                                    companiesDictionary[item]?.active && 'bg-ghostBlue'
                                } cursor-pointer`}
                            >
                                {companiesDictionary[item]?.company_name_short}
                                {handleTitle(
                                    companiesDictionary[item]?.region_name,
                                    companiesDictionary[item]?.district_name,
                                )}
                            </span>
                        ))}
                    {districtsDictionary &&
                        role === 3 &&
                        districtsList.map((item, index) => (
                            <span
                                role='presentation'
                                onClick={() => handlerParticipation(districtsDictionary[item], 'district')}
                                key={index}
                                className={`px-4 pt-[6px] pb-[6px] w-full text-sm text-black hover:bg-lightBlue ${
                                    districtsDictionary[item]?.active && 'bg-ghostBlue'
                                } cursor-pointer`}
                            >
                                {districtsDictionary[item]?.district_name}
                            </span>
                        ))}
                    {regionsDictionary &&
                        role === 4 &&
                        regionsList.map((item, index) => (
                            <span
                                role='presentation'
                                onClick={() => handlerParticipation(regionsDictionary[item], 'region')}
                                key={index}
                                className={`px-4 pt-[6px] pb-[6px] w-full text-sm text-black hover:bg-lightBlue ${
                                    regionsDictionary[item]?.active && 'bg-ghostBlue'
                                } cursor-pointer`}
                            >
                                {regionsDictionary[item]?.region_name}
                            </span>
                        ))}
                </div>
                <span className='border-r-[1px] border-borderGray ml-4 mr-4' />
                <div className='flex flex-col'>
                    {rolesDictionary &&
                        rolesNoAdmin.map((item, index) => {
                            const roleId = rolesDictionary[item]?.role_id;
                            return (
                                roleId && (
                                    <span
                                        role='presentation'
                                        onClick={() => handlerChangeRole(rolesDictionary[item].role_id)}
                                        key={index}
                                        className={`px-4 pt-[6px] pb-[6px] w-full text-sm text-black hover:bg-lightBlue ${
                                            role === rolesDictionary[item]?.role_id && 'bg-ghostBlue'
                                        } cursor-pointer  profile-disabled`}
                                    >
                                        {rolesDictionary[item]?.role_name_short}
                                    </span>
                                )
                            );
                        })}
                </div>
            </div>
            <LogoutButton styleButton='text-redDark font-bold hover:bg-redLight' />
        </div>
    );
};

export default NavProfilePopup;
