import React from 'react';
import Cross from '../../../image/svg/Cross';
import { useLazyAddAnimalToApplicationQuery } from '../../../store/applications/applications.api';
import useDataSourceParams from '../../../hooks/useDataSourceParams';
import { API_KEYS } from '../../../utils/dictionary';
import { IParams } from '../../../models/models';
import useAppSelector from '../../../hooks/redux';
import useActions from '../../../store/actions';
import ModalSelectedEdit from './ModalChange/ModalSelectedEdit';
import ModalFilteredEdit from './ModalChange/ModalFilteredEdit';

interface IAnimalsAddApplication {
    selectedAnimalsId: number[];
    setSelectedAnimalsId: React.Dispatch<React.SetStateAction<number[]>>;
}

// const apiKey = API_KEYS.Animals;

const AnimalsAddApplication = ({ selectedAnimalsId, setSelectedAnimalsId }: IAnimalsAddApplication) => {
    const [addAnimalToApplication] = useLazyAddAnimalToApplicationQuery();
    const { init } = useDataSourceParams(API_KEYS.Animals);
    const { setLoading } = useActions();

    const currentParams: IParams = useAppSelector((state) => state.list.animals.params);

    const addSelectedAnimals = () => {
        const requestParams = {
            body: {
                animal_id: selectedAnimalsId,
            },
        };
        if (selectedAnimalsId.length) {
            addAnimalToApplication(requestParams).then((resp) => {
                if (resp.isSuccess) {
                    init();
                    setSelectedAnimalsId([]);
                }
            });
        }
    };
    const addFilteredAnimal = async () => {
        setLoading({ key: API_KEYS.Animals, loading: true });
        const requestParams = Object.entries(currentParams).reduce(
            (acc, [key, value]) => {
                if (key.includes('filter')) {
                    return {
                        params: {
                            ...acc.params,
                            [key]: value,
                        },
                    };
                } else {
                    return acc;
                }
            },
            {
                params: {},
            },
        );

        await addAnimalToApplication(requestParams).then((resp) => {
            if (resp.isSuccess) {
                init(currentParams);
            }
        });
        setLoading({ key: API_KEYS.Animals, loading: false });
    };

    return (
        <div className='flex pl-4 justify-between items-center bg-paleYellow w-full h-[48px] border-b-[2px] border-backgroundModal'>
            <div className='flex items-center gap-6'>
                <span className='text-xs text-gray'>Выбрано: {selectedAnimalsId.length}</span>
                <button
                    type='button'
                    onClick={() => addSelectedAnimals()}
                    className={`${!selectedAnimalsId.length ? 'button-add__disabled' : 'button-add'}`}
                    disabled={!selectedAnimalsId.length}
                >
                    <Cross classSvg='plus-svg rotate-[45deg] mr-2' width='12' height='12' color='#CBD5E0' />
                    Добавить выбранные в заяку
                </button>
            </div>
            <div>
                <ModalSelectedEdit selectedAnimalsId={selectedAnimalsId} disabled={!selectedAnimalsId.length} />
            </div>
            <div>
                <ModalFilteredEdit />
            </div>
            <div className='flex items-center gap-6'>
                <button onClick={() => addFilteredAnimal()} type='button' className='button-add'>
                    <Cross classSvg='plus-svg rotate-[45deg] mr-2' width='12' height='12' color='#CBD5E0' />
                    Добавить отфильтрованные в заявку
                </button>
                <button
                    aria-label='delete'
                    type='button'
                    className='flex justify-center items-center w-[48px] h-[48px] border-l-[1px] border-backgroundModal'
                >
                    <Cross classSvg='cross-svg' color='#AEAEAE' width='14' height='14' />
                </button>
            </div>
        </div>
    );
};

export default AnimalsAddApplication;
