import React, { useEffect } from 'react';
import useDebounceString from '../../hooks/debounceString';
import useTableFilter from '../../hooks/useTableUserFilter';
import { EnterClick } from '../../utils/func';
import useAppSelector from '../../hooks/redux';
import useActions from '../../store/actions';
import { ApiKeysType } from '../../models/models';

interface IAnimalsTableSearchInput {
    apiKey: ApiKeysType;
    filterKey: string;
    title: string;
}

const AnimalsTableSearchInput = ({ filterKey, title, apiKey }: IAnimalsTableSearchInput) => {
    const { changeInputFilter } = useTableFilter(apiKey);
    const filterStoreValue = useAppSelector((state) => state.list[apiKey].filters[filterKey]);
    const { setFilters, deleteFilter } = useActions();

    // const [value, setValue] = useState<string | number>('');

    const debouncedValue = useDebounceString(filterStoreValue?.toString() ?? '', 500);

    useEffect(() => {
        if (debouncedValue?.length >= 3 || debouncedValue?.length === 0) {
            changeInputFilter(filterKey, debouncedValue);
        }
    }, [debouncedValue]);

    const onChangeHandler = (value: string) => {
        if (value) {
            setFilters({ storeKey: apiKey, filterKey, filterValue: value });
        } else {
            deleteFilter({ storeKey: apiKey, filterKey });
        }
    };

    const handlerSearchKeyDown = async (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (EnterClick(evt)) {
            changeInputFilter(filterKey, filterStoreValue);
        }
    };

    const className =
        'text-black w-full border-[1px] border-grayDisabled rounded outline-0 pt-1 pb-1 pl-3 pr-3 hover:border-blue focus:border-blue placeholder-grayDisabled text-xs';

    return (
        <input
            className={className}
            type='text'
            placeholder={title}
            value={filterStoreValue ?? ''}
            onChange={(evt) => onChangeHandler(evt.target.value)}
            onKeyDown={(evt) => handlerSearchKeyDown(evt)}
        />
    );
};
export default AnimalsTableSearchInput;
