import { Middleware, isRejected, isFulfilled } from '@reduxjs/toolkit';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getTokenFromLocalStorage } from '../utils/func';
import { NotificationManager } from '../components/notification';
import { NOTIF_TIMEOUT } from '../utils/dictionary';
import { authActions } from './auth/auth.slice';
import { notificationsActions } from './notifications/notifications.slice';

interface ActionPayload {
    message?: string;
    originalStatus?: number;
    data: {
        message?: string;
    };
}

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
        const token = getTokenFromLocalStorage();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
        return headers;
    },
});

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
    if (isFulfilled(action)) {
        const payload = action.payload as ActionPayload;
        const successMessage = payload?.message;
        if (successMessage) {
            NotificationManager.add({
                id: `success-${action.meta.requestId}`,
                type: 'success',
                timeout: NOTIF_TIMEOUT,
                text: successMessage,
            });
        }
    }
    if (isRejected(action)) {
        if (action.payload) {
            const payload = action?.payload as ActionPayload;
            const errorMessage = payload.data?.message;
            const originalStatus = payload?.originalStatus;

            if (errorMessage) {
                switch (true) {
                    case errorMessage.includes('токен'):
                        next(authActions.logout());
                        NotificationManager.add({
                            id: '403',
                            type: 'error',
                            timeout: NOTIF_TIMEOUT,
                            text: 'Ошибка аутентификации',
                        });
                        break;
                    case errorMessage.includes('не достаточно прав'):
                        NotificationManager.add({
                            id: '403',
                            type: 'error',
                            timeout: NOTIF_TIMEOUT,
                            text: 'У вас не достаточно прав для совершения запрошенной операции',
                        });
                        break;
                    case !!errorMessage:
                        NotificationManager.add({
                            id: `success${action.meta.requestId}`,
                            type: 'error',
                            timeout: NOTIF_TIMEOUT,
                            text: errorMessage,
                        });
                        break;
                    case errorMessage === 'FETCH_ERROR':
                        NotificationManager.add({
                            id: `networkError`,
                            type: 'error',
                            timeout: NOTIF_TIMEOUT,
                            text: 'Что-то пошло не так',
                        });
                        break;
                    default:
                        NotificationManager.add({
                            id: `404`,
                            type: 'error',
                            timeout: NOTIF_TIMEOUT,
                            header: `Ошибка ${originalStatus || ''}`,
                            text: errorMessage || 'Что-то пошло не так',
                        });
                        break;
                }
            }
        }
    }

    const aa = JSON.stringify(action);
    const aaa = JSON.parse(aa);
    if (aaa.payload?.notifications) {
        next(notificationsActions.setNotificationsInfo(aaa.payload));
    }

    return next(action);
};

export default rtkQueryErrorLogger;
