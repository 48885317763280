import React from 'react'
import IconPencil from '../../image/svg/iconPencil'
import useAppSelector from '../../hooks/redux';
import useActions from '../../store/actions';
import {useEditUserMutation} from '../../store/users/users.api';

const EditButton = () => {
    const {setEdit} = useActions();
    const edit = useAppSelector((state) => state.users.edit);
    const currentUser = useAppSelector((state) => state.users.currentUser);
    const inputs = useAppSelector((state) => state.inputs);
    const [editUser] = useEditUserMutation();

    const handleEditingUser = async () => {
        let keysObj = {};
        let changeFlag = false
        Object.entries(inputs).forEach(([key, value]) => {
            if (key.includes('user_edit')) {
                keysObj = {
                    ...keysObj,
                    [key.split('__')[0]]: value,
                };

                // Проверяем, изменилось ли значение
                if (value !== currentUser[key.split('__')[0]]) {
                    changeFlag = true
                }
            }
        })
        // Если были изменения, вызываем функцию editUser
        if (changeFlag) {
            editUser(keysObj);
        }
        setEdit(false);
    }

    return (
        <>
            {edit ? (
                <button
                    className='button-green'
                    type='button'
                    onClick={handleEditingUser}
                >
                    <IconPencil iconStyle='#fff' />
                    <span className='ml-[5px]'>Сохранить</span>
                </button>
            ) : (
                <button
                    className='button-green'
                    type='button'
                    onClick={() => setEdit(true)}
                >
                    <IconPencil iconStyle='#fff' />
                    <span className='ml-[5px]'>Редактировать</span>
                </button>
            )}
        </>
    )
}

export default EditButton