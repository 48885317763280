import React from 'react';
import Select from '../Inputs/Select';
import { useLazySetUserStatusQuery } from '../../store/users/users.api';
import useAppSelector from '../../hooks/redux';
import { UserStatusArray } from '../../utils/dictionary';

const ProfileStatusContainer = () => {
    const id = useAppSelector((state) => state.users.currentUser.user_id);
    const currentStatus = useAppSelector((state) => state.users.currentUser.user_status);

    const [setStatus] = useLazySetUserStatusQuery();

    const handlerSetStatus = (e: 'disabled' | 'enabled') => {
        setStatus({
            user_id: id,
            user_status: e,
        });
    };

    return (
        <>
            <Select
                placeholder='Статус'
                initValue={currentStatus}
                storeKey={'user_status'}
                array={UserStatusArray}
                itemKey={'status_key'}
                itemValue={'status_value'}
                onChange={(e: 'disabled' | 'enabled') => handlerSetStatus(e)}
            />
        </>
    );
};

export default ProfileStatusContainer;
