import {createSlice} from "@reduxjs/toolkit";
import {IApplication} from "../../models/base/applications";
import {applicationsApi} from "./applications.api";
import {NotificationManager} from "../../components/notification";
import {
    getRandomInteger
} from "../../components/notification/notification-response-message";
import {NOTIF_TIMEOUT} from "../../utils/dictionary";

interface ApplicationsState {
    currentApplication: IApplication
}

const initialState: ApplicationsState = {
    currentApplication: {
        application_id: 0,
        company_name_short: '',
        region_name: '',
        district_name: '',
        application_date_create: '',
        application_date_horriot: '',
        application_date_complete: '',
        application_status: '',
        user_id: 0,
        user_last: '',
        user_first: '',
        user_middle: '',
        user_avatar_small: '',
        user_avatar_big: '',
        user_status: '',
        user_date_create: '',
        user_date_block: '',
        user_phone: '',
        user_email: '',
        user_herriot_data: {
            login: '',
            password: '',
            web_login: '',
        },
        user_companies_count: 0,
        animals_list: [],

        user_name: '',
        user_role: '',
        application_status_name: '',
    }
}

export const ApplicationsSlice = createSlice({
    name: 'applications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(applicationsApi.endpoints.getApplication.matchFulfilled, (state, action) => {
                state.currentApplication = action.payload.data
            })
            .addMatcher(applicationsApi.endpoints.setStatus.matchFulfilled, (state, action) => {
                state.currentApplication = action.payload.data
                // NotificationManager.add({
                //     id: `success${getRandomInteger(1, 1000000)}`,
                //     type: 'success',
                //     timeout: NOTIF_TIMEOUT,
                //     text: action.payload.message ? action.payload.message :
                //         'Статус заявки успешно изменен',
                // });
            })
            .addMatcher(applicationsApi.endpoints.addAnimalToApplication.matchFulfilled, (_, action) => {
                NotificationManager.add({
                    id: `success${getRandomInteger(1, 1000000)}`,
                    type: 'info',
                    timeout: NOTIF_TIMEOUT,
                    text: action.payload.message ? action.payload.message :
                        'Животное не выбрано',
                });
            })
    }
})

export const applicationsReducer = ApplicationsSlice.reducer