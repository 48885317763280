import { createApi } from '@reduxjs/toolkit/query/react';
import { ICompanyObject, IServerResponse } from '../../models/models';
import { baseQuery } from '../middleware';

export const companyApi = createApi({
    reducerPath: 'companies/api',
    tagTypes: ['Company'],
    baseQuery,
    endpoints: (build) => ({
        getCompanyObjectsList: build.query<IServerResponse<ICompanyObject[]>, number | string | undefined>({
            query: (id: number | string) => ({
                url: `companies/company_objects_list/${id}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result?.data
                    ? [
                          ...result.data.map(({ company_object_id }) => ({
                              type: 'Company' as const,
                              company_object_id,
                          })),
                          { type: 'Company', id: 'Company' },
                      ]
                    : [{ type: 'Company', id: 'Company' }],

            transformResponse: (response: IServerResponse<ICompanyObject[]>) => {
                response.data.map((item) => {
                    item.company_object_full_name = `${item.company_object_approval_number} - ${item.company_object_address_view}`;
                    return item;
                });
                return response;
            },
        }),
        setFavoriteCompany: build.mutation<any, any>({
            query: (body) => ({
                url: `companies/set_company_objects_favorite`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Company', id: 'Company' }],
        }),
        unsetFavoriteCompany: build.mutation<any, any>({
            query: (body) => ({
                url: `companies/unset_company_objects_favorite`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Company', id: 'Company' }],
        }),
    }),
});

export const {
    useLazyGetCompanyObjectsListQuery,
    useGetCompanyObjectsListQuery,
    useSetFavoriteCompanyMutation,
    useUnsetFavoriteCompanyMutation,
} = companyApi;
