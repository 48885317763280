import React, { useState } from 'react';
import ModalInputAdd from './ModalInputAdd';
import TrashSvg from '../../../../image/svg/TrashSvg';

const AddAnimalsMarking = () => {
    const [inputList, setInputList] = useState([1]);

    const addInputComponent = () => {
        setInputList([...inputList, Math.max.apply(null, inputList) + 1]); // Делаем уникальные ключи, чтобы не повторялись
    };

    const removeInputComponent = (index: number) => {
        setInputList(inputList.filter((i) => i !== index));
    };

    return (
        <>
            <div className='flex flex-col'>
                {inputList.map((key) => (
                    <div key={key} className='flex items-center gap-2 w-full'>
                        <ModalInputAdd indexElement={key} />
                        {key > 1 ? (
                            <button
                                aria-label='delete'
                                type='button'
                                onClick={() => removeInputComponent(key)}
                                className='flex items-center mb-6'
                            >
                                <TrashSvg classSvg='icon-trash' color='#E53E3E' />
                            </button>
                        ) : (
                            <div className='w-[25px]' /> // Пустой div для смещения первого ряда
                        )}
                    </div>
                ))}
            </div>
            <button type='button' onClick={addInputComponent} className='text-blue'>
                Добавить вид номера
            </button>
        </>
    );
};

export default AddAnimalsMarking;
