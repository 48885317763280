import React from 'react';
import AnimalsTableSearchInput from './AnimalsTableSearchInput';
import { API_KEYS } from '../../utils/dictionary';

const apiKey = API_KEYS.Animals;

const AnimalsTableSearch = () => {
    return (
        <div className='relative w-[40%] flex justify-between items-center gap-3 p-[4.5px] pl-3'>
            <span className='text-xs text-gray w-20 min-w-16'>Фильтр по номерам:</span>
            <AnimalsTableSearchInput apiKey={apiKey} filterKey='search_unsm' title='№ УНСМ' />
            <AnimalsTableSearchInput apiKey={apiKey} filterKey='search_horriot_number' title='№ Хорриот' />
            <AnimalsTableSearchInput apiKey={apiKey} filterKey='search_inv' title='Инв. №' />
        </div>
    );
};
export default AnimalsTableSearch;
