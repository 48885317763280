import React from 'react';

interface ICheckSvg {
    color: string;
    classSvg: string;
}

const CheckSvg = ({classSvg, color}:ICheckSvg) => {
    return (
        <svg className={classSvg} width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M5.70312 11C5.62109 10.9908 5.54362 10.9724 5.4707 10.9448C5.39779 10.9079 5.33398 10.8573 5.2793 10.7928L1.27344 6.75967C1.19141 6.70442 1.12305 6.63075 1.06836 6.53867C1.02279 6.44659 1 6.34991 1 6.24862C1 6.08287 1.05924 5.94015 1.17773 5.82044C1.29622 5.70074 1.4375 5.64088 1.60156 5.64088C1.71094 5.64088 1.8112 5.66851 1.90234 5.72376C2.0026 5.77901 2.07552 5.85267 2.12109 5.94475L5.70312 9.53591L14.084 1.09669C14.1296 1.06906 14.1797 1.04604 14.2344 1.02762C14.2891 1.00921 14.3438 1 14.3984 1C14.5716 1 14.7129 1.05985 14.8223 1.17956C14.9408 1.29926 15 1.44199 15 1.60773C15 1.66298 14.9909 1.71823 14.9727 1.77348C14.9635 1.81952 14.9499 1.86556 14.9316 1.9116V1.89779L6.12695 10.7928C6.07227 10.8573 6.00846 10.9079 5.93555 10.9448C5.86263 10.9724 5.78516 10.9908 5.70312 11Z'
                fill={color}
                stroke={color}
            />
        </svg>
    );
};

export default CheckSvg;
