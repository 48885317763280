import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as Arrow} from '../../image/svg/arrowBlue.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useSetStateInputsValue from '../../hooks/useSetStateInputsValue';

interface ISelect {
    placeholder?: string
    array: any;
    initValue?: string | number
    storeKey: string
    itemKey: string
    itemValue: string
    onChange?: any
    label?: string
}

const Select = ({
                    placeholder,
                    initValue,
                    array,
                    storeKey,
                    itemKey,
                    itemValue,
                    onChange,
                    label
                }: ISelect) => {
    const {internalValue, setInternalValue} = useSetStateInputsValue(storeKey, initValue)
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState(initValue);
    const [search, setSearch] = useState('');

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setInputValue('')
        !initValue && setSearch('')
        array?.forEach((item: any) => {
            if (item[itemKey] === initValue) {
                setInputValue(item[itemValue])
            }
        })
    }, [initValue, array]);

    const onChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!search) {
            setInputValue('');
            setInternalValue('')
        }
        setSearch(value);
    };

    const onSelectItem = (item: Record<string, string>) => {
        setIsOpen(false);
        setInputValue(item[itemValue]);
        setSearch('');
        setInternalValue(item[itemKey]);
        onChange && onChange(item[itemKey])
    };

    const filterItems = array?.filter((item: any) => {
        return item[itemValue]?.toLowerCase().includes(search.toLowerCase());
    });
    const closeHandler = () => setIsOpen(false);
    useOnClickOutside(ref, closeHandler);

    return (
        <div ref={ref} className='w-full mb-6 relative'>
            <div
                role='presentation'
                className={`select-container relative ${isOpen ? 'rounded-t-lg border-blue' : 'rounded-lg border-borderGray'
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {label && (
                    <label
                        htmlFor={label}
                        className='input-label top-[-2px]'
                    >
                        {label}
                    </label>
                )}
                <input
                    type='text'
                    value={inputValue || search}
                    onChange={(e) => onChangeSelect(e)}
                    className='w-full h-full py-[10px] px-[12px] rounded-lg outline-0 font-bold'
                    placeholder={placeholder}
                />
                <Arrow
                    className={`w-[20px] h-[20px] cursor-pointer duration-200 ${isOpen ? 'rotate-[90deg]' : 'rotate-[-90deg]'}`}/>
            </div>
            {isOpen ? (
                <div className='select-items z-[1000]'>
                    {filterItems?.length ? (
                        filterItems.map((item: any) => (
                            <div
                                role='presentation'
                                key={item[itemKey]}
                                className={`${item[itemKey] === internalValue
                                    ? 'div-arrow bg-blue text-white'
                                    : ' div-arrow text-black'
                                } select-item`}
                                onClick={() => {
                                    onSelectItem(item);
                                }}
                            >
                                {item[itemValue]}
                            </div>
                        ))
                    ) : (
                        <div
                            className='text-sm h-[32px] flex justify-center items-center text-grayDisabled'>
                            Ничего не найдено
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Select;
