import React, {useEffect} from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import ApplicationsTable from "../components/Applications/ApplicationsTable";
import useAppSelector from '../hooks/redux';
import useDataSourceParams from '../hooks/useDataSourceParams';
import {API_KEYS} from '../utils/dictionary';

const apiKey = API_KEYS.Applications;

const ApplicationsPage = () => {
  const authUserDictionary = useAppSelector((state) => state.dictionary.authUser);
  const params = useAppSelector((state) => state.list.applications.params);

  const {init} = useDataSourceParams(apiKey);

  useEffect(() => {
    init(params)
  }, [authUserDictionary])

  return (
    <div className='pt-5 px-4 pb-4'>
      <div className='flex items-center justify-between'>
        <Breadcrumbs title='Реестр заявок' caption='Реестр заявок' />
        {/* <ButtonOrderSend /> */}
      </div>
      <ApplicationsTable />
    </div>
  );
};

export default ApplicationsPage;
