import React from 'react';
import {ReactComponent as Arrow} from '../../image/svg/arrow.svg';
import useDataSourceParams from '../../hooks/useDataSourceParams';
import useAppSelector from '../../hooks/redux';
import {ApiKeysType} from '../../models/models';
import {SORT_DIRECTION} from '../../utils/dictionary';

interface IButtonTable {
    title: string;
    filterKey?: string;
    apiKey?: ApiKeysType
    classTitle?: string;
}

const titleCursorPointerClass = 'flex items-center cursor-pointer';
const titleCursorDefaultClass = 'flex items-center cursor-default';

const TableTitle = ({title, filterKey, apiKey, classTitle}: IButtonTable) => {

    const setSorting = apiKey ? useDataSourceParams(apiKey).setSorting : () => { };
    const sortDirection = apiKey && useAppSelector((state) => state.list[apiKey]?.params.order_direction);
    const sortField = apiKey && useAppSelector((state) => state.list[apiKey]?.params.order_field);

    const handlerChangeSort = () => {
        if (apiKey) {
            setSorting(filterKey)
        }
    };

    return (
        <th onClick={handlerChangeSort} scope='col' className={`font-normal rounded-lg px-2 py-1 h-8 ${classTitle}`}>
            <div className={filterKey ? titleCursorPointerClass : titleCursorDefaultClass}>
                {filterKey && (
                    <div className='flex flex-col mr-2'>
                        <button className='rotate-180 mb-1 w-[10px] h-[6px]' type='button' aria-label='Save'>
                            <Arrow className={filterKey === sortField && sortDirection === SORT_DIRECTION.Asc ? 'text-blue' : 'text-gray hover:text-blueSky'} />
                        </button>
                        <button className='w-[10px] h-[6px]' type='button' aria-label='Save'>
                            <Arrow className={filterKey === sortField && sortDirection === SORT_DIRECTION.Desc ? 'text-blue' : 'text-gray hover:text-blueSky'} />
                        </button>
                    </div>
                )}
                {title}
            </div>
        </th>
    );
};

export default TableTitle;
