import React from 'react';
import useAppSelector from "../../hooks/redux";
import {
    useLazyAddAnimalToApplicationQuery
} from "../../store/applications/applications.api";
import {ANIMAL_STATUS} from '../../utils/dictionary';

const InfoAnimal = () => {
    const [addAnimalToApplication] = useLazyAddAnimalToApplicationQuery()
    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);

    return (
        <div className={'min-w-auto bg-white rounded-lg p-4'}>
            <div className='flex flex-col mb-4'>
                <span className='text-gray text-xs mb-1'>Вид животного</span>
                <span
                    className='text-sm text-black bg-inherit w-full'>{currentAnimal.main?.animal_specie_name || '-'}</span>
            </div>
            <div className='flex flex-col mb-4'>
                <span className='text-gray text-xs mb-1'>№ УНСМ</span>
                <span
                    className='text-sm text-black bg-inherit w-full'>{currentAnimal.main?.animal_rshn || '-'}</span>
            </div>
            <div className='flex flex-col mb-4'>
                <span className='text-gray text-xs mb-1'>Инвентарный номер</span>
                <span
                    className='text-sm text-black bg-inherit w-full'>{currentAnimal.main?.animal_inv || '-'}</span>
            </div>
            <div className='flex flex-col mb-4'>
                <span className='text-gray text-xs mb-1'>GUID животного</span>
                <span
                    className='text-sm text-black bg-inherit w-full'>{currentAnimal.main?.animal_guid || '-'}</span>
            </div>
            <div className='flex flex-col mb-4'>
                <span className='text-gray text-xs mb-1'>№ Хорриот</span>
                <span
                    className='text-sm text-black bg-inherit w-full'>{currentAnimal.main?.animal_horriot_number || '-'}</span>
            </div>
            <div className='flex flex-col mb-4'>
                <span className='text-gray text-xs mb-1'>Статус</span>
                <span
                    className='text-sm text-black bg-inherit w-full'>
                    {currentAnimal.main?.animal_status_card === 'enabled' ? 'Активная' : 'Не активная'}
                </span>
            </div>
            <div className='flex justify-center'>
                {
                    ((currentAnimal.main?.animal_status_record === ANIMAL_STATUS.Added || currentAnimal.main?.animal_status_record === ANIMAL_STATUS.Rejected) && currentAnimal.main?.animal_registration_available) &&
                    <button className='text-blue text-sm hover:text-blueHover'
                        onClick={() => addAnimalToApplication({body: {animal_id: [currentAnimal.main?.animal_id]}})}
                        type='button'>
                        Добавить в заявку на регистрацию
                    </button>
                }
            </div>
        </div>
    );
};

export default InfoAnimal;
