import React from 'react';
import useSetStateInputsValue from '../../hooks/useSetStateInputsValue';
import useAppSelector from '../../hooks/redux';
import { emojiRegex } from '../../utils/dictionary';

interface IInput {
    label?: string;
    type: string;
    storeKey: string;
    value?: string | number | number[];
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    error?: string;
}

const Input = ({ type, storeKey, value, label, disabled, placeholder, className, error }: IInput) => {
    const { internalValue, setInternalValue } = useSetStateInputsValue(storeKey, value);
    const edit = useAppSelector((state) => state.users.edit);
    const editing = disabled ? false : edit;

    return (
        <div className={`${editing ? 'w-full' : 'h-[40px]'} relative flex flex-col mb-6`}>
            {label && (
                <label className={`${editing ? 'input-label' : 'label-user'} duration-200`} htmlFor={storeKey}>
                    {label}
                </label>
            )}
            <input
                className={`${editing ? 'input' : 'input-user'} duration-200 ${className}`}
                type={type}
                id={storeKey}
                name={storeKey}
                placeholder={placeholder}
                value={internalValue}
                onChange={(value) => setInternalValue(value.target.value.replace(emojiRegex, ''))}
                disabled={disabled}
            />
            {/* Отображение валидации */}
            {error && <p className=' absolute text-xs text-red top-[40px]'>{error}</p>}
        </div>
    );
};

export default Input;
