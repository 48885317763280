import { createApi } from '@reduxjs/toolkit/query/react';
import {
    IAnimalKeepingPurposesList,
    IAnimalKeepingTypesList,
    IBreedType,
    IDistrictType,
    IGenderType,
    IGetBreedType,
    IMarkStatus,
    IMarkToolType,
    IMarkTypeId,
    IRegionType,
    IServerResponse,
    ISpeciesType,
    IToolLocation,
} from '../../models/models';
import { params } from '../../utils/dictionary';
import { baseQuery } from '../middleware';

export const directoriesApi = createApi({
    reducerPath: 'directories/api',
    baseQuery,
    endpoints: (build) => ({
        getCountriesList: build.query<IServerResponse<any[]>, void>({
            query: () => ({
                url: `directories/countries`,
                method: 'GET',
                params,
            }),
        }),
        getRegionsList: build.query<IServerResponse<IRegionType[]>, string | void>({
            query: (region_name) => ({
                url: `directories/regions`,
                method: 'GET',
                params: { ...params, region_name },
            }),
        }),
        getDistrictsList: build.query<
            IServerResponse<IDistrictType[]>,
            { region_id: string | number; search: string | number }
        >({
            query: ({ region_id, search }) => ({
                url: `directories/districts`,
                method: 'GET',
                params: { ...params, region_id, district_name: search },
            }),
        }),
        getCompaniesList: build.query<
            IServerResponse<any[]>,
            { region_id: string | number; district_id: string | number; search: string | number }
        >({
            query: ({ region_id, district_id, search }) => ({
                url: `companies/list`,
                method: 'GET',
                params: { ...params, region_id, district_id, search },
            }),
        }),
        getCompaniesLocationList: build.query<IServerResponse<any[]>, string | void>({
            query: (search) => ({
                url: `companies/locations_list`,
                method: 'GET',
                params: search ? { ...params, search } : params,
            }),
        }),
        getSpeciesList: build.query<IServerResponse<ISpeciesType[]>, string | void>({
            // виды животных
            query: (search) => ({
                url: `directories/species`,
                method: 'GET',
                params: search ? { ...params, search } : params,
            }),
        }),
        getBreedsList: build.query<IServerResponse<IBreedType[]>, IGetBreedType>({
            // породы животных
            query: ({ specie_id, search }) => ({
                url: `directories/breeds`,
                method: 'GET',
                params: { ...params, specie_id, breed_name: search },
            }),
        }),
        getGendersList: build.query<IServerResponse<IGenderType[]>, string | void>({
            // породы животных
            query: () => ({
                url: `directories/genders`,
                method: 'GET',
                params,
            }),
        }),
        getMarkTypesList: build.query<IServerResponse<IMarkTypeId[]>, string | void>({
            // вид номера маркирования
            query: () => ({
                url: `directories/mark_types`,
                method: 'GET',
                params,
            }),
        }),
        getMarkStatusesList: build.query<IServerResponse<IMarkStatus[]>, string | void>({
            // вид маркировки
            query: () => ({
                url: `directories/mark_statuses`,
                method: 'GET',
                params,
            }),
        }),
        getMarkToolTypesList: build.query<IServerResponse<IMarkToolType[]>, string | void>({
            // тип средства маркировки
            query: () => ({
                url: `directories/mark_tool_types`,
                method: 'GET',
                params,
            }),
        }),
        getToolsLocationsList: build.query<IServerResponse<IToolLocation[]>, string | void>({
            // место нанесения маркировки
            query: () => ({
                url: `directories/tools_locations`,
                method: 'GET',
                params,
            }),
        }),
        getAnimalKeepingTypeList: build.query<
            IServerResponse<IAnimalKeepingTypesList[]>,
            { specie_id: number | undefined }
        >({
            // тип содержания
            query: ({ specie_id }) => ({
                url: `directories/keeping_types`,
                method: 'GET',
                params: { ...params, specie_id },
            }),
        }),
        getAnimalKeepingPurposeList: build.query<IServerResponse<IAnimalKeepingPurposesList[]>, string | void>({
            // цели содержания
            query: () => ({
                url: `directories/keeping_purposes`,
                method: 'GET',
                params,
            }),
        }),
    }),
});

export const {
    useGetDistrictsListQuery,
    useGetRegionsListQuery,
    useGetCompaniesListQuery,
    useGetCompaniesLocationListQuery,
    useGetSpeciesListQuery,
    useGetBreedsListQuery,
    useGetGendersListQuery,
    useGetMarkTypesListQuery,
    useGetMarkStatusesListQuery,
    useGetMarkToolTypesListQuery,
    useGetToolsLocationsListQuery,
    useGetAnimalKeepingTypeListQuery,
    useGetAnimalKeepingPurposeListQuery,
} = directoriesApi;
