import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import user from '../../image/icon-user.png';
import useAppSelector from '../../hooks/redux';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import NavProfilePopup from './NavProfilePopup';
import {ReactComponent as Arrow} from '../../image/svg/arrow.svg';
import {useLazyGetAuthUserQuery} from '../../store/users/users.api';
import {ROUTES} from '../../utils/dictionary';

const NavProfile = () => {
    const authUserDictionary = useAppSelector((state) => state.dictionary.authUser);

    const {
        user_id: id,
        user_avatar_small: userAvatarSmall,
        user_first,
        user_last,
    } = useAppSelector((state) => state.auth.user);
    const currentUserId = useAppSelector((state) => state.users.currentUser.user_id);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedHoz, setSelectedHoz] = useState<string | number>('');
    const [selectedTitle, setSelectedTitle] = useState('');
    const [getAuthUser] = useLazyGetAuthUserQuery();
    const {pathname} = useLocation();

    useEffect(() => {
        let roleId = 0;
        let titleFlag = true;
        let hozFlag = true;
        if (authUserDictionary?.user_roles_list) {
            Object.values(authUserDictionary.user_roles_list).forEach((item) => {
                if (item.active) {
                    setSelectedTitle(item.role_name_short);
                    roleId = item.role_id;
                    titleFlag = false
                }
            });
        }
        if (titleFlag) {
            setSelectedTitle('');
        }

        switch (roleId) {
            case 2:
                if (authUserDictionary?.user_companies_locations_list) {
                    Object.values(authUserDictionary.user_companies_locations_list).forEach((item) => {
                        if (item.active) {
                            setSelectedHoz(item.company_name_short);
                            hozFlag = false
                        }
                    });
                }
                break;
            case 3:
                if (authUserDictionary?.user_districts_list) {
                    Object.values(authUserDictionary.user_districts_list).forEach((item) => {
                        if (item.active) {
                            setSelectedHoz(item.district_name);
                            hozFlag = false
                        }
                    });
                }
                break;
            case 4:
                if (authUserDictionary?.user_regions_list) {
                    Object.values(authUserDictionary.user_regions_list).forEach((item) => {
                        if (item.active) {
                            setSelectedHoz(item.region_name);
                            hozFlag = false
                        }
                    });
                }
                break;

            default:
                break;
        }
        if (hozFlag) {
            setSelectedHoz('');
        }

    }, [authUserDictionary]);

    const ref = useRef<HTMLDivElement>(null);
    const closeHandler = () => setIsOpen(false);
    useOnClickOutside(ref, closeHandler);

    useEffect(() => {
        getAuthUser(id);
    }, []);

    return (
        <div ref={ref} className='relative'>
            <div className='flex justify-end w-full px-4 bg-white'>
                <div
                    role='presentation'
                    onClick={() => setIsOpen(!isOpen)}
                    className={`flex items-center justify-end min-w-[200px] px-2 py-1 cursor-pointer hover:bg-ghostBlue duration-200 ${isOpen && 'bg-ghostBlue'
                        } ${pathname.includes(ROUTES.Profile) && Number(id) === Number(currentUserId) && 'bg-ghostBlue'}`}
                >
                    <div className='flex flex-col items-end px-2'>
                        <h2 className='text-sm'>
                            {user_first} {user_last}
                        </h2>
                        <div className='flex'>
                            {selectedTitle !== 'Админ' && <>
                                {selectedHoz ? (
                                    <span className='text-sm text-black'>{selectedHoz}</span>
                                ) : (
                                    <span className='text-sm text-black'>не выбрано</span>
                                )}
                                <span className='border-r-[1px] border-borderGray ml-[19px] mr-[19px]' />
                            </>}
                            {selectedTitle ? (
                                <span className={`${selectedTitle === 'Админ' ? 'w-[65px]' : 'w-[145px]'}  flex flex-nowrap justify-between items-center text-sm text-black`}>
                                    {selectedTitle}
                                    <Arrow className='text-blue ml-2 mt-[2px] w-[12px] h-[12px]' />
                                </span>
                            ) : (
                                <span className='w-[145px] flex flex-nowrap justify-between items-center text-sm text-black'>
                                    роль не выбрана
                                    <Arrow className='text-blue ml-2 mt-[2px] w-[12px] h-[12px]' />
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='rounded-[50%]'>
                        <img
                            className='w-[32px] h-[32px] object-cover rounded-[50%]'
                            src={userAvatarSmall || user}
                            alt='user'
                            width={32}
                            height={32}
                        />
                    </div>
                </div>
            </div>
            {isOpen && <NavProfilePopup setIsOpen={setIsOpen} />}
        </div>
    );
};

export default NavProfile;
