import {createSlice} from "@reduxjs/toolkit";
import {IRolesInfo} from "../../models/models";
import {rolesApi} from "./roles.api";

export const LS_AUTH_KEY = 'svr-auth'

interface IRolesList {
    rolesList: IRolesInfo[]
}

const initialState: IRolesList = {
    rolesList: []
}

export const RolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(rolesApi.endpoints.getRolesList.matchFulfilled, (state, action) => {
                state.rolesList = action.payload.data
            })
    }
})

export const rolesActions = RolesSlice.actions
export const rolesReducer = RolesSlice.reducer