import React from 'react';
import AcceptedSvg from '../../image/svg/AcceptedSvg';
import {ReactComponent as Error} from '../../image/svg/cross-err.svg';
import CheckSvg from '../../image/svg/CheckSvg';
import {ReactComponent as Popover} from '../../image/svg/popover.svg';
import Cross from '../../image/svg/Cross';
import {APPLICATION_STATUS} from '../../utils/dictionary';

interface IAnimalsStatus {
    status: string | undefined;
}

const ApplicationsStatus = ({status}: IAnimalsStatus) => {
    const statusItem = () => {
        switch (status) {
            case APPLICATION_STATUS.CompletePartial:
                return (
                    <span className='popover'>
                        <CheckSvg classSvg='w-[18px] h-[18px]' color='#DA721B' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Обработана частично</span>
                        </span>
                    </span>
                );
            case APPLICATION_STATUS.CompleteFull:
                return (
                    <span className='popover'>
                        <CheckSvg classSvg='w-[18px] h-[18px]' color='#6BB1FA' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Обработана частично</span>
                        </span>
                    </span>
                );
            case APPLICATION_STATUS.Created:
                return (
                    <span className='popover'>
                        <CheckSvg classSvg='w-[18px] h-[18px]' color='#718096' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Создана</span>
                        </span>
                    </span>
                );
            case APPLICATION_STATUS.Sent:
                return (
                    <span className='popover'>
                        <Cross classSvg='rotate-[45deg]' color='#ED8936' width='16' height='16' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Отправлена</span>
                        </span>
                    </span>
                );
            case APPLICATION_STATUS.Prepared:
                return (
                    <span className='popover'>
                        <AcceptedSvg classSvg='w-[25px] h-[25px]' color='#2B6CB0' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Сформирована</span>
                        </span>
                    </span>
                );
            case APPLICATION_STATUS.Finished:
                return (
                    <span className='popover'>
                        <AcceptedSvg classSvg='w-[25px] h-[25px]' color='#38A169' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Завершена</span>
                        </span>
                    </span>
                );
            case 'rejected':
                return (
                    <span className='popover'>
                        <Error className='w-[18px] h-[18px]' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Отказ</span>
                        </span>
                    </span>
                );
            default:
                return null;
        }
    };
    return <>{statusItem()}</>;
};

export default ApplicationsStatus;
