import React from 'react';

interface ITabsContent {
    regionName: string;
    districtName: string;
}

const ProfileTabsItem = ({ districtName, regionName }: ITabsContent) => {
    return (
        <>
            <div className='flex flex-col mb-4'>
                <span className='text-gray text-xs mb-1'>Страна</span>
                <span className='text-sm text-black bg-inherit w-full'>Россия</span>
            </div>
            <div className='flex flex-col mb-4'>
                <span className='text-gray text-xs mb-1'>Область</span>
                {districtName ? (
                    <span className='text-sm text-black bg-inherit w-full'>{districtName}</span>
                ) : (
                    <span className='text-sm text-black bg-inherit w-full'>не указано</span>
                )}
            </div>
            <div className='flex flex-col mb-4'>
                <span className='text-gray text-xs mb-1'>Район</span>
                {regionName ? (
                    <span className='text-sm text-black bg-inherit w-full'>{regionName}</span>
                ) : (
                    <span className='text-sm text-black bg-inherit w-full'>не указано</span>
                )}
            </div>
        </>
    );
};

export default ProfileTabsItem;
