import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import ApplicationButton from '../components/Applications/ApplicationButton';
import ApplicationTable from '../components/Applications/ApplicationTable';
import { useLazyGetApplicationQuery } from '../store/applications/applications.api';
import useAppSelector from '../hooks/redux';
import useActions from '../store/actions';
import { API_KEYS } from '../utils/dictionary';

const ApplicationPage = () => {
    const currentApplication = useAppSelector((state) => state.applications.currentApplication);
    const params = useParams<'id'>();
    const [getApplication] = useLazyGetApplicationQuery();
    const { setHiddenParam } = useActions();

    const paramsQuery = {
        id: params.id,
    };

    useEffect(() => {
        getApplication(paramsQuery);
        if (params.id) {
            setHiddenParam({
                key: API_KEYS.ApplicationAnimals,
                filterKey: 'filter[application_id]',
                filterValue: params.id,
            });
        }
    }, [params.id]);

    return (
        <div className='pt-5 px-4 pb-4'>
            <div className='flex items-center justify-between'>
                <Breadcrumbs
                    title={`Заявка №${currentApplication.application_id}`}
                    caption={`Заявка №${currentApplication.application_id} от
                    ${currentApplication.application_date_create}
                    ${currentApplication.user_first} ${currentApplication.user_middle} ${currentApplication.user_last}`}
                />
                <ApplicationButton />
            </div>

            <ApplicationTable />
        </div>
    );
};

export default ApplicationPage;
