import useAppSelector from './redux';
import useActions from '../store/actions';
import { ApiKeysType, IParams } from '../models/models';
import { useLazyGetUsersQuery } from '../store/users/users.api';
import { useLazyGetAnimalsFromApplicationQuery, useLazyGetAnimalsQuery } from '../store/animals/animals.api';
import { API_KEYS } from '../utils/dictionary';
import { useLazyGetApplicationsQuery } from '../store/applications/applications.api';

const params: IParams = {
    cur_page: 1,
    per_page: 10,
};

const paramsAnimals = {
    cur_page: 1,
    per_page: 100,
};

function useDataSourceParams(key: ApiKeysType) {
    const { setParams, setLoading } = useActions();
    const currentParams: IParams = useAppSelector((state) => state.list[key].params);
    const hiddenPapam = useAppSelector((state) => state.list[key].hiddenParam);
    // console.log(hiddenPapam);

    let newParams: IParams = { ...currentParams };

    const [getUser] = useLazyGetUsersQuery();
    const [getAnimals] = useLazyGetAnimalsQuery();
    const [getAnimalsFromApplication] = useLazyGetAnimalsFromApplicationQuery();
    const [getApplications] = useLazyGetApplicationsQuery();

    const wrapGetFunction = async (params: IParams, key: ApiKeysType) => {
        let response: any;

        const mergedParams = hiddenPapam ? { ...params, ...hiddenPapam } : params;
        // console.log(mergedParams);

        switch (key) {
            case API_KEYS.Users:
                response = await getUser(mergedParams);
                return response;
            case API_KEYS.Animals:
                response = await getAnimals(mergedParams);
                return response;
            case API_KEYS.ApplicationAnimals:
                response = await getAnimalsFromApplication(mergedParams);
                return response;
            case API_KEYS.Applications:
                response = await getApplications(mergedParams);
                return response;
            default:
                return response;
        }
    };

    const init = async (initParams?: { [key: string]: string | number | undefined }) => {
        // setHiddenFilter({key, filter: initParams})
        setLoading({ key, loading: true });
        if (key === API_KEYS.Animals || key === API_KEYS.ApplicationAnimals) {
            await wrapGetFunction({ ...paramsAnimals, ...initParams }, key);
        } else {
            await wrapGetFunction({ ...params, ...initParams }, key);
        }
        setLoading({ key, loading: false });
    };
    // const reset = async () => {
    //     setLoading({key, loading: true})
    //     await wrapGetFunction(params, key);
    //     setLoading({key, loading: false})
    // }

    const setSorting = async (value: string | undefined) => {
        setLoading({ key, loading: true });

        newParams = { ...currentParams, order_field: value, order_direction: 'asc' };

        if (currentParams.order_field === value && currentParams.order_direction === 'asc') {
            newParams = { ...currentParams, order_field: value, order_direction: 'desc' };
        }
        if (currentParams.order_field === value && currentParams.order_direction === 'desc') {
            delete newParams.order_field;
            delete newParams.order_direction;
        }

        const response = await wrapGetFunction(newParams, key);

        if (response.data?.status) {
            setParams({ key, params: newParams });
        }

        setLoading({ key, loading: false });
    };

    const setCurPage = async (value: number) => {
        setLoading({ key, loading: true });

        newParams = { ...currentParams, cur_page: value };
        const response = await wrapGetFunction(newParams, key);

        if (response.data?.status) {
            setParams({ key, params: newParams });
        }

        setLoading({ key, loading: false });
    };

    const setPerPage = async (value: number) => {
        setLoading({ key, loading: true });

        newParams = { ...currentParams, per_page: value };
        const response: any = await wrapGetFunction(newParams, key);

        if (response.data?.status) {
            setParams({ key, params: newParams });
        } else if (
            response?.error?.data?.pagination.max_page &&
            currentParams.cur_page > response.error.data.pagination.max_page
        ) {
            newParams = { ...currentParams, per_page: value, cur_page: response?.error.data.pagination.max_page };
            const resp: any = await wrapGetFunction(newParams, key);

            if (resp.data?.status) {
                setParams({ key, params: newParams });
            }
        }

        setLoading({ key, loading: false });
    };

    const setSearch = async (value: string) => {
        if (value) {
            setLoading({ key, loading: true });

            newParams = { ...currentParams, cur_page: 1, search: value };

            const response = await wrapGetFunction(newParams, key);

            if (response.data?.status) {
                setParams({ key, params: newParams });
            }
        } else if (!value && currentParams.search) {
            setLoading({ key, loading: true });

            newParams = { ...currentParams, cur_page: 1 };
            delete newParams.search;

            wrapGetFunction(newParams, key);
        }

        setLoading({ key, loading: false });
    };

    const setFilter = async (filters: { [key: string]: string | number }) => {
        const filtersParams: { [key: string]: string } = Object.entries(filters).reduce((acc, [key2, value]) => {
            return { ...acc, [`filter[${key2}]`]: value };
        }, {});

        newParams = { per_page: currentParams.per_page, cur_page: 1, ...filtersParams };

        if (!Object.values(filters).length) {
            newParams = Object.keys(currentParams).reduce(
                (acc, key) => {
                    if (!key.includes('filter')) {
                        return {
                            ...acc,
                            [key]: currentParams[key],
                        };
                    } else {
                        return acc;
                    }
                },
                { cur_page: 1, per_page: 10 },
            );
        }

        if (JSON.stringify(newParams) !== JSON.stringify(currentParams)) {
            setLoading({ key, loading: true });
            await wrapGetFunction(newParams, key);
            setLoading({ key, loading: false });
        }
    };

    return {
        setSorting,
        setCurPage,
        setPerPage,
        setSearch,
        setFilter,
        init,
        // reset
    };
}

export default useDataSourceParams;
