import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IDictionary} from "../models/models";
import {usersApi} from "./users/users.api";
import {authApi} from "./auth/auth.api";
import {AuthSlice} from "./auth/auth.slice";
import {animalsApi} from "./animals/animals.api";

export const LS_AUTH_KEY = 'svr-auth'
interface DictionaryState {
    current?: IDictionary,
    authUser?: IDictionary,
}

const initialState: DictionaryState = {
    current: {},
    authUser: {}
}

export const DictionarySlice = createSlice({
    name: 'dictionary',
    initialState,
    reducers: {
        setCurrentDictionary(state, action: PayloadAction<IDictionary>) {
            state.current = action.payload
        },
        setAuthDictionary(state, action: PayloadAction<IDictionary>) {
            state.authUser = action.payload
        },
        api_updateDictionary(state, action) {
            const currentId = action.payload.data.user_id
            const authId = AuthSlice.reducer(undefined, action).user.user_id
            if (+authId === +currentId) {
                state.authUser = action.payload.dictionary
            }
            state.current = action.payload.dictionary
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
                state.authUser = {}
            })
            .addMatcher(authApi.endpoints.setParticipation.matchFulfilled, (state, action) => {
                state.authUser = action.payload.dictionary;
            })
            .addMatcher(usersApi.endpoints.getUser.matchFulfilled, (state, action) => {
                state.current = action.payload.dictionary
            })
            .addMatcher(usersApi.endpoints.getUsers.matchFulfilled, (state, action) => {
                state.current = action.payload.dictionary
            })
            .addMatcher(usersApi.endpoints.getUsers.matchFulfilled, (state, action) => {
                state.current = action.payload.dictionary
            })
            .addMatcher(usersApi.endpoints.getAuthUser.matchFulfilled, (state, action) => {
                state.authUser = action.payload.dictionary;
            })
            .addMatcher(usersApi.endpoints.addUserCompany.matchFulfilled, (state, action) => {
                DictionarySlice.caseReducers.api_updateDictionary(state, action)
            })
            .addMatcher(usersApi.endpoints.deleteUserCompany.matchFulfilled, (state, action) => {
                DictionarySlice.caseReducers.api_updateDictionary(state, action)
            })
            .addMatcher(usersApi.endpoints.addUserLocation.matchFulfilled, (state, action) => {
                DictionarySlice.caseReducers.api_updateDictionary(state, action)
            })
            .addMatcher(usersApi.endpoints.deleteUserLocation.matchFulfilled, (state, action) => {
                DictionarySlice.caseReducers.api_updateDictionary(state, action)
            })
            .addMatcher(usersApi.endpoints.addUserRole.matchFulfilled, (state, action) => {
                DictionarySlice.caseReducers.api_updateDictionary(state, action)
            })
            .addMatcher(usersApi.endpoints.deleteUserRole.matchFulfilled, (state, action) => {
                DictionarySlice.caseReducers.api_updateDictionary(state, action)
            })
            .addMatcher(animalsApi.endpoints.getAnimals.matchFulfilled, (state, action) => {
                state.current = action.payload.dictionary
            })
    }
})

export const dictionaryActions = DictionarySlice.actions
export const dictionaryReducer = DictionarySlice.reducer