import React, { useEffect, useState } from 'react';
import { ApiKeysType } from '../../models/models';
import useDataSourceParams from '../../hooks/useDataSourceParams';
import useDebounceString from '../../hooks/debounceString';
import { ReactComponent as IconSearch } from '../../image/svg/search.svg';
import useAppSelector from '../../hooks/redux';
import { EnterClick } from '../../utils/func';

interface ITableSearch {
    apiKey: ApiKeysType;
}

const TableSearch = ({ apiKey }: ITableSearch) => {
    const { setSearch } = useDataSourceParams(apiKey);
    const initSearch = useAppSelector((state) => state.list[apiKey].params.search);
    const [searchValue, setValueSearch] = useState<string>(initSearch ?? '');

    const debouncedValue = useDebounceString(searchValue, 500);

    useEffect(() => {
        if (debouncedValue.length >= 3 || debouncedValue.length === 0) {
            setSearch(debouncedValue);
        }
    }, [debouncedValue]);

    const handlerSearchKeyDown = async (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (EnterClick(evt)) {
            setSearch(debouncedValue);
        }
    };

    return (
        <div className='relative w-[85%]'>
            <IconSearch className='absolute top-[8px] left-[4px]' />
            <input
                className={
                    'text-black w-full outline-0 pt-[11px] pb-[10px] pl-[33px] pr-[12px] hover:border-blue focus:border-blue placeholder-grayDisabled text-sm'
                }
                type='text'
                placeholder='Поиск по разделу'
                value={searchValue}
                onChange={(evt) => setValueSearch(evt.target.value)}
                onKeyDown={(evt) => handlerSearchKeyDown(evt)}
            />
        </div>
    );
};
export default TableSearch;
