import React, { useCallback } from 'react';
import useAppSelector from '../../../hooks/redux';
import { useLazyEditAnimalKeepingPurposeQuery, useLazyGetAnimalPartQuery } from '../../../store/animals/animals.api';
import Select from '../../Inputs/Select';
import { useGetAnimalKeepingPurposeListQuery } from '../../../store/directories/directories.api';

interface IAnimalBirthSelect {
    id?: string;
    description?: boolean;
    storeKey?: string;
    onChange?: boolean;
}
const AnimalKeepingPurposeSelect = ({ id, description = true, storeKey, onChange = true }: IAnimalBirthSelect) => {
    const paramsQuery = {
        id,
        body: { data_sections: ['main', 'base', 'mark', 'genealogy', 'vib', 'registration', 'history'] },
    };

    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);
    const [editAnimalKeepingType] = useLazyEditAnimalKeepingPurposeQuery();
    const [getAnimal] = useLazyGetAnimalPartQuery();
    const { data } = useGetAnimalKeepingPurposeListQuery();

    const setAnimalKeepingType = useCallback(
        async (keeping_purpose_id: number) => {
            await editAnimalKeepingType({
                animal_id: currentAnimal.base?.animal_id,
                keeping_purpose: keeping_purpose_id,
            });
            getAnimal(paramsQuery);
        },
        [editAnimalKeepingType, getAnimal, paramsQuery, currentAnimal.base?.animal_id],
    );

    return (
        <>
            <span className='text-gray text-xs mb-1'>Цель содержания</span>
            <Select
                placeholder='Цель содержания'
                initValue={currentAnimal.base?.animal_keeping_purpose}
                storeKey={storeKey ?? 'animal_keeping_purpose_id__select'}
                array={data?.data}
                itemKey={'keeping_purpose_id'}
                itemValue={'keeping_purpose_name'}
                onChange={onChange ? setAnimalKeepingType : undefined}
            />
            {description && (
                <span className='text-sm text-black bg-inherit w-full'>
                    {currentAnimal.base?.animal_keeping_purpose_name || '-'}
                </span>
            )}
        </>
    );
};

export default AnimalKeepingPurposeSelect;
