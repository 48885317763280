import React, { useState } from 'react';
import useCloseEscape from '../hooks/useCloseEscape';
import Cross from "../image/svg/Cross";

interface IPopupNotif {
    children: React.ReactNode;
    textHeader: string;
    type?: 'danger' | 'default';
    onClick: () => void;
    textButtonConf: string;
    isLoading?: boolean;
}

const ModalConfirm = ({ type, textHeader, children, onClick, textButtonConf, isLoading }: IPopupNotif) => {
    const [open, setOpen] = useState(false);
    const onClickButton = () => {
        onClick();
        setOpen(false);
    };
    useCloseEscape(open, setOpen);

    return (
        <>
            <div role='presentation' onClick={() => setOpen(true)}>
                {children}
            </div>
            {open ? (
                <>
                    <div className='justify-center shadow-lg items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[1002] outline-none focus:outline-none'>
                        <div className='relative w-full mx-auto max-w-[500px] min-w-[280px]'>
                            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                                <div className='bg-white min-h-[136px] rounded-lg px-[24px] py-[16px] flex flex-col justify-between'>
                                    <div className='flex justify-between'>
                                        <h2 className='text-[22px] font-bold'>{textHeader}</h2>
                                        <button aria-label='close' type='button' onClick={() => setOpen(false)}>
                                            <Cross color='#AEAEAE' classSvg='cross-svg w-[16px] h-[16px]' />
                                        </button>
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <button
                                            onClick={() => setOpen(false)}
                                            className='text-blue text-sm hover:text-blueHover'
                                            type='button'
                                        >
                                            Отмена
                                        </button>
                                        <button
                                            className={`${
                                                type === 'danger' ? 'bg-red hover:bg-redHover' : 'bg-blue hover:bg-blueHover'
                                            } bg-blue rounded-lg text-sm text-white px-4 py-2`}
                                            type='button'
                                            onClick={onClickButton}
                                            disabled={isLoading}
                                        >
                                            {textButtonConf}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='opacity-25 fixed inset-0 z-[1001] bg-black' />{' '}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default ModalConfirm;
