import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AntDatePicker from '../../Inputs/AntDatePicker';
import InputDefault from '../../Inputs/InputDefault';
import ImageSingleDownload from './ImageSingleDownload';
import { IAnimalMarkItems } from '../../../models/base/animals';
import useAppSelector from '../../../hooks/redux';
import { useLazyAnimalsMarkEditQuery, useLazyGetAnimalPartQuery } from '../../../store/animals/animals.api';
import validateCheck from '../../../utils/validateCheck';
import { markSchema } from '../../../utils/validation';
import SelectMarkToolLocation from './SelectMarkToolLocation';
import SelectMarkStatus from './SelectMarkStatus';
import SelectMarkToolType from './SelectMarkToolType';

interface IModalMarking {
    item: IAnimalMarkItems;
    setShowModal: (flag: boolean) => void;
}

interface IParamModal {
    mark_id: number;

    [key: string]: number | string;
}

const ModalMarking = ({ item, setShowModal }: IModalMarking) => {
    const [getAnimal] = useLazyGetAnimalPartQuery();
    const inputs = useAppSelector((state) => state.inputs);
    const [errors, setErrors] = useState<{ [x: string]: string }>();
    const [markParams, setMarkParams] = useState<IParamModal>({ mark_id: item.mark_id });
    const [markEdit, {isLoading}] = useLazyAnimalsMarkEditQuery();
    const [isTouched, setIsTouched] = useState(false)

    const { id } = useParams();
    const paramsQuery = {
        id,
        body: { data_sections: ['main', 'base', 'mark', 'genealogy', 'vib', 'registration', 'history'] },
    };

    useEffect(() => {
        const initialParams = { ...markParams };

        Object.entries(inputs).forEach(([key, value]) => {
            if (key.includes('mark')) {
                initialParams[key.split('__')[0]] = value;
            }
        });
        setMarkParams(initialParams);
    }, [inputs]);

    useEffect(() => {
        if (isTouched) {
            getAllErrors(markParams).then(setErrors);
        }
    }, [markParams, isTouched]);

    const getAllErrors = async (markObj: IParamModal): Promise<{ [x: string]: string } | undefined> => {
        return validateCheck({ validateObj: markObj, schema: markSchema });
    };

    const handleSubmit = async () => {
        setIsTouched(true);
        const newErrors = await getAllErrors(markParams) || {};
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            await markEdit(markParams);
            setErrors({});
            setShowModal(false);
            getAnimal(paramsQuery);
        }
    };

    return (
        <>
            <h2 className='text-base text-black font-bold mb-4'>Средство маркирования</h2>
            <div className='flex w-full gap-4'>
                <InputDefault
                    type='text'
                    label='Номер'
                    storeKey='number__mark'
                    placeholder='Номер'
                    value={item?.number}
                    disabled
                />
                <InputDefault
                    type='text'
                    storeKey='number__mark'
                    placeholder='Вид номера'
                    label='Вид номера'
                    value={item?.mark_type_name}
                    disabled
                />
            </div>
            <div className='flex w-full gap-4'>
                <SelectMarkStatus
                    label='Вид маркировки'
                    initValue={item.mark_status ? item.mark_status : undefined}
                    error={errors?.mark_status}
                    required
                />
                <SelectMarkToolType
                    initValue={item.mark_tool_type ? item.mark_tool_type : undefined}
                    label='Тип средства'
                    error={errors?.mark_tool_type}
                    required
                />
            </div>
            <div className='flex w-full gap-4'>
                <SelectMarkToolLocation
                    initValue={item.mark_tool_location ? item.mark_tool_location : undefined}
                    label='Место нанесения'
                    error={errors?.mark_tool_location}
                    required
                />
                <InputDefault
                    type='text'
                    label='Описание'
                    placeholder='Описание'
                    storeKey='description__mark'
                    value={item.description}
                    error={errors?.description}
                    required
                    isTouched={isTouched}
                    setIsTouched={setIsTouched}
                />
            </div>
            <div className='flex w-full gap-4'>
                <AntDatePicker
                    label='Дата нанесения'
                    date={item.mark_date_set}
                    storeKey='mark_date_set'
                    placeholder='Дата нанесения'
                    error={errors?.mark_date_set}
                    required
                    isTouched={isTouched}
                    setIsTouched={setIsTouched}
                />
                <AntDatePicker
                    label='Дата выбытия'
                    storeKey='mark_date_out'
                    date={item.mark_date_out}
                    placeholder='Дата выбытия'
                    error={errors?.mark_date_out}
                />
            </div>
            <h2 className='text-base text-black font-bold'>Фото</h2>
            <ImageSingleDownload item={item} />
            <div className='flex items-center justify-between rounded-b'>
                <button
                    className='text-sm text-blue cursor-pointer py-2 hover:text-blueHover'
                    type='button'
                    onClick={() => setShowModal(false)}
                >
                    Отмена
                </button>
                <button
                    className='bg-blue py-2 px-6 text-white rounded-lg text-sm cursor-pointer hover:bg-blueHover'
                    type='button'
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    Сохранить
                </button>
            </div>
        </>
    );
};

export default ModalMarking;
