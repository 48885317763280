import React from 'react';
import AcceptedSvg from '../../../image/svg/AcceptedSvg';
import {ReactComponent as Error} from '../../../image/svg/cross-err.svg';
import CheckSvg from '../../../image/svg/CheckSvg';
import {ReactComponent as Popover} from '../../../image/svg/popover.svg';
import Cross from '../../../image/svg/Cross';
import {ANIMAL_REGISTRATION_STATUS} from "../../../utils/dictionary";

interface IAnimalsStatus {
    status: string | undefined;
    error?: string;
}

const AnimalsStatus = ({status, error}: IAnimalsStatus) => {
    const statusItem = () => {
        switch (status) {
            case ANIMAL_REGISTRATION_STATUS.Added:
                return (
                    <span className='popover'>
                        <CheckSvg classSvg='w-[18px] h-[18px]' color='#718096' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>В системе</span>
                        </span>
                    </span>
                );
            case ANIMAL_REGISTRATION_STATUS.InApplication:
                return (
                    <span className='popover'>
                        <Cross classSvg='rotate-[45deg]' color='#ED8936' width='16' height='16' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Добавлено</span>
                        </span>
                    </span>
                );
            case ANIMAL_REGISTRATION_STATUS.Sent:
                return (
                    <span className='popover'>
                        <AcceptedSvg classSvg='w-[25px] h-[25px]' color='#2B6CB0' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Отправлено</span>
                        </span>
                    </span>
                );
            case ANIMAL_REGISTRATION_STATUS.Registered:
                return (
                    <span className='popover'>
                        <AcceptedSvg classSvg='w-[25px] h-[25px]' color='#38A169' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>Зарегистрировано</span>
                        </span>
                    </span>
                );
            case ANIMAL_REGISTRATION_STATUS.Rejected:
                return (
                    <span className='popover'>
                        <Error className='w-[18px] h-[18px]' />
                        <Popover className='popover-svg' />
                        <span className='popover-span-hidden'>
                            <span>{error ?? 'Отказ'}</span>
                        </span>
                    </span>
                );
            default:
                return null;
        }
    };
    return <>{statusItem()}</>;
};

export default AnimalsStatus;
