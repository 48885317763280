import React, {useCallback, useEffect, useState} from 'react';
import { NotificationManager } from '.';
import './notifications.css';
import Cross from '../../image/svg/Cross';
import {NotificationType} from "./types";

interface IPortalsInsideProps extends NotificationType {
    children: React.ReactNode;
}

const PortalsInside: React.FC<IPortalsInsideProps> = ({ type, text, header, id, children }) => {
    const [isRemoving, setIsRemoving] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const removeNotification = useCallback(() => {
        setIsRemoving(true);
        setTimeout(() => {
            NotificationManager.remove(id);
        }, 500);
    }, [id]);
    return (
        <div className={`portals-notify portals-notify-${type} ${isVisible ? 'slide-in' : ''} ${isRemoving ? 'fade-out' : ''}`}>
            <div className={`portals-text-${type}`}>
                <div className='absolute top-[19px] right-[16px] cursor-pointer'>
                    <button
                        aria-label='close'
                        type='button'
                        onClick={removeNotification}
                        className='hover:opacity-80'
                    >
                        <Cross color='#1A202C' width='14' height='14' />
                    </button>
                </div>
                <div className='w-[40px]'>{children}</div>
                <div className='portal__container ml-3'>
                    <h3 title={header && header?.length > 60 ? header : ''} className='text-header'>{header}</h3>
                    <p className='text-overflow'>{text}</p>
                </div>
            </div>
        </div>
    );
};

export default PortalsInside;
