import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useAppSelector from './hooks/redux';
import Navigation from './components/Navigation';
import AuthPage from './pages/AuthPage';
import NavProfile from './components/Profile/NavProfile';
// import ForgotPassPage from './pages/ForgotPassPage';
import PageNotFound from './pages/PageNotFound';
import AnimalPage from './pages/AnimalPage';
import ApplicationsPage from './pages/ApplicationsPage';
import AnimalsPage from './pages/AnimalsPage';
import UsersPage from './pages/UsersPage';
// import LogsPage from './pages/LogsPage';
// import AnalyticsPage from './pages/AnalyticsPage';
import { NOTIF_TIMEOUT, ROLES, ROUTES } from './utils/dictionary';
import ProfilePage from './pages/ProfilePage';
import ApplicationPage from './pages/ApplicationPage';
// import TestPage from './pages/PageTest';
import { NotificationManager } from './components/notification';
import useNetworkStatus from './hooks/useNetworkStatus';

const App = () => {
    const [open, setOpen] = useState(false);
    const userToken = useAppSelector((state) => state.auth.user.user_token);
    const userRoles = useAppSelector((state) => state.auth.user.user_roles_list);
    const currentUserId = useAppSelector((state) => state.users.currentUser.user_id);
    const authId = useAppSelector((state) => state.auth.user.user_id);

    const [pathname, setPathname] = useState<string>(ROUTES.Home);
    const [prevPathname, setPrevPathname] = useState<string>(ROUTES.Home);
    const location = useLocation();
    const isOnline = useNetworkStatus();
    const navigate = useNavigate();

    // запоминаем страницу с которой попали на страницу авторизации
    // чтобы перенаправить на неё после авторизации строка 63
    useEffect(() => {
        setPathname(location.pathname);
        pathname === ROUTES.Auth ? setPrevPathname(ROUTES.Home) : setPrevPathname(pathname);
    }, [location.pathname]);

    // Проверяем если id пользователя совпадает с id текущей страницы, то перекидываем на страницу /profile
    useEffect(() => {
        // Функция для извлечения id из URL
        const id = pathname.split('/')[pathname.length - 1];

        if (currentUserId && Number(id) === currentUserId && currentUserId === Number(authId)) {
            navigate(ROUTES.Profile);
        }
    }, [location.pathname, currentUserId, authId, navigate]);

    // Функция которая отслеживает есть ли интеренет у пользователя
    if (!isOnline) {
        NotificationManager.add({
            id: `error-network`,
            type: 'error',
            timeout: NOTIF_TIMEOUT,
            text: 'Нет подключения к сети',
        });
    }

    if (!userToken) {
        return (
            <>
                <Routes>
                    <Route path={ROUTES.Auth} element={<AuthPage />} />
                </Routes>
                <Navigate to={ROUTES.Auth} replace />
            </>
        );
    }

    return (
        <>
            <Navigation open={open} setOpen={setOpen} />
            <div className='relative'>
                <div
                    id='height'
                    className={`min-w-100 absolute top-0 flex-grow bg-backgroundModal duration-500 ${
                        open ? 'w-[calc(100%-256px)] left-[256px]' : 'w-[calc(100%-48px)] left-[48px]'
                    }`}
                >
                    <NavProfile />
                    <Routes>
                        {userRoles.includes(ROLES.Admin) && (
                            <>
                                <Route path={ROUTES.Users} element={<UsersPage />} />
                                <Route path={ROUTES.User}>
                                    <Route path={ROUTES.Id} element={<ProfilePage />} />
                                </Route>
                            </>
                        )}
                        <Route path={ROUTES.Home} element={<Navigate to={ROUTES.Animals} replace />} />
                        <Route path={ROUTES.Applications} element={<ApplicationsPage />} />
                        <Route path={ROUTES.Application}>
                            <Route path={ROUTES.Id} element={<ApplicationPage />} />
                        </Route>
                        <Route path={ROUTES.Animals} element={<AnimalsPage />} />
                        <Route path={ROUTES.Animal}>
                            <Route path={ROUTES.Id} element={<AnimalPage />} />
                        </Route>
                        <Route path={ROUTES.Profile} element={<ProfilePage />} />
                        <Route path={ROUTES.Auth} element={<Navigate to={prevPathname} replace />} />
                        <Route path={ROUTES.PageNotFound} element={<PageNotFound />} />
                    </Routes>
                </div>
            </div>
        </>
    );
};

export default App;
