import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getTokenFromLocalStorage } from '../../utils/func';
import { IServerResponse } from '../../models/models';
import { IAuthUser } from '../../models/base/user';
import { IParticipationParams } from '../../models/auth';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
        const token = getTokenFromLocalStorage();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
        return headers;
    },
});

export const authApi = createApi({
    reducerPath: 'auth/api',
    baseQuery,
    endpoints: (build) => ({
        login: build.query<IServerResponse<IAuthUser>, any>({
            query: (payload) => ({
                url: '/auth/login',
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }),
        }),
        logout: build.query<any, void>({
            query: () => ({
                url: `/auth/logout`,
                method: 'GET',
            }),
        }),
        setParticipation: build.query<any, IParticipationParams>({
            query: (params) => ({
                url: `/auth/set`,
                method: 'GET',
                params,
            }),
        }),
    }),
});

export const { useLazyLoginQuery, useLazyLogoutQuery, useLazySetParticipationQuery } = authApi;
