import React from 'react';

interface ICross {
    color?: string;
    width?: string;
    classSvg?: string;
    height?: string;
    onClick?: () => void;
}
const Cross = ({ color, width, height, onClick, classSvg }: ICross) => (
    <svg
        className={`close-icon ${classSvg}`}
        onClick={onClick}
        width={`${width || '20'}`}
        height={`${height || '20'}`}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M2 18C12 8 16.8333 3.16667 18 2'
            stroke={`${color || '#D83737'}`}
            strokeWidth='4'
            strokeLinecap='round'
        />
        <path
            d='M18 18C8 8 3.16667 3.16667 2 2'
            stroke={`${color || '#D83737'}`}
            strokeWidth='4'
            strokeLinecap='round'
        />
    </svg>
);

export default Cross;
