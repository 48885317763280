import React from "react";

interface ITrashSvg {
    classSvg?: string;
    color?: string;
}

const TrashSvg = ({classSvg, color}:ITrashSvg) => {
  return(
      <svg className={classSvg} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.0856 3.02174H15.2727V2.97826C15.2727 2.15217 14.5508 1.5 13.6364 1.5H10.3636C9.4492 1.5 8.72727 2.15217 8.72727 2.97826V3.02174H3.91444C3.40909 3.02174 3 3.3913 3 3.84783V4.67391C3 5.13043 3.40909 5.5 3.91444 5.5H20.0856C20.5909 5.5 21 5.13043 21 4.67391V3.84783C21 3.3913 20.5909 3.02174 20.0856 3.02174Z" fill={color}/>
          <path d="M18.4907 7.5H5.52752C5.27823 7.5 5.09693 7.70168 5.11959 7.94818L5.75415 20.923C5.82214 22.3571 7.02328 23.5 8.47371 23.5H15.5219C16.9723 23.5 18.1735 22.3796 18.2415 20.923L18.876 7.94818C18.9213 7.72409 18.7174 7.5 18.4907 7.5ZM10.604 20.0266C10.604 20.43 10.2867 20.7437 9.87881 20.7437C9.47088 20.7437 9.1536 20.43 9.1536 20.0266V10.9958C9.1536 10.5924 9.47088 10.2787 9.87881 10.2787C10.2867 10.2787 10.604 10.5924 10.604 10.9958V20.0266ZM14.8647 20.0266C14.8647 20.43 14.5474 20.7437 14.1395 20.7437C13.7315 20.7437 13.4142 20.43 13.4142 20.0266V10.9958C13.4142 10.5924 13.7315 10.2787 14.1395 10.2787C14.5474 10.2787 14.8647 10.5924 14.8647 10.9958V20.0266Z" fill={color}/>
      </svg>
  )
}

export default TrashSvg;