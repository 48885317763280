import React, { useState } from 'react';
import ButtonClearFilter from '../../ButtonClearFilter';
import AnimalsFilter from './AnimalsFilter';
import Drawer from '../../Drawer';
import { API_KEYS } from '../../../utils/dictionary';

const storeKey = API_KEYS.Animals;

const AnimalsTableFilter = () => {
    const [open, setOpen] = useState(false);

    return (
        <div className='flex items-center'>
            <ButtonClearFilter storeKey={storeKey} />
            <Drawer open={open} setOpen={setOpen} storeKey={storeKey}>
                <AnimalsFilter setOpen={setOpen} />
            </Drawer>
        </div>
    );
};

export default AnimalsTableFilter;
