import { createApi } from '@reduxjs/toolkit/query/react';
import { IParams, IServerResponse } from '../../models/models';

import { baseQuery } from '../middleware';

const params: IParams = {
    cur_page: 1,
    per_page: 100,
};
export const rolesApi = createApi({
    reducerPath: 'roles/api',
    baseQuery,
    endpoints: (build) => ({
        getRolesList: build.query<IServerResponse<any[]>, void>({
            query: () => ({
                url: `roles/list`,
                method: 'GET',
                params,
            }),
        }),
    }),
});

export const { useLazyGetRolesListQuery, useGetRolesListQuery } = rolesApi;
