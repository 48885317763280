import { NotificationManager } from './index';

export const getRandomInteger = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min)) + min;
};
export const NotificationResponseMessage = () => {
    const SuccessMessage = (e: any) => {
        if (e.request.status === 200) {
            NotificationManager.add({
                id: `success${getRandomInteger(1, 1000000)}`,
                type: 'success',
                timeout: 0,
                text: 'Успешно',
            });
        }
    };
    const responseMessage = (e: any) => {
        if (e.message === 'Network Error') {
            NotificationManager.add({
                id: `networkError${getRandomInteger(1, 1000000)}`,
                type: 'error',
                timeout: 15000,
                text: 'Нет подключения к сети',
            });
        }

        if (e.request.status === 401 || e.request.status === 422) {
            NotificationManager.add({
                id: `networkError${getRandomInteger(1, 1000000)}`,
                type: 'error',
                timeout: 15000,
                text: 'Неверные данные',
            });
        }

        if (e.request.status === 404) {
            NotificationManager.add({
                id: `networkError${getRandomInteger(1, 1000000)}`,
                type: 'error',
                timeout: 15000,
                text: 'Not found',
            });
        }

        if (e.request.status === 429) {
            NotificationManager.add({
                id: `networkError${getRandomInteger(1, 1000000)}`,
                type: 'error',
                timeout: 15000,
                text: 'Too many request',
            });
        }

        if (e.request.status >= 500) {
            NotificationManager.add({
                id: `networkError${getRandomInteger(1, 1000000)}`,
                type: 'error',
                timeout: 15000,
                text: 'Ошибка сервера',
            });
        }

        if (e.request && !e.response) {
            NotificationManager.add({
                id: `networkError${getRandomInteger(1, 1000000)}`,
                type: 'error',
                timeout: 15000,
                text: 'Ошибка подключения',
            });
        }
    };

    return {
        SuccessMessage,
        responseMessage,
    };
};
