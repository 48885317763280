import React from 'react';
import {ReactComponent as User} from '../../image/svg/user.svg';

interface ITableMemberId {
    userId: string | number;
}
const TableMemberId = ({userId}: ITableMemberId) => {
    return (
        <td className='px-1 py-2'>
            <div className='flex items-center'>
                <User className='mr-2' />
                <span>{userId}</span>
            </div>
        </td>
    );
};

export default TableMemberId;
