import React from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {ROUTES_TITLE} from '../utils/dictionary';

export interface IBreadcrumbs {
    caption: string | undefined | React.JSX.Element;
    title: string | undefined | React.JSX.Element;
}

const Breadcrumbs = ({ title, caption }: IBreadcrumbs) => {
    const location = useLocation();
    const params = useParams();
    const sep = <span className='text-sm text-blue px-4'>|</span>;
    const keys = Object.keys(ROUTES_TITLE);
    const arrPath = location.pathname.split('/');

    /** убрать из массива пути последний роут, чтобы заменить его на title */
    arrPath.pop();
    if (Object.keys(params).length) {
        /** убрать из массива пути еще один роут, т.к. это параметр */
        arrPath.pop();
    }

    return (
        <div className='mb-[14px] relative'>
            <div>
                <h2 className='text-[24px] text-black'>{caption}</h2>
                <div className='flex items-center'>
                    {arrPath.map((item, index) => {
                        let element = <span key={index} />;
                        keys.forEach((key) => {
                            const safeKey = key as keyof typeof ROUTES_TITLE;
                            if (ROUTES_TITLE[safeKey].pathname === `/${item}`) {
                                element = (
                                    <Link
                                        key={index}
                                        to={ROUTES_TITLE[safeKey].pathname}
                                        className='text-sm text-blue hover:text-blueHover'
                                    >
                                        <span key={index + ROUTES_TITLE[safeKey].title}>
                                            {ROUTES_TITLE[safeKey].title}
                                        </span>
                                    </Link>
                                );

                                if (index > 0) {
                                    element = (
                                        <span key={index}>
                                            {sep}
                                            {element}
                                        </span>
                                    );
                                }
                            }
                        });
                        return element;
                    })}
                    {sep}
                    <span key='title'>
                        <span className='text-sm'>{title ?? ''}</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Breadcrumbs;
