import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from '../../image/svg/arrowBlue.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useAppSelector from '../../hooks/redux';
import { ListsState } from '../../store/lists/lists.slice';
import useActions from '../../store/actions';

interface ISelect {
    placeholder?: string;
    listObject: { [key: string]: string };
    storeKey: keyof ListsState;
    filterKey: string;
    label?: string;
}

const FilterSelect = ({ placeholder, listObject, storeKey, filterKey, label }: ISelect) => {
    const filterStoreValue = useAppSelector((state) => state.list[storeKey].filters[filterKey]);
    const { setFilters, deleteFilter } = useActions();

    const [isOpen, setIsOpen] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [search, setSearch] = useState('');

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setInputValue('');
        !filterStoreValue && setSearch('');
        if (listObject[filterStoreValue]) {
            setInputValue(listObject[filterStoreValue]);
        }
    }, [filterStoreValue]);

    const onChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        deleteFilter({ storeKey, filterKey });
        setInputValue(value);
        setSearch(value);
    };

    const onSelectItem = (key: string, value: string) => {
        setIsOpen(false);
        setInputValue(value);
        setSearch('');
        setFilters({ storeKey, filterKey, filterValue: key });
        // onChange && onChange(item[itemKey]);
    };

    const filtredItems: { [key: string]: string } = Object.entries(listObject).reduce((acc, [key, value]) => {
        if (value.toLowerCase().includes(search.toLowerCase())) {
            return { ...acc, [key]: value };
        }
        return acc;
    }, {});

    const closeHandler = () => setIsOpen(false);
    useOnClickOutside(ref, closeHandler);

    return (
        <div ref={ref} className='w-full mb-6 relative'>
            <div
                role='presentation'
                className={`select-container relative ${
                    isOpen ? 'rounded-t-lg border-blue' : 'rounded-lg border-borderGray'
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {label && (
                    <label htmlFor={label} className='input-label top-[-2px]'>
                        {label}
                    </label>
                )}
                <input
                    type='text'
                    value={inputValue}
                    onChange={(e) => onChangeSelect(e)}
                    className='w-full h-full py-[10px] px-[12px] rounded-lg outline-0 font-bold'
                    placeholder={placeholder}
                />
                <Arrow
                    className={`w-[20px] h-[20px] cursor-pointer duration-200 ${
                        isOpen ? 'rotate-[90deg]' : 'rotate-[-90deg]'
                    }`}
                />
            </div>
            {isOpen ? (
                <div className='select-items z-[1000]'>
                    {Object.entries(filtredItems)?.length ? (
                        Object.entries(filtredItems).map(([key, value]) => (
                            <div
                                role='presentation'
                                key={key}
                                className={`${
                                    key === filterStoreValue ? 'div-arrow bg-blue text-white' : ' div-arrow text-black'
                                } select-item`}
                                onClick={() => {
                                    onSelectItem(key, value);
                                }}
                            >
                                {value}
                            </div>
                        ))
                    ) : (
                        <div className='text-sm h-[32px] flex justify-center items-center text-grayDisabled'>
                            Ничего не найдено
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default FilterSelect;
