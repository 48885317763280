import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const LS_AUTH_KEY = 'svr-auth'


interface IStateInputs {
    [key: string]: any
}

const initialState: IStateInputs = {}

export const InputValuesSlice = createSlice({
    name: 'inputs',
    initialState,
    reducers: {
        setInputValue(state, action: PayloadAction<{key: string, value: string | number}>) {
            state[action.payload.key] = action.payload.value
        },
        deleteInputValue(state, action: PayloadAction<string>) {
            if (state[action.payload]) {
                delete state[action.payload]
            }
        },
    },
})

export const inputValuesActions = InputValuesSlice.actions
export const inputValuesReducer = InputValuesSlice.reducer