import React, {useState} from 'react';
import MultiSelect from '../../Inputs/MultiSelect';
import useAppSelector from '../../../hooks/redux';
import {IChangeParamsFunc} from "../../../models/models";
import {useLazyAddUserLocationQuery, useLazyDeleteUserLocationQuery} from "../../../store/users/users.api";
import {useGetDistrictsListQuery} from "../../../store/directories/directories.api";
import useDebounceString from '../../../hooks/debounceString';

const ChooseDistrict = () => {
    const userId = useAppSelector((state) => state.users.currentUser.user_id);

    const currentUserDistricts = useAppSelector((state) => state.users.currentUser.user_districts_list);
    const currentUserDistrictsDictionary = useAppSelector((state) => state.dictionary.current?.user_districts_list);
    const [searchDistrict, setSearchDistrict] = useState('');
    const debouncedValue = useDebounceString(searchDistrict, 500)

    const [addLocation] = useLazyAddUserLocationQuery();
    const [deleteLocation] = useLazyDeleteUserLocationQuery();
    const {data} = useGetDistrictsListQuery({search: debouncedValue, region_id: ''});

    const setDistrict: IChangeParamsFunc = (id, addFlag) => {
        const params = {
            user_id: userId,
            district_id: id,
        };

        if (addFlag) {
            addLocation(params);
        } else {
            deleteLocation(params);
        }
    };

    return (
        <>
            <MultiSelect
                placeholder='Привязанные районы'
                initValue={currentUserDistricts}
                list={data?.data}
                dictionaryKey={'district_id'}
                dictionaryValue={'district_name'}
                callback={setDistrict}
                setSearch={setSearchDistrict}
                searchValue={searchDistrict}
                usersDictionaryList={currentUserDistrictsDictionary}
            />
        </>
    );
};

export default ChooseDistrict;
