import React from 'react';
import useAppSelector from '../../hooks/redux';
import {useGetRolesListQuery} from '../../store/roles/roles.api';
import {useLazyAddUserRoleQuery, useLazyDeleteUserRoleQuery} from '../../store/users/users.api';
import MultiSelect from '../Inputs/MultiSelect';


const ProfileRoleContainer = () => {
    const authId = useAppSelector((state) => state.auth.user.user_id);
    const rolesId = useAppSelector((state) => state.users.currentUser.user_roles_list);
    const userId = useAppSelector((state) => state.users.currentUser.user_id);
    const rolesList = useAppSelector((state) => state.roles.rolesList);
    const currentUserRolesDictionary = useAppSelector((state) => state.dictionary.current?.user_roles_list);


    useGetRolesListQuery();

    const [addRole] = useLazyAddUserRoleQuery();
    const [delRole] = useLazyDeleteUserRoleQuery();

    const setRoles = (id: number, addFlag: boolean) => {
        const params = {
            user_id: userId,
            role_id: id,
            auth_id: authId
        }
        if (addFlag) {
            addRole(params)
        } else {
            delRole(params)
        }
    }
    return (
        <>
            <MultiSelect placeholder='Роли пользователя' initValue={rolesId} list={rolesList} dictionaryKey={'role_id'} dictionaryValue={'role_name_short'} callback={setRoles} usersDictionaryList={currentUserRolesDictionary} />
        </>
    );
};
export default ProfileRoleContainer;
