import React from 'react';

interface IDotSvg {
    color: string;
    classSvg?: string;
}

const DotSvg = ({classSvg, color}: IDotSvg) => {
    return (
        <svg
            className={classSvg}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12 10.1399C13.1111 10.1399 14 10.9666 14 12C14 13.0334 13.1111 13.8601 12 13.8601C10.8889 13.8601 10 13.0334 10 12C10 10.9666 10.8889 10.1399 12 10.1399ZM10 6.86008C10 7.89345 10.8889 8.72015 12 8.72015C13.1111 8.72015 14 7.89345 14 6.86008C14 5.8267 13.1111 5 12 5C10.8889 5 10 5.8267 10 6.86008ZM10 17.1399C10 18.1733 10.8889 19 12 19C13.1111 19 14 18.1733 14 17.1399C14 16.1065 13.1111 15.2798 12 15.2798C10.8889 15.2798 10 16.1065 10 17.1399Z'
                fill={color}
            />
        </svg>
    );
};

export default DotSvg;
