import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from '../image/svg/arrowBlue.svg';
import useOnClickOutside from '../hooks/useOnClickOutside';
import { useGetGendersListQuery } from '../store/directories/directories.api';
import { IGenderType } from '../models/models';
import useAppSelector from '../hooks/redux';
import useActions from '../store/actions';
import { ListsState } from '../store/lists/lists.slice';

interface IAnimalGenderFilterSelect {
    storeKey: keyof ListsState;
}

const filterKey = 'animal_sex';

const AnimalGenderFilterSelect = ({ storeKey }: IAnimalGenderFilterSelect) => {
    const filterStoreValue = useAppSelector((state) => state.list[storeKey].filters[filterKey]);
    const { setFilters, deleteFilter } = useActions();

    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const { data } = useGetGendersListQuery();

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!filterStoreValue) {
            setInputValue('');
        }
        if (data) {
            setInputValue('');
            data?.data?.forEach((item) => {
                if (item.gender_value === filterStoreValue) {
                    setInputValue(item.gender_name);
                }
            });
        }
    }, [filterStoreValue, data]);

    const onChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        deleteFilter({ storeKey, filterKey });
        setInputValue(value);
    };

    const onSelectItem = (item: IGenderType) => {
        setIsOpen(false);
        setInputValue(item.gender_name);
        setFilters({ storeKey, filterKey, filterValue: item.gender_value });
    };

    const filterItems = data?.data || [];

    const closeHandler = () => setIsOpen(false);
    useOnClickOutside(ref, closeHandler);

    return (
        <div ref={ref} className='w-full mb-6 relative'>
            <div
                role='presentation'
                className={`select-container relative ${
                    isOpen ? 'rounded-t-lg border-blue' : 'rounded-lg border-borderGray'
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <input
                    type='text'
                    value={inputValue}
                    onChange={onChangeSelect}
                    className='w-full h-full py-[10px] px-[12px] rounded-lg outline-0 font-bold'
                    placeholder={'Пол животного'}
                />
                <Arrow className={`w-[20px] h-[20px] duration-200 ${isOpen ? 'rotate-[90deg]' : 'rotate-[-90deg]'}`} />
            </div>
            {isOpen ? (
                <div className='select-items z-[1000]'>
                    {filterItems?.length ? (
                        filterItems.map((item) => (
                            <div
                                role='presentation'
                                key={item.gender_value}
                                className={`${
                                    item.gender_value === filterStoreValue
                                        ? 'div-arrow bg-blue text-white'
                                        : ' div-arrow text-black'
                                } select-item`}
                                onClick={() => {
                                    onSelectItem(item);
                                }}
                            >
                                {item.gender_name}
                            </div>
                        ))
                    ) : (
                        <div className='text-sm h-[32px] flex justify-center items-center text-grayDisabled'>
                            Ничего не найдено
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default AnimalGenderFilterSelect;
