import React from 'react';
import useAppSelector from '../../hooks/redux';

const AnimalGenealogy = () => {
    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);

    return (
        <div>
            <h4 className='pb-4 text-base text-gray'>1 колено</h4>
            <div className='grid grid-cols-[1fr_1fr] gap-6'>
                <div className='border-blueSky border-[2px] rounded-lg p-4'>
                    <h2 className='text-lg text-blue mb-1'>Отец</h2>
                    <table className='flex gap-8'>
                        <thead>
                            <tr className='flex flex-col justify-start'>
                                <th className='text-left text-sm text-gray font-normal text-nowrap'>Уникальный номер</th>
                                <th className='text-left text-sm text-gray font-normal'>№ УНСМ</th>
                                <th className='text-left text-sm text-gray font-normal'>Инв. №</th>
                                <th className='text-left text-sm text-gray font-normal'>Дата рождения</th>
                                <th className='text-left text-sm text-gray font-normal'>Порода</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='flex flex-col'>
                                <td className='text-left text-sm text-blue font-normal'>{currentAnimal.genealogy?.o.father_guid || '-'}</td>
                                <td className='text-left text-sm text-black font-normal'>{currentAnimal.genealogy?.o.father_rshn || '-'}</td>
                                <td className='text-left text-sm text-black font-normal'>{currentAnimal.genealogy?.o.father_inv || '-'}</td>
                                <td className='text-left text-sm text-black font-normal'>{currentAnimal.genealogy?.o.father_date_birth || '-'}</td>
                                <td className='text-left text-sm text-black font-normal'>{currentAnimal.genealogy?.o.father_breed_name || '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='border-red border-[2px] rounded-lg py-4 pl-4'>
                    <h2 className='text-lg text-red mb-1'>Мать</h2>
                    <table className='flex gap-8'>
                        <thead>
                            <tr className='flex flex-col justify-start'>
                                <th className='text-left text-sm text-gray font-normal text-nowrap'>Уникальный номер</th>
                                <th className='text-left text-sm text-gray font-normal'>№ УНСМ</th>
                                <th className='text-left text-sm text-gray font-normal'>Инв. №</th>
                                <th className='text-left text-sm text-gray font-normal'>Дата рождения</th>
                                <th className='text-left text-sm text-gray font-normal'>Порода</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='flex flex-col'>
                                <td className='text-left text-sm text-blue font-normal'>{currentAnimal.genealogy?.m.mother_guid || '-'}</td>
                                <td className='text-left text-sm text-black font-normal'>{currentAnimal.genealogy?.m.mother_rshn || '-'}</td>
                                <td className='text-left text-sm text-black font-normal'>{currentAnimal.genealogy?.m.mother_inv || '-'}</td>
                                <td className='text-left text-sm text-black font-normal'>{currentAnimal.genealogy?.m.mother_date_birth || '-'}</td>
                                <td className='text-left text-sm text-black font-normal'>{currentAnimal.genealogy?.m.mother_breed_name || '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AnimalGenealogy;
