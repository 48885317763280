import React, { useCallback, useEffect, useState } from 'react';
import { NotificationType } from './types';
import NotificationManager from './notification-manager';
import PortalsInside from './portals-notify';
import Portal from '../Portal';
import { icons } from './icons';

const Notifications = () => {
    const [notifications, setNotifications] = useState<NotificationType[]>([]);

    const handleStoreChange = useCallback((notifi: NotificationType[]) => {
        setNotifications([...notifi]);
    }, []);

    useEffect(() => {
        NotificationManager.addChangeListener(handleStoreChange);
    }, [handleStoreChange]);

    const notifyItemType = (notification: NotificationType) => {
        const NotificationIcon = icons[notification.type];
        return NotificationIcon ? (
            <PortalsInside key={notification.id} {...notification}>
                <NotificationIcon className='w-[32px] h-[32px]' />
            </PortalsInside>
        ) : null;
    };

    return (
        <Portal>
            <div className={`${notifications.length !== 0 ? 'notification-list-animation' : 'notification-list'}`}>
                {notifications.map(notifyItemType)}
                {notifications.length > 2 && (
                    <button className='bg-gray  text-white rounded-lg w-full h-[40px] cursor-pointer' onClick={() => NotificationManager.clearNotifyList()} type='button'>
                        очистить все
                    </button>
                )}
            </div>
        </Portal>
    );
};

export default Notifications;
