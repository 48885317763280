import React, { useState } from 'react';
import ProfileTabsItem from './ProfileTabsItem';
import useAppSelector from '../../hooks/redux';

const ProfileOrganizationList = () => {
    const companiesDictionary = useAppSelector((state) => state.dictionary.authUser?.user_companies_locations_list);
    const companiesList = useAppSelector((state) => state.auth.user.user_companies_locations_list);

    const [activeTab, setActiveTab] = useState(companiesList[0]);
    const openTab = (e: React.MouseEvent<HTMLSpanElement>) => {
        const index = Number((e.currentTarget as HTMLSpanElement).dataset.index);
        setActiveTab(index);
    };

    return (
        <>
            <span className='text-gray text-xs bg-white'>Название организации</span>
            <div className='flex flex-wrap mb-5'>
                {companiesDictionary &&
                    companiesList.map((item, index) => {
                        return (
                            <div className='tabs-header' key={`${companiesDictionary[item]?.company_location_id}_${index}`}>
                                <span
                                    role='presentation'
                                    onClick={openTab}
                                    data-index={companiesDictionary[item]?.company_location_id}
                                    className={`profile-text font-bold hover:opacity-80 text-fixed ${
                                        companiesDictionary[item]?.company_location_id === activeTab &&
                                        'profile-active-text'
                                    }`}
                                >
                                    {companiesDictionary && companiesDictionary[item]?.company_name_full}
                                </span>
                                <span className='tabs-border' />
                            </div>
                        );
                    })}
            </div>
            <div>
                {companiesDictionary && companiesDictionary[activeTab] && (
                    <ProfileTabsItem
                        key={activeTab}
                        districtName={companiesDictionary[activeTab].district_name}
                        regionName={companiesDictionary[activeTab].region_name}
                    />
                )}
            </div>
        </>
    );
};

export default ProfileOrganizationList;
