import React, { useState } from 'react';
import eyeClose from '../../image/EyeClose.png';
import eyeOpen from '../../image/Eye.png';

interface IPassword {
    label: string;
    name: string;
    id: string;
    value?: string;
    placeholder: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    autoComplete?: string;
    error?: string;
    required?: boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
}
const InputPassword = ({ label, name, onKeyDown, id, placeholder, onChange, value, autoComplete, error, required }: IPassword) => {
    const [iconShow, setIconShow] = useState(false);
    return (
        <div className='flex mb-6 relative'>
            <label className='input-label' htmlFor={id}>
                {label} {required && <span className='text-sm text-red'>&nbsp;*</span>}
            </label>
            <input
                className={
                    'rounded-lg text-black font-bold outline-0 py-[8px] px-[12px] border-borderGray border-[2px] w-full hover:border-blue focus:border-blue placeholder-grayLight text-sm'
                }
                type={iconShow ? 'text' : 'password'}
                name={name}
                value={value}
                id={id}
                placeholder={placeholder}
                onChange={onChange}
                autoComplete={autoComplete}
                onKeyDown={onKeyDown}
            />
            {iconShow ? (
                <img
                    className='absolute right-[20px] top-[12px] cursor-pointer'
                    role='presentation'
                    onClick={() => setIconShow(!iconShow)}
                    src={eyeOpen}
                    alt='open'
                />
            ) : (
                <img
                    className='absolute right-[20px] top-[12px] cursor-pointer'
                    role='presentation'
                    onClick={() => setIconShow(!iconShow)}
                    src={eyeClose}
                    alt='close'
                />
            )}
            {error && <p className='absolute text-xs text-red top-[40px]'>{error}</p>}
        </div>
    );
};

export default InputPassword;
