import React from 'react';
import { useParams } from 'react-router-dom';
import useAppSelector from '../../hooks/redux';
import AnimalBirthSelect from './Selects/AnimalBirthSelect';
import AnimalKeepingSelect from './Selects/AnimalKeepingSelect';
import AnimalKeepingTypeSelect from './Selects/AnimalKeepingTypeSelect';
import AnimalKeepingPurposeSelect from './Selects/AnimalKeepingPurposeSelect';

// interface CompanyObject {
//     company_object_id: number;
//     company_id: number;
//     company_object_guid_herriot: string;
//     company_object_approval_number: string;
//     company_object_address_view: string;
//     company_object_full_name: string;
// }

const AnimalBase = () => {
    // const [getCompanyObjects] = useLazyGetCompanyObjectsListQuery();
    // const [editAnimalKeepingObject] = useLazyEditAnimalKeepingObjectQuery();
    // const [editAnimalBirthObject] = useLazyEditAnimalBirthObjectQuery();
    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);

    const { id } = useParams<{ id: string }>();
    // const [getAnimal] = useLazyGetAnimalPartQuery();

    // const [cmpObjectsBirth, setCmpObjectsBirth] = useState<CompanyObject[]>([]);
    // const [cmpObjectsKeep, setCmpObjectsKeep] = useState<CompanyObject[]>([]);

    // const paramsQuery = {
    //     id,
    //     body: { data_sections: ['main', 'base', 'mark', 'genealogy', 'vib', 'registration', 'history'] },
    // };

    // useEffect(() => {
    // if (currentAnimal.base?.animal_birth_company) {
    //     getCompanyObjects(currentAnimal.base?.animal_birth_company)
    //         .then((response) => {
    //             setCmpObjectsBirth(response.data?.data || []);
    //         })
    //         .catch(() => {
    //             setCmpObjectsBirth([]);
    //         });
    // } else {
    //     setCmpObjectsBirth([]);
    // }
    // if (currentAnimal.base?.animal_keeping_company) {
    //     getCompanyObjects(currentAnimal.base?.animal_keeping_company)
    //         .then((response) => {
    //             setCmpObjectsKeep(response.data?.data || []);
    //         })
    //         .catch(() => {
    //             setCmpObjectsKeep([]);
    //         });
    // } else {
    //     setCmpObjectsKeep([]);
    // }
    // }, [getCompanyObjects, currentAnimal.base]);

    // const setCmpObjectsBirthSrv = useCallback(
    //     async (id: number) => {
    //         await editAnimalBirthObject({
    //             animal_id: currentAnimal.base?.animal_id,
    //             company_object_id: id,
    //         });
    //         getAnimal(paramsQuery);
    //     },
    //     [editAnimalBirthObject, getAnimal, paramsQuery, currentAnimal.base?.animal_id],
    // );

    // const setCmpObjectsKeepSrv = useCallback(
    //     async (id: number) => {
    //         await editAnimalKeepingObject({
    //             animal_id: currentAnimal.base?.animal_id,
    //             company_object_id: id,
    //         });
    //         getAnimal(paramsQuery);
    //     },
    //     [editAnimalKeepingObject, getAnimal, paramsQuery, currentAnimal.base?.animal_id],
    // );

    return (
        <div className='w-full grid auto-fit-100 grid-cols-3 bg-white rounded-lg'>
            <div className='border-r-[2px] border-backgroundModal'>
                <div className='flex flex-col mb-4'>
                    <span className='text-gray text-xs mb-1'>Пол</span>
                    <span className='text-sm text-black bg-inherit w-full'>
                        {currentAnimal.base?.animal_gender_name || '-'}
                    </span>
                </div>
                <div className='flex flex-col mb-4'>
                    <span className='text-gray text-xs mb-1'>Дата рождения</span>
                    <span className='text-sm text-black bg-inherit w-full'>
                        {currentAnimal.base?.animal_date_birth || '-'}
                    </span>
                </div>
                <div className='flex flex-col mb-4'>
                    <span className='text-gray text-xs mb-1'>Племенная ценность</span>
                    <span className='text-sm text-black bg-inherit w-full'>
                        {currentAnimal.base?.animal_breeding_value || '-'}
                    </span>
                </div>
                <div className='flex flex-col mb-4'>
                    <span className='text-gray text-xs mb-1'>Порода</span>
                    <span className='text-sm text-black bg-inherit w-full'>
                        {currentAnimal.base?.animal_breed_name || '-'}
                    </span>
                </div>
                <div className='flex flex-col mb-4'>
                    <span className='text-gray text-xs mb-1'>Масть</span>
                    <span className='text-sm text-black bg-inherit w-full'>
                        {currentAnimal.base?.animal_mast || '-'}
                    </span>
                </div>
            </div>
            <div className='px-[20px] border-r-[2px] border-backgroundModal'>
                <div className='flex flex-col mb-4'>
                    <AnimalKeepingSelect id={id} />

                    {/* <span className='text-gray text-xs mb-1'>Место содержания</span>

                    <Select
                        placeholder='Статус записи'
                        initValue={currentAnimal.base?.animal_keeping_object_obj?.company_object_id}
                        storeKey='animal_keeping_object_obj__select'
                        array={cmpObjectsKeep}
                        itemKey={'company_object_id'}
                        itemValue={'company_object_full_name'}
                        onChange={setCmpObjectsKeepSrv}
                    />

                    <span className='text-sm text-black bg-inherit w-full italic'>
                        {currentAnimal.base?.animal_keeping_object_obj?.company_object_approval_number || '-'}
                        <br />
                        {currentAnimal.base?.animal_keeping_object_obj?.company_object_address_view}
                    </span> */}
                </div>
                <div className='flex flex-col mb-4'>
                    <AnimalBirthSelect id={id} />

                    {/* <span className='text-gray text-xs mb-1'>Место рождения</span>
                    <Select
                        placeholder='Статус записи'
                        initValue={currentAnimal.base?.animal_birth_object_obj?.company_object_id}
                        storeKey='animal_birth_object_obj__select'
                        array={cmpObjectsBirth}
                        itemKey={'company_object_id'}
                        itemValue={'company_object_full_name'}
                        onChange={setCmpObjectsBirthSrv}
                    />

                    <span className='text-sm text-black bg-inherit w-full italic'>
                        {currentAnimal.base?.animal_birth_object_obj?.company_object_approval_number || '-'}
                        <br />
                        {currentAnimal.base?.animal_birth_object_obj?.company_object_address_view}
                    </span> */}
                </div>
                <div className='flex flex-col mb-4'>
                    <AnimalKeepingTypeSelect id={id} />
                </div>
                <div className='flex flex-col mb-4'>
                    <AnimalKeepingPurposeSelect id={id} />
                </div>
            </div>
            <div className='px-[20px]'>
                <div className='flex flex-col mb-4'>
                    <span className='text-gray text-xs mb-1'>Дата поступления (завоза)</span>
                    <span className='text-sm text-black bg-inherit w-full'>
                        {currentAnimal.base?.animal_date_income || '-'}
                    </span>
                </div>
                <div className='flex flex-col mb-4'>
                    <span className='text-gray text-xs mb-1'>Страна завоза</span>
                    <span className='text-sm text-black bg-inherit w-full'>
                        {currentAnimal.base?.animal_country_import || '-'}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default AnimalBase;
