import { createApi } from '@reduxjs/toolkit/query/react';
import { IApplication } from '../../models/base/applications';
import { IParams, IServerResponse } from '../../models/models';
import applicationService from './application.service';
import { baseQuery } from '../middleware';

export const applicationsApi = createApi({
    reducerPath: 'applications/api',
    tagTypes: ['Applications'],
    baseQuery,
    endpoints: (build) => ({
        getApplications: build.query<IServerResponse<IApplication[]>, IParams>({
            query: (params) => ({
                url: `applications/list`,
                method: 'GET',
                params,
            }),
            transformResponse: (response: IServerResponse<IApplication[]>) => {
                return applicationService.prepareApplicationsList(response);
            },
            providesTags: () => [{ type: 'Applications', id: 'LIST' }],
        }),
        getApplication: build.query<IServerResponse<IApplication>, any>({
            query: ({ id }) => ({
                url: `applications/data/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: IServerResponse<IApplication>) => {
                response.data = applicationService.prepareApplicationData(response.data, response.dictionary);
                return response;
            },
        }),
        addAnimalToApplication: build.query<IServerResponse<IApplication>, any>({
            query: ({ params, body }) => ({
                url: `applications/animal_add`,
                method: 'POST',
                params,
                body,
            }),
        }),
        deleteAnimalFromApplication: build.mutation<IServerResponse<any>, any>({
            query: (body) => ({
                url: `applications/animal_delete/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Applications', id: 'LIST' }],
        }),
        setStatus: build.query<IServerResponse<IApplication>, any>({
            query: (body) => ({
                url: `applications/status`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useLazyGetApplicationsQuery,
    useLazyGetApplicationQuery,
    useDeleteAnimalFromApplicationMutation,
    useLazyAddAnimalToApplicationQuery,
    useLazySetStatusQuery,
} = applicationsApi;
