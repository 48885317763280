import React from 'react';
import { useParams } from 'react-router-dom';
import { useLazySetStatusQuery } from '../../store/applications/applications.api';
import useAppSelector from '../../hooks/redux';
import { APPLICATION_STATUS, ROLES } from '../../utils/dictionary';

const ApplicationButton = () => {
    const authUserRoles = useAppSelector((state) => state.dictionary.authUser?.user_roles_list);
    const currentApplication = useAppSelector((state) => state.applications.currentApplication);
    const herriotData = useAppSelector((state) => state.auth.user.user_herriot_data);

    const [setStatus, { isLoading }] = useLazySetStatusQuery();
    const params = useParams<'id'>();

    const changeStatus = (status: string) => {
        setStatus({
            application_id: params.id,
            application_status: status,
        });
    };

    const checkRoles = (rolesArray: number[]) => {
        return rolesArray.some((key) => authUserRoles?.[key]?.active);
    };

    const checkStatus = (statusesArray: string[]) => {
        return statusesArray.includes(currentApplication.application_status);
    };

    return (
        <div className='flex gap-4'>
            {checkRoles([ROLES.VetCompany]) && checkStatus([APPLICATION_STATUS.Created]) && (
                <button
                    onClick={() => changeStatus(APPLICATION_STATUS.Prepared)}
                    className='h-[40px] text-white text-sm bg-blue hover:bg-blueHover rounded-lg px-4'
                    type='button'
                    disabled={isLoading}
                >
                    Сформировать заявку
                </button>
            )}
            {!herriotData?.login && (
                <span className='flex items-center text-center text-sm text-orange rounded-lg px-4'>
                    Данные для авторизации в Хорриот не предоставлены
                </span>
            )}
            {checkRoles([ROLES.VetCompany]) && checkStatus([APPLICATION_STATUS.Prepared]) && (
                <div className='flex items-center h-[40px] text-center text-sm text-green font-bold rounded-lg px-4'>
                    Заявка сформирована
                </div>
            )}
            {herriotData?.login && currentApplication.application_status === 'prepared' && (
                <button
                    onClick={() => changeStatus(APPLICATION_STATUS.Sent)}
                    className='h-[40px] text-white text-sm bg-blue hover:bg-blueHover rounded-lg px-4'
                    disabled={isLoading}
                    type='button'
                >
                    Передать в ВетИС Хорриот
                </button>
            )}
        </div>
    );
};

export default ApplicationButton;
