import { ValidationError } from 'yup';

interface IValidateCheck {
    validateObj: any;
    schema: any;
}

const validateCheck = async ({ validateObj, schema }: IValidateCheck) => {
    let errors: { [x: string]: string } | undefined;
    await schema.validate(validateObj, { abortEarly: false }).catch((err: ValidationError) => {
        const parsed = err as ValidationError;
        const yupErrors = parsed.inner;
        yupErrors.forEach((err) => {
            if (err.path) {
                if (!errors) {
                    errors = {};
                }
                errors[err.path] = err.message;
            }
        });
    });

    return errors;
};

export default validateCheck;
