import React from 'react';
import StepperHorisontal from '../StepperVertical/StepperVertical';
import useAppSelector from '../../hooks/redux';
import {ANIMAL_REGISTRATION_STATUS} from "../../utils/dictionary";

const AnimalRegistration = () => {
    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);
    const currentStatus = (status: string) => {
        switch (status) {
            case ANIMAL_REGISTRATION_STATUS.Added:
                return <span className='text-base  text-black'>Добавлено в систему</span>;
            case ANIMAL_REGISTRATION_STATUS.InApplication:
                return <span className='text-base  text-black'>Добавлено в заявку</span>;
            case ANIMAL_REGISTRATION_STATUS.Sent:
                return <span className='text-base '>Отправлено на регистрацию</span>;
            case ANIMAL_REGISTRATION_STATUS.Registered:
                return <span className='text-base  text-black'>Зарегистрировано</span>;
            case ANIMAL_REGISTRATION_STATUS.Rejected:
                return <span className='text-base  text-black'>Отказ. Причина отказа: {currentAnimal?.registration?.text_error ?? 'неизвестна'}</span>;
            default:
                return <span className='text-base text-black'>нет данных</span>
        }
    };

    return (
        <>
            <div className='flex flex-col mb-6'>
                <span className='text-sm text-gray'>Статус</span>
                {currentAnimal.registration?.status && currentStatus(currentAnimal.registration?.status)}
            </div>
            <div className='px-8'>
                <StepperHorisontal items={currentAnimal.registration} />
            </div>
        </>
    );
};

export default AnimalRegistration;
