import {IDictionary, IServerResponse} from "../../models/models";
import {IAnimal} from "../../models/base/animals";
import {prepareDate} from "../../utils/func";

const animalService = {
    prepareAnimalsList: (response: IServerResponse<IAnimal[]>) => {
        response.data.map((item) => {
            item = animalService.prepareAnimalData(item, response.dictionary)
            return item
        })

        return response
    },
    prepareAnimalData: (data: IAnimal, dictionary: IDictionary) => {
        if (data.base) {
            if (dictionary.companies_objects_list) {
                data.base.animal_birth_object_obj =
                    dictionary.companies_objects_list[data.base.animal_birth_object]
                data.base.animal_keeping_object_obj =
                    dictionary.companies_objects_list[data.base.animal_keeping_object]
            }
            if (dictionary.breeds_list) {
                data.base.animal_breed_name =
                    dictionary.breeds_list[data.base.animal_breed]?.breed_name
            }
            if (dictionary.genders_list) {
                data.base.animal_gender_name =
                    dictionary.genders_list[data.base.animal_sex]?.gender_name
            }
            if (dictionary.keeping_purposes_list) {
                data.base.animal_keeping_purpose_name =
                    dictionary.keeping_purposes_list[data.base.animal_keeping_purpose]?.keeping_purpose_name
            }
            if (dictionary.keeping_types_list) {
                data.base.animal_keeping_type_name =
                    dictionary.keeping_types_list[data.base.animal_keeping_type]?.keeping_type_name
            }

            data.base.animal_date_birth = prepareDate(data.base.animal_date_birth)
            data.base.animal_date_income = prepareDate(data.base.animal_date_income)
        }

        if (data.main) {
            if (dictionary.genders_list) {
                data.main.animal_gender_name =
                    dictionary.genders_list[data.main.animal_sex]?.gender_name
            }
            if (dictionary.breeds_list) {
                data.main.animal_breed_name =
                    dictionary.breeds_list[data.main.animal_breed]?.breed_name
            }
            if (dictionary.species_list) {
                data.main.animal_specie_name =
                    dictionary.species_list[data.main.animal_specie]?.specie_name
            }

            data.main.animal_date_birth = prepareDate(data.main.animal_date_birth)
            data.main.animal_date_reg = prepareDate(data.main.animal_date_reg)
            data.main.animal_date_reg_herriot = prepareDate(data.main.animal_date_reg_herriot)
        }

        if (data.mark) {
            data.mark.items.map((item) => {
                if (dictionary.mark_types_list) {
                    item.mark_type_name =
                        dictionary.mark_types_list[item.mark_type]?.mark_type_name
                }
                if (dictionary.mark_tools_locations_list) {
                    item.mark_tool_location_name =
                        dictionary.mark_tools_locations_list[item.mark_tool_location]?.tool_location_name
                }
                if (dictionary.mark_statuses_list) {
                    item.mark_status_name =
                        dictionary.mark_statuses_list[item.mark_status]?.mark_status_name
                }
                if (dictionary.mark_tool_types_list) {
                    item.mark_tool_type_name =
                        dictionary.mark_tool_types_list[item.mark_tool_type]?.mark_tool_type_name
                }

                item.mark_date_set = prepareDate(item.mark_date_set)
                item.mark_date_out = prepareDate(item.mark_date_out)

                return item
            })
        }

        if (data.genealogy) {
            if (dictionary.breeds_list) {
                data.genealogy.o.father_breed_name =
                    dictionary.breeds_list[data.genealogy.o.father_breed]?.breed_name
            }
            if (dictionary.breeds_list) {
                data.genealogy.m.mother_breed_name =
                    dictionary.breeds_list[data.genealogy.m.mother_breed]?.breed_name
            }

            data.genealogy.o.father_date_birth = prepareDate(data.genealogy.o.father_date_birth)
            data.genealogy.m.mother_date_birth = prepareDate(data.genealogy.m.mother_date_birth)
        }

        if (data.vib) {
            if (dictionary.out_basises_list) {
                data.vib.animal_out_basis_name =
                    dictionary.out_basises_list[data.vib.animal_out_basis]?.out_basis_name
            }
            if (dictionary.out_types_list) {
                data.vib.animal_out_type_name =
                    dictionary.out_types_list[data.vib.animal_out_type]?.out_type_name
            }

            data.vib.animal_out_date = prepareDate(data.vib.animal_out_date)
        }

        if (data.registration) {
            data.registration.date_response = prepareDate(data.registration.date_response)
            data.registration.date_send = prepareDate(data.registration.date_send)
            data.registration.date_created = prepareDate(data.registration.date_created)
            data.registration.date_verification = prepareDate(data.registration.date_verification)
            data.registration.date_registration = prepareDate(data.registration.date_registration)
        }

        return data
    }
}

export default animalService