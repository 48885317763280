import React from 'react';
import ProfilePersonInfo from './ProfilePersonInfo';
import ProfileUser from './ProfileUser';
import ProfileLogin from './ProfileLogin';
import ProfileOrganization from './ProfileOrganization';

const ProfileContainer = () => {

    return (
        <div className='grid grid-cols-[1fr_3fr] gap-4'>
            <div className={'min-w-auto bg-white rounded-lg p-4'}>
                <ProfilePersonInfo />
            </div>

            <div className='w-full grid auto-fit-100 grid-cols-3 bg-white rounded-lg p-4'>
                <ProfileUser />
                <ProfileLogin />
                <ProfileOrganization />
            </div>
        </div>
    );
};

export default ProfileContainer;
