import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAppSelector from '../hooks/redux';
import Breadcrumbs from '../components/Breadcrumbs';
import Loader from '../components/Loader';
import ProfileContainer from '../components/Profile/ProfileContainer';
import { useLazyGetUserQuery } from '../store/users/users.api';
import EditButton from '../components/Profile/EditButton';
import DotLoader from '../components/Loaders/DotLoader';
// import { ROLES } from '../utils/dictionary';

const ProfilePage = () => {
    const id = useAppSelector((state) => state.auth.user.user_id);
    const userFirst = useAppSelector((state) => state.users.currentUser.user_first);
    const userLast = useAppSelector((state) => state.users.currentUser.user_last);
    // const authRole = useAppSelector((state) => state.auth.user.user_roles_list);

    const params = useParams<'id'>();
    const [getUser, { isLoading }] = useLazyGetUserQuery();

    useEffect(() => {
        getUser(params.id ? params.id : id);
    }, []);

    return (
        <div className='pt-5 px-4'>
            <div className='flex items-center justify-between'>
                <Breadcrumbs
                    title={isLoading ? <DotLoader /> : `${userFirst} ${userLast}`}
                    caption={isLoading ? <DotLoader /> : `${userFirst} ${userLast}`}
                />
                {/* {authRole.includes(ROLES.Admin) && <EditButton />} */}
                <EditButton />
            </div>
            <Loader isLoading={isLoading}>
                <ProfileContainer />
            </Loader>
        </div>
    );
};

export default ProfilePage;
