import React, { useState } from 'react';
import ButtonClearFilter from '../ButtonClearFilter';
import Drawer from '../Drawer';
import UserFilter from './UserFilter';
import { API_KEYS } from '../../utils/dictionary';

const storeKey = API_KEYS.Users;

const TableUserFilter = () => {
    const [open, setOpen] = useState(false);
    return (
        <div className='flex items-center'>
            <ButtonClearFilter storeKey={storeKey} />
            <Drawer open={open} setOpen={setOpen} storeKey={storeKey}>
                <UserFilter setOpen={setOpen} />
            </Drawer>
        </div>
    );
};

export default TableUserFilter;
