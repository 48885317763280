import {IDictionary, IServerResponse} from "../../models/models";
import {IApplication} from "../../models/base/applications";
import animalService from "../animals/animal.service";
import {prepareDate} from "../../utils/func";

const applicationService = {
    prepareApplicationsList: (response: IServerResponse<IApplication[]>) => {
        // console.log(response)
        response.data.map((item) => {
            item = applicationService.prepareApplicationData(item, response.dictionary)
            return item
        })

        return response
    },
    prepareApplicationData: (data: IApplication, dictionary: IDictionary) => {
        data.animals_list?.map((animal) => {
            animal = animalService.prepareAnimalData(animal, dictionary)
            return animal
        })
        if (dictionary.application_status) {
            data.application_status_name =
                dictionary.application_status[data.application_status]?.status_name
        }

        if (dictionary.users_list) {
            data.user_name =
                dictionary.users_list[data.user_id]?.user_first.concat(' '
                    , dictionary.users_list[data.user_id]?.user_last)

            // data.user_role = dictionary.users_list[data.user_id]?.user_roles_list.join(', ')
        }

        data.application_date_create = prepareDate(data.application_date_create)
        data.application_date_complete = prepareDate(data.application_date_complete)
        data.application_date_horriot = prepareDate(data.application_date_horriot)
        return data
    }
}

export default applicationService