import {useEffect, useState} from 'react'
// import useDebounceString from './debounceString';
import useActions from '../store/actions';

function useSetStateInputsValue(key: string, initValue: any) {
    const {setInputValue, deleteInputValue} = useActions();

    const [internalValue, setInternalValue] = useState('');

    useEffect(() => {
        setInternalValue(initValue)
    }, [initValue])

    useEffect(() => {
        setInputValue({key, value: internalValue});
        return () => {
            deleteInputValue(key)
        }
    }, [internalValue])


    return {
        setInternalValue,
        internalValue
    }
}

export default useSetStateInputsValue