import React, {useCallback, useState} from 'react';
import AnimalBase from './AnimalBase';
import AnimalsGenealogy from './AnimalGenealogy';
import AnimalsMarking from './AnimalMarking';
import AnimalVibitie from './AnimalVibitie';
import AnimalRegistration from "./AnimalRegistration";

const AnimalsTabs = () => {
    const [toggle, setToggle] = useState('base');

    const toggleTab = useCallback((index: string) => {
        setToggle(index);
    }, []);

    return (
        <div className='flex flex-col bg-white rounded-lg px-4 pt-4 pb-2 overflow-y-auto'>
            <div className='flex items-center w-full'>
                <div
                    role='presentation'
                    onClick={() => toggleTab('base')}
                    className={`font-bold test-base px-3 py-1 hover:text-blueHover cursor-pointer duration-300 ${
                        toggle === 'base' ? 'active-head-tabs' : 'text-blue border-b-[4px] border-white'
                    }`}
                >
                    Основные сведения
                </div>
                <div
                    role='presentation'
                    onClick={() => toggleTab('mark')}
                    className={`font-bold test-base px-3 py-1 hover:text-blueHover cursor-pointer duration-300 ${
                        toggle === 'mark' ? 'active-head-tabs' : 'text-blue border-b-[4px] border-white'
                    }`}
                >
                    Маркирование
                </div>
                <div
                    role='presentation'
                    onClick={() => toggleTab('genealogy')}
                    className={`font-bold test-base px-3 py-1 hover:text-blueHover cursor-pointer duration-300 ${
                        toggle === 'genealogy' ? 'active-head-tabs' : 'text-blue border-b-[4px] border-white'
                    }`}
                >
                    Родословная
                </div>
                <div
                    role='presentation'
                    onClick={() => toggleTab('vib')}
                    className={`font-bold test-base px-3 py-1 hover:text-blueHover cursor-pointer duration-300 ${
                        toggle === 'vib' ? 'active-head-tabs' : 'text-blue border-b-[4px] border-white'
                    }`}
                >
                    Выбытие
                </div>
                <div
                    role='presentation'
                    onClick={() => toggleTab('registration')}
                    className={`font-bold test-base px-3 py-1 hover:text-blueHover cursor-pointer duration-300 ${
                        toggle === 'registration' ? 'active-head-tabs' : 'text-blue border-b-[4px] border-white'
                    }`}
                >
                    Регистрация
                </div>
                {/* <div */}
                {/*    role='presentation' */}
                {/*    onClick={() => toggleTab(6)} */}
                {/*    className={`font-bold test-base px-3 py-1 hover:text-blueHover cursor-pointer ${ */}
                {/*        toggle === 6 ? 'active-head-tabs' : 'text-blue border-b-[4px] border-white' */}
                {/*    }`} */}
                {/* > */}
                {/*    История изменений */}
                {/* </div> */}
            </div>
            <div className='px-4 py-6'>
                <div
                    className={`content-tabs ${toggle === 'base' ? 'active-tabs' : ''} `}>
                    {toggle === 'base' && <AnimalBase/>}
                </div>
                <div className={`content-tabs ${toggle === 'mark' ? 'active-tabs' : ''}`}>
                    {toggle === 'mark' && <AnimalsMarking/>}
                </div>
                <div
                    className={`content-tabs ${toggle === 'genealogy' ? 'active-tabs' : ''}`}>
                    {toggle === 'genealogy' && <AnimalsGenealogy/>}
                </div>
                <div className={`content-tabs ${toggle === 'vib' ? 'active-tabs' : ''}`}>
                    {toggle === 'vib' && <AnimalVibitie/> }
                </div>
                <div
                    className={`content-tabs ${toggle === 'registration' ? 'active-tabs' : ''}`}>
                    {toggle === 'registration' && <AnimalRegistration />}
                </div>
                {/* <div className={`content-tabs ${toggle === 6 ? 'active-tabs' : ''}`}> */}
                {/*    <AnimalsHistoryChange /> */}
                {/* </div> */}
            </div>
        </div>
    );
};

export default AnimalsTabs;
