import * as Yup from 'yup';

export const herriotSchema = Yup.object().shape({
    user_herriot_web_login: Yup.string().required('Это поле обязательно'),
    user_herriot_login: Yup.string()
        // .email('Неверный формат Email')
        // .max(64, 'Email не может быть больше 64 символов')
        .required('Это поле обязательно'),
    user_herriot_password: Yup.string()
        // .min(8, 'Пароль должен содержать не менее 8 символов')
        // .max(32, 'Пароль не должен превышать 32 символа')
        .required('Это поле обязательно'),
    user_herriot_issuerid: Yup.string().required('Это поле обязательно'),
    user_herriot_apikey: Yup.string().required('Это поле обязательно'),
    user_herriot_serviceid: Yup.string().required('Это поле обязательно'),
});

// const confirmPassword = password
//     .oneOf([Yup.ref('current_password')], 'Пароли не совпадают')
//     .min(8, 'Пароль должен содержать не менее 8 символов')
//     .max(32, 'Пароль не должен превышать 32 символа')
//     .required('Это поле обязательно');
// const confirmNewPassword = password
//     .oneOf([Yup.ref('password')], 'Пароли не совпадают')
//     .min(8, 'Пароль должен содержать не менее 8 символов')
//     .max(32, 'Пароль не должен превышать 32 символа')
//     .required('Это поле обязательно');

const password = Yup.string().min(8, 'Пароль должен быть длиннее 8 символов').required('Это поле обязательно');
const confirmPassword = Yup.string()
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
    .required('Это поле обязательно');
const confirmNewPassword = confirmPassword
    .oneOf([Yup.ref('new_password'), null], 'Пароли не совпадают')
    .required('Это поле обязательно');
export const changePasswordSchema = Yup.object().shape({
    user_id: Yup.number(),
    current_password: password,
    password: confirmPassword,
    password_confirmation: confirmNewPassword,
});

const adminConfirmPassword = Yup.string()
    .oneOf([Yup.ref('password')], 'Пароли не совпадают')
    .min(8, 'Пароль должен содержать не менее 8 символов')
    .max(32, 'Пароль не должен превышать 32 символа')
    .required('Это поле обязательно');
const adminConfirmNewPassword = adminConfirmPassword
    .oneOf([Yup.ref('new_password')], 'Пароли не совпадают')
    .min(8, 'Пароль должен содержать не менее 8 символов')
    .max(32, 'Пароль не должен превышать 32 символа')
    .required('Это поле обязательно');

export const adminChangePass = Yup.object().shape({
    user_id: Yup.number(),
    current_password: Yup.string(),
    password: adminConfirmPassword,
    password_confirmation: adminConfirmNewPassword,
});

export const authSchema = Yup.object().shape({
    user_email: Yup.string()
        .email('Неверный формат Email')
        .max(64, 'Email не может быть больше 64 символов')
        .required('Это поле обязательно'),
    user_password: Yup.string()
        .max(32, 'Пароль не должен превышать 32 символа')
        .required('Это поле обязательно'),
});

export const markSchema = Yup.object().shape({
    mark_id: Yup.number(),
    mark_status: Yup.number().required('Это поле обязательно'),
    mark_tool_type: Yup.number().required('Это поле обязательно'),
    mark_tool_location: Yup.number().required('Это поле обязательно'),
    description: Yup.string().required('Это поле обязательно'),
    mark_date_set: Yup.string().required('Это поле обязательно'),
    mark_date_out: Yup.string(),
});

export const regUserSchema = Yup.object().shape({
    user_first: Yup.string().required('Это поле обязательно'),
    user_last: Yup.string().required('Это поле обязательно'),
    user_middle: Yup.string().required('Это поле обязательно'),
    user_email: Yup.string().email('Неверный формат Email').required('Это поле обязательно'),
    user_password: Yup.string().required('Это поле обязательно').min(8, 'Пароль должен содержать не менее 8 символов'),
    user_phone: Yup.string().required('Это поле обязательно'),
});
