import React, { useEffect } from 'react';
import Cross from '../image/svg/Cross';

interface IModal {
    setShowModal: (flag: boolean) => void;
    showModal: boolean;
    children: React.ReactNode;
    buttonStyle: string;
    textButton?: string;
    component?: React.JSX.Element;
    title?: string;
    disabled?: boolean;
    disabledStyle?: string;
    buttonElement?: React.JSX.Element;
}

const Modal = ({
    children,
    buttonStyle,
    textButton,
    buttonElement,
    component,
    title,
    showModal,
    setShowModal,
    disabled,
    disabledStyle,
}: IModal) => {
    useEffect(() => {
        const closeModal = (event: MouseEvent) => {
            if ((event.target as HTMLElement).id === 'modal-backdrop') {
                setShowModal(false);
            }
        };

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setShowModal(false);
            }
        };

        if (showModal) {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = `${scrollbarWidth}px`;
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0px';
        }

        window.addEventListener('click', closeModal);
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('click', closeModal);
            window.removeEventListener('keydown', handleKeyDown);
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0px';
        };
    }, [showModal]);

    return (
        <>
            <button
                className={disabled ? disabledStyle : buttonStyle}
                type='button'
                onClick={() => setShowModal(true)}
                disabled={disabled}
            >
                {textButton || component}
            </button>
            {showModal ? (
                <>
                    <div
                        id='modal-backdrop'
                        className='fixed inset-0 z-[1002] flex items-center justify-center bg-black bg-opacity-25'
                    >
                        <div className='relative w-full mx-auto max-w-[90%] min-w-[280px]'>
                            <div className='border-0 min-h-[500px] rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                                <div className='flex items-start justify-between p-5 rounded-t relative'>
                                    <h3 className='text-xl font-semibold'>{title}</h3>
                                    <button
                                        aria-label='close'
                                        className='absolute top-[26px] right-[24px]'
                                        type='button'
                                        onClick={() => setShowModal(false)}
                                    >
                                        <Cross color='#AEAEAE' classSvg='cross-svg w-[16px] h-[16px]' />
                                    </button>
                                </div>
                                <div
                                    className='relative px-5 pb-5 pt-[5px] flex-auto overflow-y-auto'
                                    style={{ maxHeight: '70vh' }}
                                >
                                    {children}
                                </div>
                                {buttonElement}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Modal;
