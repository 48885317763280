import React from 'react';
import {ReactComponent as DoubleArrow} from '../../image/svg/doubleArrow.svg';
import {ReactComponent as Arrow} from '../../image/svg/arrowBlue.svg';
import {ApiKeysType} from '../../models/models';
import useDataSourceParams from '../../hooks/useDataSourceParams';
import useAppSelector from '../../hooks/redux';
import SelectList from "./SelectList";

const NUMBER_OF_PAGES = 7;
const CALCULATION_MIN_PAGES = NUMBER_OF_PAGES - 1;
const MIN_MAX_RANGE = Math.floor(NUMBER_OF_PAGES / 2);

interface IPaginations {
    apiKey: ApiKeysType;
    countListPagination?: boolean;
}

const basicClass = 'pagination-list';
const activeClass = 'pagination-list-active';
const basicLeftButtonClass = 'pagination-left';
const disabledLeftButtonClass = 'pagination-disabled-left';
const basicRightButtonClass = 'pagination-right';
const disabledRightButtonClass = 'pagination-disabled-right';

const Pagination = ({apiKey, countListPagination}: IPaginations) => {
    const {setCurPage} = useDataSourceParams(apiKey);

    const {
        max_page: lastPage,
        total_records: items,
        cur_page: currentPage,
        per_page: perPage,
        total_records: pages,
    } = useAppSelector((state) => state.list[apiKey].pagination);

    const firstPage = 1;
    const nextPage = currentPage + 1;
    const prevPage = currentPage - 1;

    const pagePeriodMin = perPage * currentPage - (perPage - 1);
    const pagePeriodMax = perPage * (currentPage - 1) + perPage < items ? perPage * (currentPage - 1) + perPage : items;
    const paginationArr = [];
    let startPaginations = currentPage - MIN_MAX_RANGE;
    let finishPaginations = currentPage + MIN_MAX_RANGE;

    if (startPaginations <= 0) {
        startPaginations = 1;
        finishPaginations =
            startPaginations + CALCULATION_MIN_PAGES < lastPage ? startPaginations + CALCULATION_MIN_PAGES : lastPage;
    }
    if (finishPaginations >= lastPage) {
        finishPaginations = lastPage;
        startPaginations =
            finishPaginations - CALCULATION_MIN_PAGES > firstPage
                ? finishPaginations - CALCULATION_MIN_PAGES
                : firstPage;
    }

    for (let i = startPaginations; i <= finishPaginations; i += 1) {
        const pageItem = {
            active: currentPage === i,
            page: i,
        };
        paginationArr.push(pageItem);
    }

    const handlerCurPage = (item: number) => {
        if (currentPage !== item) setCurPage(item);
    }

    return (
        <div className='flex justify-end items-center py-2'>
            <span className='text-gray text-sm mr-[40px]'>
                Записи {pagePeriodMin} - {pagePeriodMax} из {items}
            </span>
            <div className='flex mr-[40px]'>
                <SelectList apiKey={apiKey} countListPagination={countListPagination} />
            </div>
            <div className='flex items-center'>
                <button
                    aria-label='button'
                    type='button'
                    onClick={() => setCurPage(firstPage)}
                    disabled={firstPage === currentPage}
                    title='Первая страница'
                >
                    <DoubleArrow
                        className={firstPage === currentPage ? disabledLeftButtonClass : basicLeftButtonClass}
                    />
                </button>
                <button
                    aria-label='button'
                    type='button'
                    onClick={() => {
                        if (paginationArr.length !== 1 && firstPage !== currentPage) {
                            setCurPage(prevPage as number)
                        }
                    }}
                    disabled={!prevPage}
                    title='Предыдущая страница'
                    className='mr-1'
                >
                    <Arrow className={firstPage === currentPage ? disabledLeftButtonClass : basicLeftButtonClass} />
                </button>
                {pages > NUMBER_OF_PAGES && startPaginations > firstPage && (
                    <>
                        <button onClick={() => setCurPage(firstPage)} className={basicClass} type='button'>
                            {firstPage}
                        </button>
                        <span className='text-sm mr-1.5 font-bold text-gray'>...</span>
                    </>
                )}
                {paginationArr.map((item) => {
                    return (
                        <button
                            key={item.page}
                            onClick={() => handlerCurPage(item.page)}
                            className={item.active ? activeClass : basicClass}
                            type='button'
                        >
                            {item.page}
                        </button>
                    );
                })}
                {pages > NUMBER_OF_PAGES && finishPaginations < lastPage && (
                    <>
                        <span className='text-sm mr-1.5 font-bold text-gray'>...</span>
                        <button onClick={() => setCurPage(lastPage)} className={basicClass} type='button'>
                            {lastPage}
                        </button>
                    </>
                )}
                <button
                    aria-label='button'
                    type='button'
                    onClick={() => {
                        if(paginationArr.length !== 1 && lastPage !== currentPage) {
                            setCurPage(nextPage as number)
                        }
                    }}
                    disabled={!nextPage}
                    title='Cледующая страница'
                >
                    <Arrow className={lastPage === currentPage ? disabledRightButtonClass : basicRightButtonClass} />
                </button>
                <button
                    aria-label='button'
                    type='button'
                    onClick={() => setCurPage(lastPage)}
                    disabled={lastPage === currentPage}
                    title='Последняя страница'
                >
                    <DoubleArrow
                        className={lastPage === currentPage ? disabledRightButtonClass : basicRightButtonClass}
                    />
                </button>
            </div>
        </div>
    );
};

export default Pagination;
