import React from 'react';
import InfoAnimal from './InfoAnimal';
import AnimalsTabs from './AnimalsTabs';

const AnimslsContent = () => {
    return (
        <div className='grid grid-cols-[1fr_3fr] gap-4'>
            <InfoAnimal />
            <AnimalsTabs />
        </div>
    );
};

export default AnimslsContent;
