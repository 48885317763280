import React, { useState } from 'react';
import { Drawer } from 'antd';
import { ReactComponent as Bell } from '../../image/svg/bell.svg';
import Cross from '../../image/svg/Cross';
import Notifications from "./Notifications";
import useAppSelector from "../../hooks/redux";

interface INavigationNotify {
    open: boolean;
}
const NavigationNotify = ({ open }: INavigationNotify) => {
    // У иконки svg в зависимости от цвета Notify указать класс bell-blue, bell-yellow, bell-green
    const [drawerOpen, setDrawerOpen] = useState(false);
    const {notifications} = useAppSelector((state) => state.notifications);

    return (
        <>
            <button
                type='button'
                onClick={() => setDrawerOpen(true)}
                className={`sidebar-link group hover:bg-darkBlue ${open ? 'w-64' : 'w-12'}`}
            >
                <div className='svg-block relative'>
                    {notifications.count_new !== 0 &&
                        <span className='absolute flex items-center justify-center top-[-3px] right-[-2px] w-[22px] h-[22px] bg-blue rounded-[50%]'>
                            <span className=' flex items-center text-xs text-black justify-center w-[18px] h-[18px] rounded-[50%] bg-[#FBD38D]'>
                                {notifications.count_new < 99 ? notifications.count_new : '+99'}
                            </span>
                        </span>
                    }
                    <Bell className='icon-sidebar' />
                </div>
                <h2
                    className={`text-white whitespace-pre duration-500 ${
                        !open && 'opacity-0 translate-x-28 overflow-hidden'
                    }`}
                >
                    Уведомления
                </h2>
                <h2
                    className={`${
                        open && 'hidden'
                    } absolute z-[1000] left-48 bg-blueHover whitespace-pre text-white rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1.5 group-hover:left-14 group-hover:w-fit`}
                >
                    Уведомления
                </h2>
            </button>
            <Drawer className='drawer-scroll' open={drawerOpen} closable={false} onClose={() => setDrawerOpen(false)}>
                <div className='relative h-full'>
                    <div className='flex justify-between items-center flex-nowrap pb-4'>
                        <h2 className='text-2xl font-bold'>Уведомления</h2>
                        <button aria-label='close' type='button' onClick={() => setDrawerOpen(false)}>
                            <Cross classSvg='cross-svg' color='#AEAEAE' width='14' height='14' />
                        </button>
                    </div>
                    <Notifications />
                </div>
            </Drawer>
        </>
    );
};

export default NavigationNotify;
