import React from 'react';

const DotLoader = () => {
    return (
        <div className='h-full flex items-end justify-start'>
            <span className='loader' />
        </div>
    );
};
export default DotLoader;
