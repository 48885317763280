import React from 'react';
import AnimalBirthSelect from '../../Selects/AnimalBirthSelect';
import AnimalKeepingSelect from '../../Selects/AnimalKeepingSelect';
import AnimalKeepingTypeSelect from '../../Selects/AnimalKeepingTypeSelect';
import AnimalKeepingPurposeSelect from '../../Selects/AnimalKeepingPurposeSelect';

const AddAnimalsSupervisedObjects = () => {
    return (
        <div className='flex  gap-2'>
            <div className='flex flex-col'>
                <AnimalKeepingSelect
                    description={false}
                    storeKey='keeping_object__select_supervised'
                    onChange={false}
                    listOrCurrent
                />
            </div>
            <div className='flex flex-col'>
                <AnimalBirthSelect
                    description={false}
                    storeKey='birth_object__select_supervised'
                    onChange={false}
                    listOrCurrent
                />
            </div>
            <div className='flex flex-col'>
                <AnimalKeepingTypeSelect
                    description={false}
                    storeKey='keeping_type__select_supervised'
                    onChange={false}
                    listOrCurrent
                />
            </div>
            <div className='flex flex-col'>
                <AnimalKeepingPurposeSelect
                    description={false}
                    storeKey='keeping_purpose__select_supervised'
                    onChange={false}
                />
            </div>
        </div>
    );
};

export default AddAnimalsSupervisedObjects;
