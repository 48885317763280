import React from 'react';
import { ReactComponent as Accepted } from '../../image/svg/accepted.svg';
import { ReactComponent as Error } from '../../image/svg/cross-err.svg';
import { ReactComponent as Popover } from '../../image/svg/popover.svg';

interface IStatus {
    status: boolean;
}

const TableStatus = ({ status }: IStatus) => {
    return (
        <>
            {status ? (
                <span className='popover'>
                    <Accepted className='w-[25px] h-[25px]' />
                    <Popover className='popover-svg' />
                    <span className='popover-span-hidden'>
                        <span>Активна</span>
                    </span>
                </span>
            ) : (
                <span className='popover'>
                    <Error className='w-[18px] h-[18px]' />
                    <Popover className='popover-svg' />
                    <span className='popover-span-hidden'>
                        <span>Заблокирован</span>
                    </span>
                </span>
            )}
        </>
    );
};

export default TableStatus;
