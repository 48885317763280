import React, { useCallback } from 'react';
import useAppSelector from '../../../hooks/redux';
import { useLazyEditAnimalKeepingTypeQuery, useLazyGetAnimalPartQuery } from '../../../store/animals/animals.api';
import Select from '../../Inputs/Select';
import { useGetAnimalKeepingTypeListQuery } from '../../../store/directories/directories.api';

interface IAnimalBirthSelect {
    id?: string;
    description?: boolean;
    storeKey?: string;
    onChange?: boolean;
    listOrCurrent?: boolean;
}
const AnimalKeepingTypeSelect = ({
    id,
    description = true,
    storeKey,
    onChange = true,
    listOrCurrent = false,
}: IAnimalBirthSelect) => {
    const paramsQuery = {
        id,
        body: { data_sections: ['main', 'base', 'mark', 'genealogy', 'vib', 'registration', 'history'] },
    };

    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);
    const animalsList = useAppSelector((state) => state.list.animals);
    const specie = listOrCurrent ? animalsList.list[0]?.main?.animal_specie : currentAnimal.main?.animal_specie;
    const [editAnimalKeepingType] = useLazyEditAnimalKeepingTypeQuery();
    const [getAnimal] = useLazyGetAnimalPartQuery();
    const { data } = useGetAnimalKeepingTypeListQuery({ specie_id: specie });

    const setAnimalKeepingType = useCallback(
        async (keeping_type_id: number) => {
            await editAnimalKeepingType({
                animal_id: currentAnimal.base?.animal_id,
                keeping_type: keeping_type_id,
            });
            getAnimal(paramsQuery);
        },
        [editAnimalKeepingType, getAnimal, paramsQuery, currentAnimal.base?.animal_id],
    );

    return (
        <>
            <span className='text-gray text-xs mb-1'>Тип содержания</span>
            <Select
                placeholder='Тип содержания'
                initValue={currentAnimal.base?.animal_keeping_type}
                storeKey={storeKey ?? 'animal_keeping_type_id__select'}
                array={data?.data}
                itemKey={'keeping_type_id'}
                itemValue={'keeping_type_name'}
                onChange={onChange ? setAnimalKeepingType : undefined}
            />
            {description && (
                <span className='text-sm text-black bg-inherit w-full'>
                    {currentAnimal.base?.animal_keeping_type_name || '-'}
                </span>
            )}
        </>
    );
};

export default AnimalKeepingTypeSelect;
