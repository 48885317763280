import React from 'react';
import Input from '../Inputs/Input';
import useAppSelector from '../../hooks/redux';
import { ROLES } from '../../utils/dictionary';

const ProfileUser = () => {
    const user_id = useAppSelector((state) => state.users.currentUser.user_id);
    const user_last = useAppSelector((state) => state.users.currentUser.user_last);
    const user_first = useAppSelector((state) => state.users.currentUser.user_first);
    const user_middle = useAppSelector((state) => state.users.currentUser.user_middle);
    const user_phone = useAppSelector((state) => state.users.currentUser.user_phone);
    const edit = useAppSelector((state) => state.users.edit);
    const authRole = useAppSelector((state) => state.auth.user.user_roles_list);

    return (
        <div className='profile-container'>
            <h2 className='text-base text-gray mb-5 w-full min-w-[100px]'>Персональные данные пользователя</h2>
            <Input label={'ID'} type={'text'} storeKey={'user_id__user_edit'} value={user_id} disabled />
            <Input label='Фамилия' type='text' storeKey='user_last__user_edit' value={user_last} disabled={!edit} />
            <Input label='Имя' type='text' storeKey='user_first__user_edit' value={user_first} disabled={!edit} />
            <Input
                label='Отчество'
                type='text'
                storeKey='user_middle__user_edit'
                value={user_middle}
                disabled={!edit}
            />
            <Input
                label='Телефон'
                type='tel'
                storeKey='user_phone__user_edit'
                value={user_phone}
                disabled={authRole.includes(ROLES.Admin) ? !edit : true}
                // pattern='\+?[0-9\s\-\(\)]+'
            />
        </div>
    );
};

export default ProfileUser;
