import React from 'react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/interface';
import { ReactComponent as DateSvg } from '../../image/svg/calendar.svg';
import { DATE_FORMAT } from '../../utils/dictionary';
import useAppSelector from '../../hooks/redux';
import { ListsState } from '../../store/lists/lists.slice';
import useActions from '../../store/actions';

const { RangePicker } = DatePicker;

interface IRangePicker {
    placeholder: [string, string];
    error?: string;
    label?: string;
    minKey: string;
    maxKey: string;
    storeKey: keyof ListsState;
}

const ReactRangePickerNew = ({ placeholder, error, label, minKey, maxKey, storeKey }: IRangePicker) => {
    const filterStoreValueOne = useAppSelector((state) => state.list[storeKey].filters[minKey]);
    const filterStoreValueTwo = useAppSelector((state) => state.list[storeKey].filters[maxKey]);
    const { setFilters, deleteFilter } = useActions();

    const onChange: RangePickerProps<Dayjs>['onChange'] = (_, dateString: [string, string]) => {
        if (dateString[0]) {
            setFilters({ storeKey, filterKey: minKey, filterValue: dateString[0] });
        } else {
            deleteFilter({ storeKey, filterKey: minKey });
        }
        if (dateString[1]) {
            setFilters({ storeKey, filterKey: maxKey, filterValue: dateString[1] });
        } else {
            deleteFilter({ storeKey, filterKey: maxKey });
        }
    };
    return (
        <div className='h-[40px] w-full relative flex flex-col mb-6'>
            {label && <span className='input-label z-[1000]'>{label}</span>}
            <RangePicker
                style={{ width: '100%', height: '40px' }}
                locale={locale}
                value={[
                    filterStoreValueOne ? dayjs(filterStoreValueOne, DATE_FORMAT) : null,
                    filterStoreValueTwo ? dayjs(filterStoreValueTwo, DATE_FORMAT) : null,
                ]}
                placeholder={placeholder}
                onChange={onChange}
                format={DATE_FORMAT}
                allowEmpty={[true, true]}
                suffixIcon={<DateSvg />}
            />
            {error && <p className=' absolute text-xs text-red top-[40px]'>{error}</p>}
        </div>
    );
};

export default ReactRangePickerNew;
