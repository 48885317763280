import React from 'react';
import useAppSelector from '../../hooks/redux';
import useActions from '../../store/actions';
import { ListsState } from '../../store/lists/lists.slice';

interface IFilterInput {
    storeKey: keyof ListsState;
    placeholder: string;
    filterKey: string;
}

const FilterInput = ({ storeKey, filterKey, placeholder }: IFilterInput) => {
    const filterStoreValue = useAppSelector((state) => state.list[storeKey].filters[filterKey]);
    const { setFilters, deleteFilter } = useActions();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            deleteFilter({ storeKey, filterKey });
        } else {
            setFilters({ storeKey, filterKey, filterValue: e.target.value });
        }
    };

    return (
        <>
            <div className='flex flex-col mb-6 relative w-full'>
                <input
                    className={`input-default rounded-lg text-black font-bold outline-0 py-[8px] px-[12px] border-borderGray border-[2px] w-full  focus:border-blue placeholder-grayLight text-sm `}
                    type='text'
                    id={storeKey}
                    name={storeKey}
                    placeholder={placeholder}
                    value={filterStoreValue ?? ''}
                    onChange={handleChange}
                />
            </div>
        </>
    );
};

export default FilterInput;
