import {createSlice} from "@reduxjs/toolkit";
import {IAnimal} from "../../models/base/animals";
import {animalsApi} from "./animals.api";

interface AnimalsState {
    currentAnimal: IAnimal
}

const initialState: AnimalsState = {
    currentAnimal: {

    }
}

export const AnimalsSlice = createSlice({
    name: 'animals',
    initialState,
    reducers: {
        // setCurrentAnimal(state, action: PayloadAction<IAnimal>) {
        //     state.currentAnimal = action.payload
        // }
        deleteCurrentAnimal(state) {
            state.currentAnimal = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(animalsApi.endpoints.getAnimalPart.matchFulfilled, (state, action) => {
                state.currentAnimal = action.payload.data
            })
    }
})

export const animalsActions = AnimalsSlice.actions
export const animalsReducer = AnimalsSlice.reducer