import React, { useEffect, useState } from 'react';
import InputDefault from '../Inputs/InputDefault';
import PhoneInput from '../Inputs/PhoneInput';
import useAppSelector from '../../hooks/redux';
import validateCheck from '../../utils/validateCheck';
import { regUserSchema } from '../../utils/validation';
import { useLazyGetUsersQuery, useLazyRegistrationUserQuery } from '../../store/users/users.api';

interface IUserRegistrationWrap {
    setShowModal: (flag: boolean) => void;
}

interface IRegistrationType {
    [x: string]: string;
}

const UserRegistrationWrap: React.FC<IUserRegistrationWrap> = ({ setShowModal }) => {
    const inputs = useAppSelector((state) => state.inputs);
    const [errors, setErrors] = useState<{ [x: string]: string } | undefined>();
    const [registration, setRegistration] = useState<IRegistrationType | undefined>({});
    const [addUser, {isLoading}] = useLazyRegistrationUserQuery();
    const [getUser] = useLazyGetUsersQuery();
    const [isTouched, setIsTouched] = useState(false);

    useEffect(() => {
        const newParams = { ...registration };
        Object.entries(inputs).forEach(([key, value]) => {
            if (key.includes('reg-user')) {
                const newKey = key.split('__')[0];
                let newValue = value;
                if (key.includes('phone') && value) {
                    newValue = value.replace(/\s+/g, '');
                }
                newParams[newKey] = newValue;
            }
        });
        setRegistration(newParams);
    }, [inputs]);

    useEffect(() => {
        if (isTouched) {
            getAllErrors(registration).then(setErrors);
        }
    }, [registration, isTouched]);

    const getAllErrors = async (
        regObj: IRegistrationType | undefined): Promise<IRegistrationType | undefined> => {
        return validateCheck({ validateObj: regObj, schema: regUserSchema });
    };

    const handleSubmit = async () => {
        setIsTouched(true);
        const newErrors = (await getAllErrors(registration)) || {};
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0 && registration) {
            await addUser(registration);
            await getUser({
                cur_page: 1,
                per_page: 10,
            });
            setErrors({});
            setShowModal(false);
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setErrors({});
    };

    return (
        <>
            <div className='flex w-full gap-4'>
                <InputDefault
                    label='Имя'
                    placeholder='Имя'
                    storeKey='user_first__reg-user'
                    type='text'
                    required
                    error={errors?.user_first}
                    isTouched={isTouched}
                    setIsTouched={setIsTouched}
                />
                <InputDefault
                    label='Фамилия'
                    placeholder='Фамилия'
                    storeKey='user_last__reg-user'
                    type='text'
                    required
                    error={errors?.user_last}
                    isTouched={isTouched}
                    setIsTouched={setIsTouched}
                />
            </div>
            <div className='flex w-full gap-4'>
                <InputDefault
                    label='Отчество'
                    placeholder='Отчество'
                    storeKey='user_middle__reg-user'
                    type='text'
                    required
                    error={errors?.user_middle}
                    isTouched={isTouched}
                    setIsTouched={setIsTouched}
                />
                <PhoneInput label='Телефон' required storeKey='user_phone__reg-user' error={errors?.user_phone} />
            </div>
            <div className='flex w-full gap-4'>
                <InputDefault
                    label='Email'
                    placeholder='Email'
                    storeKey='user_email__reg-user'
                    type='text'
                    required
                    error={errors?.user_email}
                    isTouched={isTouched}
                    setIsTouched={setIsTouched}
                />
                <InputDefault
                    label='Пароль'
                    placeholder='Пароль'
                    storeKey='user_password__reg-user'
                    type='text'
                    required
                    error={errors?.user_password}
                    isTouched={isTouched}
                    setIsTouched={setIsTouched}
                />
            </div>
            <div className='flex items-center justify-between rounded-b'>
                <button
                    className='text-sm text-blue cursor-pointer py-2 hover:text-blueHover'
                    type='button'
                    onClick={handleClose}
                >
                    Отмена
                </button>
                <button
                    className='bg-blue py-2 px-6 text-white rounded-lg text-sm cursor-pointer hover:bg-blueHover'
                    type='button'
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    Сохранить
                </button>
            </div>
        </>
    );
};

export default UserRegistrationWrap;
