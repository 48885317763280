import { useEffect } from 'react';
import useDataSourceParams from './useDataSourceParams';
import useAppSelector from './redux';
import { ApiKeysType } from '../models/models';
import useActions from '../store/actions';

function useTableFilter(storeKey: ApiKeysType) {
    const { setFilter } = useDataSourceParams(storeKey);
    // const currentParams: IParams = useAppSelector((state) => state.list[apiKey].params);
    const inputs = useAppSelector((state) => state.inputs);

    const filters = useAppSelector((state) => state.list[storeKey].filters);
    // const hiddenParam = useAppSelector((state) => state.list[apiKey].hiddenParam);
    const { setListFilters, deleteSearchValues } = useActions();

    // const setFiltersObj = useCallback(
    //     (filtersObj: { [key: string]: string }) => {
    //         setListFilters({ key: apiKey, params: filtersObj });
    //     },
    //     [apiKey],
    // );

    // useEffect(() => {
    //     Object.entries(currentParams).forEach(([key, value]) => {
    //         if (key.includes('filter')) {
    //             const tempFilters = { ...filters, [key.split('[')[1].split(']')[0]]: value };
    //             setListFilters({ key: apiKey, params: tempFilters });
    //         }
    //     });
    // }, [currentParams]);

    useEffect(() => {
        const tempFilterObj = { ...filters };
        Object.entries(inputs).forEach(([key, value]) => {
            if (key.includes('filter')) {
                const tempKey = key.split('__')[0];
                if (value) {
                    tempFilterObj[tempKey] = value;
                } else {
                    delete tempFilterObj[tempKey];
                }
            }
        });
        setListFilters({ key: storeKey, params: tempFilterObj });
        // return () => {
        //     setFilterObj({})
        // }
    }, [inputs]);

    const changeSelectFilter = (key: string, value: string | undefined) => {
        const newFilterObj = { ...filters };
        if (value === '' || value === undefined) {
            delete newFilterObj[key];
        } else {
            newFilterObj[key] = value;
        }
        setListFilters({ key: storeKey, params: newFilterObj });
    };

    const changeInputFilter = (key: string, value: string | number | undefined) => {
        const newFilterObj = { ...filters };
        if (value === '' || value === undefined) {
            delete newFilterObj[key];
        } else {
            newFilterObj[key] = value;
        }
        setListFilters({ key: storeKey, params: newFilterObj });

        setFilter(newFilterObj);
    };

    const handlerFilterApply = () => {
        setFilter(filters);
    };

    const handlerFilterReset = () => {
        setFilter({});
        setListFilters({ key: storeKey, params: {} });
        deleteSearchValues({ storeKey });
    };

    return {
        filterObj: filters,
        changeFilter: changeSelectFilter,
        changeInputFilter,
        handlerFilterApply,
        handlerFilterReset,
    };
}

export default useTableFilter;
