import React, { useState } from 'react';
import dayjs from 'dayjs';
import { ReactComponent as Bell } from '../../image/svg/bell.svg';
import { ReactComponent as Arrow } from '../../image/svg/arrow.svg';
import { INotificationData } from '../../models/base/notifications';
import {
    useLazyCheckNotificationQuery,
    useLazyGetNotificationsQuery,
} from '../../store/notifications/notifications.api';
import {BellType, paramsNotify} from '../../utils/dictionary';

interface INotifyItem {
    item: INotificationData;
    notifyTypeBell: BellType;
    visible: number;
}

const NotifyItem = ({ item, notifyTypeBell, visible }: INotifyItem) => {
    const [getNotifications] = useLazyGetNotificationsQuery();
    const [checkNotify] = useLazyCheckNotificationQuery();
    const [isOpen, setIsOpen] = useState(false);

    const handleClickCurrentNotify = async () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
        if (!item.notification_date_view) {
            await checkNotify(item.notification_id);

            getNotifications({
                ...paramsNotify,
                per_page: visible,
            });
        }
    };

    return (
        <div
            role='presentation'
            onClick={handleClickCurrentNotify}
            className='grid grid-cols-[min-content_1fr_min-content] max-w-[330px] border-t-[1px] last:border-b-[1px] border-borderGray px-1 py-3 hover:bg-lightGray cursor-pointer'
        >
            <Bell className={notifyTypeBell} />
            <div className='flex flex-col ml-2 mr-2'>
                <div className='flex justify-between'>
                    <span className='text-xs text-gray'>
                        {dayjs(item.notification_date_add).format('DD.MM.YYYY HH:mm')}
                    </span>
                    <span className='text-xs text-gray'>Система</span>
                </div>
                <span className='font-bold text-black text-fixed whitespace-nowrap max-w-[255px]'>
                    {item.notification_title}
                </span>
                <span className='flex justify-start text-xs text-gray max-w-[230px] cursor-pointer'>
                    {item.notification_text.length > 30 && (
                        <Arrow
                            className={`min-w-[12px] min-h-[12px] text-blue mr-2 duration-200 ${
                                isOpen ? 'rotate-[180deg]' : 'rotate-0'
                            }`}
                        />
                    )}
                    <span className={`${isOpen ? '' : 'text-fixed whitespace-nowrap'}`}>
                        {item.notification_text}
                    </span>
                </span>
            </div>
            {!item.notification_date_view && <div className='unread w-[16px]' />}
        </div>
    );
};

export default NotifyItem;
