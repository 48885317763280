import React, {useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import useAppSelector from '../hooks/redux';
import Loader from '../components/Loader';
import Breadcrumbs from '../components/Breadcrumbs';
import AnimslsContent from '../components/Animals/AnimslsContent';
import {
    useLazyGetAnimalPartQuery
} from '../store/animals/animals.api';
import DotLoader from "../components/Loaders/DotLoader";
import useActions from '../store/actions';

const AnimalPage = () => {
    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);
    const {deleteCurrentAnimal} = useActions();

    const params = useParams<'id'>();
    const app = useLocation().search.split('=')[1];
    const [getAnimal, {isFetching: isLoading}] = useLazyGetAnimalPartQuery();

    const paramsQuery = {
        id: params.id,
        app,
        body: {"data_sections": ["main", "base", "mark", "genealogy", "vib", "registration", "history"]}
    }

    useEffect(() => {
        getAnimal(paramsQuery)
        return () => {
            deleteCurrentAnimal()
        }
    }, [])


    return (
        <div className='pt-5 px-4 pb-4'>
            <div className='flex justify-between mb-2'>
                <Breadcrumbs
                    title={isLoading ? <DotLoader /> : currentAnimal.main?.animal_inv}
                    caption={isLoading ? <DotLoader /> : currentAnimal.main?.animal_inv}
                />
            </div>
            <Loader isLoading={isLoading}>
                <AnimslsContent />
            </Loader>
        </div>
    );
};

export default AnimalPage;

