import React, {useState} from 'react'
import {IAnimalMarkItems} from '../../../models/base/animals';
import Modal from '../../Modal';
import ModalMarking from './ModalMarking';
import {ReactComponent as Pencil} from '../../../image/svg/pencil.svg';

interface IModalWrap {
    item: IAnimalMarkItems;
}

const ModalWrap = ({item}: IModalWrap) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className='flex justify-end px-2'>
            <Modal
                setShowModal={setShowModal}
                showModal={showModal}
                title='Редактирование средства маркирования'
                buttonStyle=''
                component={<Pencil className='svg-green'/>}
            >
                <ModalMarking setShowModal={setShowModal} item={item}/>
            </Modal>
        </div>

)
}

export default ModalWrap