import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as Arrow} from '../../../image/svg/arrowBlue.svg';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useSetStateInputsValue from '../../../hooks/useSetStateInputsValue';
import useDebounceString from '../../../hooks/debounceString';
import {useGetToolsLocationsListQuery} from '../../../store/directories/directories.api';
import {emojiRegex} from "../../../utils/dictionary";
import {IToolLocation} from "../../../models/models";

interface ISelect {
    initValue?: number;
    label?: string;
    error?: string;
    required?: boolean;
    storeKey?: string;
}

const SelectMarkToolLocation = ({initValue, label, error, required, storeKey}: ISelect) => {

    const {internalValue, setInternalValue} = useSetStateInputsValue(storeKey || 'mark_tool_location__mark', initValue)

    const [search, setSearch] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const debouncedValue = useDebounceString(search, 500)

    const {data} = useGetToolsLocationsListQuery(debouncedValue);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (data) {
            setInputValue('')
            !initValue && setSearch('')
            data?.data?.forEach((item) => {
                if (item.tool_location_id === initValue) {
                    setInputValue(item.tool_location_name)
                }
            })
        }
    }, [initValue, data]);

    const onChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(emojiRegex, '');
        if (!search) {
            setInputValue('');
        }
        setSearch(value);
    };

    const onSelectItem = (item: IToolLocation) => {
        setIsOpen(false);
        setInputValue(item.tool_location_name);
        setSearch('');
        setInternalValue(String(item.tool_location_id));
    };

    const filterItems = data?.data || []

    const closeHandler = () => setIsOpen(false);
    useOnClickOutside(ref, closeHandler);

    return (
        <div ref={ref} className='relative w-full mb-6'>
            <div
                role='presentation'
                className={`select-container relative ${isOpen ? 'rounded-t-lg border-blue' : 'rounded-lg border-borderGray'
                    }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {label && (
                    <label
                        htmlFor={label}
                        className='input-label top-[-2px]'
                    >
                        {label} {required && <span className='text-sm text-red'>&nbsp;*</span>}
                    </label>
                )}
                <input
                    type='text'
                    value={inputValue || search}
                    onChange={(e) => onChangeSelect(e)}
                    className='w-full h-full py-[10px] px-[12px] rounded-lg outline-0 font-bold'
                    placeholder='Место нанесения'
                />
                <Arrow className={`w-[20px] h-[20px] duration-200 ${isOpen ? 'rotate-[90deg]' : 'rotate-[-90deg]'}`} />
            </div>
            {error && <p className='absolute text-xs text-red top-[40px]'>{error}</p>}
            {isOpen ? (
                <div className='select-items z-[1001]'>
                    {filterItems?.length ? (
                        filterItems.map((item) => (
                            <div
                                role='presentation'
                                key={item.tool_location_id}
                                className={`${String(item.tool_location_id) === internalValue
                                    ? 'div-arrow bg-blue text-white'
                                    : ' div-arrow text-black'
                                    } select-item`}
                                onClick={() => {
                                    onSelectItem(item);
                                }}
                            >
                                {item.tool_location_name}
                            </div>
                        ))
                    ) : (
                        <div className='text-sm h-[32px] flex justify-center items-center text-grayDisabled'>
                            Ничего не найдено
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default SelectMarkToolLocation;
