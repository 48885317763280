import React, { useEffect, useState } from 'react';
import InputPassword from '../Inputs/InputPassword';
import InputDefault from '../Inputs/InputDefault';
import useAppSelector from '../../hooks/redux';
import { useLazyAddHerriotDataQuery } from '../../store/users/users.api';
import { herriotSchema } from '../../utils/validation';
import validateCheck from '../../utils/validateCheck';
import { IHerriotType } from '../../models/base/user';

const initialHerriot = {
    user_herriot_web_login: '',
    user_herriot_login: '',
    user_herriot_apikey: '',
    user_herriot_issuerid: '',
    user_herriot_password: '',
    user_herriot_serviceid: 'herriot.service:1.0',
};

const ProfileHerriot = () => {
    const herriotLogin = useAppSelector((state) => state.inputs.user_herriot_login);
    const herriotWebLogin = useAppSelector((state) => state.inputs.user_herriot_web_login);
    const herriotApiKey = useAppSelector((state) => state.inputs.user_herriot_apikey);
    const herriotIssuerId = useAppSelector((state) => state.inputs.user_herriot_issuerid);

    const herriotData = useAppSelector((state) => state.users.currentUser.user_herriot_data);
    const [addHerriotData, {isLoading}] = useLazyAddHerriotDataQuery();
    const [errors, setErrors] = useState<IHerriotType>();
    const [isTouched, setIsTouched] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [herriotObj, setHarriotObj] = useState<IHerriotType>(initialHerriot);

    useEffect(() => {
        setHarriotObj((prev) => ({
            ...prev,
            user_herriot_web_login: herriotWebLogin,
            user_herriot_login: herriotLogin,
            user_herriot_apikey: herriotApiKey,
            user_herriot_issuerid: herriotIssuerId,
        }));
    }, [herriotLogin, herriotWebLogin, herriotIssuerId, herriotApiKey]);

    useEffect(() => {
        if (isTouched) {
            getAllErrors(herriotObj).then(setErrors);
        }
    }, [herriotObj, isTouched]);

    const getAllErrors = async (herriotObj: IHerriotType): Promise<IHerriotType | undefined> => {
        return validateCheck({ validateObj: herriotObj, schema: herriotSchema });
    };

    const handlerSaveHerriot = async () => {
        if (isOpen) {
            setIsTouched(true);
            const newErrors = (await getAllErrors(herriotObj)) || {};
            setErrors(newErrors);
            if (Object.keys(newErrors).length === 0 && herriotObj) {
                setIsOpen(false);
                await addHerriotData(herriotObj)
                setHarriotObj(initialHerriot);
                setErrors({});
                setIsTouched(false);
            }
        } else {
            setIsOpen(true);
            setErrors({});
            setIsTouched(false);
        }
    };

    return (
        <div>
            <h2 className='text-base text-gray w-full min-w-[100px]'>Данные системы Хорриот</h2>

            <span
                className={`${
                    !isOpen ? 'h-[20px]' : 'invisible h-0'
                } block text-sm text-black transition-all overflow-hidden duration-500`}
            >
                {herriotData.login && herriotData.password ? 'Данные предоставлены' : 'Данные не предоставлены'}
            </span>

            <div
                className={`${
                    isOpen ? 'max-h-[550px]' : 'invisible max-h-0'
                } transition-all overflow-hidden duration-500`}
            >
                <div className={`pt-2`}>
                    <span className='block text-sm text-gray mb-4'>
                        Внесите данные в поля согласно информации полученной в реквизитах доступа службы “Ветис.АРI”
                    </span>
                    <>
                        <InputDefault
                            value={herriotObj.user_herriot_login}
                            type='text'
                            storeKey='user_herriot_login'
                            placeholder='Логин'
                            label='Логин Хорриот'
                            error={errors?.user_herriot_login}
                            required
                            isTouched={isTouched}
                            setIsTouched={setIsTouched}
                        />
                        <InputPassword
                            value={herriotObj.user_herriot_password}
                            label='Пароль Хорриот'
                            name='pass-herriot'
                            id='pass-herriot'
                            placeholder='Введите пароль'
                            autoComplete='new-password'
                            onChange={(e) =>
                                setHarriotObj((prev) => ({
                                    ...prev,
                                    user_herriot_password: e.target.value,
                                }))
                            }
                            error={errors?.user_herriot_password}
                            required
                        />
                        <InputDefault
                            value={herriotObj.user_herriot_apikey}
                            type='text'
                            storeKey='user_herriot_apikey'
                            placeholder='APIKey'
                            label='APIKey'
                            error={errors?.user_herriot_apikey}
                            required
                            isTouched={isTouched}
                            setIsTouched={setIsTouched}
                        />
                        <InputDefault
                            type='text'
                            storeKey='user_herriot_serviceid'
                            placeholder='herriot.service:1.0'
                            label='ServiceID'
                            error={errors?.user_herriot_serviceid}
                            title='Значение установлено по умолчанию, ввод значения не требуется'
                            disabled
                            isTouched={isTouched}
                            setIsTouched={setIsTouched}
                        />
                        <InputDefault
                            value={herriotObj.user_herriot_issuerid}
                            type='text'
                            storeKey='user_herriot_issuerid'
                            placeholder='IssuerID'
                            label='IssuerID'
                            error={errors?.user_herriot_issuerid}
                            required
                            isTouched={isTouched}
                            setIsTouched={setIsTouched}
                        />
                        <InputDefault
                            value={herriotObj.user_herriot_web_login}
                            type='text'
                            storeKey='user_herriot_web_login'
                            placeholder='Учетная запись'
                            label='Учетная запись Хорриот'
                            error={errors?.user_herriot_web_login}
                            required
                            isTouched={isTouched}
                            setIsTouched={setIsTouched}
                        />
                    </>
                </div>
            </div>

            {!herriotObj.user_herriot_login &&
            !herriotObj.user_herriot_password &&
            !herriotObj.user_herriot_web_login &&
            !herriotObj.user_herriot_apikey &&
            !herriotObj.user_herriot_issuerid ? (
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className='button-green w-full justify-center mt-5 '
                    type='button'
                >
                    {isOpen ? 'Закрыть' : 'Ввести данные'}
                </button>
            ) : (
                <button onClick={handlerSaveHerriot} disabled={isLoading} className='button-green w-full justify-center mt-5' type='button'>
                    {isOpen ? 'Сохранить данные' : 'Ввести данные'}
                </button>
            )}
        </div>
    );
};

export default ProfileHerriot;
