import React from 'react';

interface ITableDelete {
    handler?: any
    colorLi?: string;
    textDelete?: string;
}

const TableDelete = ({handler, colorLi, textDelete}:ITableDelete) => {
    return (
        <li onClick={handler} role='presentation' className={`text-sm ${colorLi || 'text-black'} text-nowrap hover:bg-ghostBlue cursor-pointer py-[4px] px-2`}>
            {textDelete || 'Удалить'}
        </li>
    );
};

export default TableDelete;
