import React, { useEffect, useRef, useState } from 'react';
import useAppSelector from '../../../hooks/redux';
import useActions from '../../../store/actions';
import { useGetRolesListQuery } from '../../../store/roles/roles.api';
import { ListsState } from '../../../store/lists/lists.slice';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { ReactComponent as Arrow } from '../../../image/svg/arrowBlue.svg';

const storeKey: keyof ListsState = 'users';
const filterKey = 'role_id';

const UserFilterRoleSelect = () => {
    const filterStoreValue = useAppSelector((state) => state.list[storeKey].filters[filterKey]);
    const { setFilters, deleteFilter } = useActions();

    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const { data } = useGetRolesListQuery();

    const ref = useRef<HTMLDivElement>(null);
    const filterItems = data?.data || [];

    useEffect(() => {
        setInputValue('');
        filterItems?.forEach((item: any) => {
            if (item.role_id === filterStoreValue) {
                setInputValue(item.role_name_short);
            }
        });
    }, [filterStoreValue, data]);

    const onSelectItem = (item: any) => {
        setIsOpen(false);
        setInputValue(item.role_name_short);
        setFilters({ storeKey, filterKey, filterValue: item.role_id });
    };

    const onChangeInput = () => {
        setInputValue('');
        if (filterStoreValue) {
            deleteFilter({ storeKey, filterKey });
        }
    };

    const closeHandler = () => setIsOpen(false);
    useOnClickOutside(ref, closeHandler);

    return (
        <div ref={ref} className='w-full mb-6 relative'>
            <div
                role='presentation'
                className={`select-container relative ${
                    isOpen ? 'rounded-t-lg border-blue' : 'rounded-lg border-borderGray'
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <input
                    type='text'
                    onChange={onChangeInput}
                    value={inputValue}
                    className='w-full h-full py-[10px] px-[12px] rounded-lg outline-0 font-bold'
                    placeholder={'Роль'}
                />
                <Arrow className={`w-[20px] h-[20px] duration-200 ${isOpen ? 'rotate-[90deg]' : 'rotate-[-90deg]'}`} />
            </div>
            {isOpen ? (
                <div className='select-items z-[1000]'>
                    {filterItems?.length ? (
                        filterItems.map((item: any) => (
                            <div
                                role='presentation'
                                key={item.role_id}
                                className={`${
                                    item.role_id === filterStoreValue
                                        ? 'div-arrow bg-blue text-white'
                                        : ' div-arrow text-black'
                                } select-item`}
                                onClick={() => {
                                    onSelectItem(item);
                                }}
                            >
                                {item.role_name_short}
                            </div>
                        ))
                    ) : (
                        <div className='text-sm h-[32px] flex justify-center items-center text-grayDisabled'>
                            Ничего не найдено
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default UserFilterRoleSelect;
