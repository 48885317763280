import React, { useEffect } from 'react';
import TableUser from '../components/TableUser/TableUser';
import Breadcrumbs from '../components/Breadcrumbs';
import useDataSourceParams from '../hooks/useDataSourceParams';
import { API_KEYS } from '../utils/dictionary';
import UserRegistration from '../components/UserRegistration/UserRegistration';
import useAppSelector from '../hooks/redux';

const apiKey = API_KEYS.Users;

const UsersPage = () => {
    const params = useAppSelector((state) => state.list.users.params);

    const { init } = useDataSourceParams(apiKey);

    useEffect(() => {
        init(params);
    }, []);

    return (
        <div className='pt-5 px-4'>
            <div className='flex items-center justify-between'>
                <Breadcrumbs title={'Реeстр участников СВР'} caption={'Реeстр участников СВР'} />
                <UserRegistration />
            </div>
            <TableUser />
        </div>
    );
};

export default UsersPage;
