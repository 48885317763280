import React from 'react';
import useAppSelector from "../../hooks/redux";

const AnimalsMarking = () => {
    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);

    // const params = useParams<'id'>();
    // const {data: animal, isLoading} = useGetAnimalPartQuery({
    //     id: params.id,
    //     body: {"data_sections": ["vib"]}
    // });

    return (
        <div className='bg-white'>
            <h2 className='text-base text-gray mb-6'>Выбытие животного</h2>
            <div className='flex gap-16'>
                <div>
                    <div className='flex flex-col mb-4'>
                        <span className='text-gray text-xs mb-1'>Дата</span>
                        <span
                            className='text-sm text-black bg-inherit w-full'>{currentAnimal.vib?.animal_out_date || '-'}</span>
                    </div>
                    <div className='flex flex-col mb-4'>
                            <span
                                className='text-gray text-xs mb-1'>Причина выбытия</span>
                        <span
                            className='text-sm text-black bg-inherit w-full'>{currentAnimal.vib?.animal_out_reason || '-'}</span>
                    </div>
                    <div className='flex flex-col mb-4'>
                        <span className='text-gray text-xs mb-1'>Расход</span>
                        <span
                            className='text-sm text-black bg-inherit w-full'>{currentAnimal.vib?.animal_out_rashod || '-'}</span>
                    </div>
                </div>
                <div>
                    <div className='flex flex-col mb-4'>
                        <span className='text-gray text-xs mb-1'>Ж.м.</span>
                        <span
                            className='text-sm text-black bg-inherit w-full'>{currentAnimal.vib?.animal_out_weight || '-'}</span>
                    </div>
                    <div className='flex flex-col mb-4'>
                        <span className='text-gray text-xs mb-1'>Тип выбытия</span>
                        <span
                            className='text-sm text-black bg-inherit w-full'>{currentAnimal.vib?.animal_out_type_name || '-'}</span>
                    </div>
                    <div className='flex flex-col mb-4'>
                            <span
                                className='text-gray text-xs mb-1'>Основания выбытия</span>
                        <span
                            className='text-sm text-black bg-inherit w-full'>{currentAnimal.vib?.animal_out_basis_name || '-'}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnimalsMarking;
