import React from 'react';
import {ReactComponent as IconCheck} from '../../../image/svg/check.svg';

interface IAnimalsCheckbox {
    isActive: boolean;
    enabled: boolean
}

const AnimalsCheckbox = ({isActive, enabled}: IAnimalsCheckbox) => {
    return enabled ? (
            <div className='flex justify-center items-center'>
                {isActive ? (
                    <div
                        className='flex items-center cursor-pointer justify-center w-[24px] h-[24px] min-w-[24px] min-h-[24px] bg-blue rounded'>
                        <IconCheck/>
                    </div>
                ) : (
                    <div
                        className='w-[24px] h-[24px] min-w-[24px] min-h-[24px] cursor-pointer border-[2px] rounded border-blue'/>
                )}
            </div>)
        :
        (
            <div className='flex justify-center items-center'>
                <div
                    className='w-[24px] h-[24px] min-w-[24px] min-h-[24px] cursor-not-allowed border-[2px] rounded border-grayDisabled bg-lightGray'/>
            </div>
        );
};

export default AnimalsCheckbox;
