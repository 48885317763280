import React, {useState} from 'react';
import MultiSelect from '../../Inputs/MultiSelect';
import useAppSelector from '../../../hooks/redux';
import {IChangeParamsFunc} from '../../../models/models';
import {useLazyAddUserCompanyQuery, useLazyDeleteUserCompanyQuery} from '../../../store/users/users.api';
import {useGetCompaniesLocationListQuery} from '../../../store/directories/directories.api';
import useDebounceString from '../../../hooks/debounceString';

const ChooseCompany = () => {
    const userId = useAppSelector((state) => state.users.currentUser.user_id);

    const currentUserCompanies = useAppSelector((state) => state.users.currentUser.user_companies_locations_list);
    const currentUserCompaniesDictionary = useAppSelector(
        (state) => state.dictionary.current?.user_companies_locations_list,
    );
    const [searchCompany, setSearchCompany] = useState('');
    const debouncedValue = useDebounceString(searchCompany, 500)

    const [addCompany] = useLazyAddUserCompanyQuery();
    const [deleteCompany] = useLazyDeleteUserCompanyQuery();
    const {data} = useGetCompaniesLocationListQuery(debouncedValue);

    const setCompany: IChangeParamsFunc = (id, addFlag) => {
        const params = {
            user_id: userId,
            company_location_id: id,
        };

        if (addFlag) {
            addCompany(params);
        } else {
            deleteCompany(params);
        }
    };

    return (
        <>
            <MultiSelect
                placeholder='Привязанные хозяйства'
                initValue={currentUserCompanies}
                list={data?.data}
                dictionaryKey={'company_location_id'}
                dictionaryValue={'company_name_short'}
                callback={setCompany}
                setSearch={setSearchCompany}
                searchValue={searchCompany}
                dictionaryTwoKey={'company_region_name'}
                dictionaryThreeKey={'company_district_name'}
                usersDictionaryList={currentUserCompaniesDictionary}
            />
        </>
    );
};

export default ChooseCompany;
