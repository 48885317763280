import React, { useEffect, useRef, useState } from 'react';
import { useLazyLoginQuery } from '../store/auth/auth.api';
import InputPassword from '../components/Inputs/InputPassword';
import logo from '../image/logo-big.png';
import validateCheck from '../utils/validateCheck';
import { authSchema } from '../utils/validation';
import { IAuth } from '../models/base/user';

const AuthPage = () => {
    const [fetchLogin] = useLazyLoginQuery();
    const [errors, setErrors] = useState<{ [x: string]: string } | undefined>();
    const emailInputRef = useRef<HTMLInputElement>(null);

    const [touched, setTouched] = useState({
        user_email: false,
        user_password: false,
    });

    const [authObj, setAuthObj] = useState<IAuth>({
        user_email: '',
        user_password: '',
    });

    useEffect(() => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, []);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget as { name: keyof typeof touched, value: string };
        setTouched({ ...touched, [name]: true });
        setAuthObj((prev) => ({ ...prev, [name]: value }));

        if (touched[name]) {
            const newErrors = await validateCheck({validateObj: {...authObj, [name]: value}, schema: authSchema});
            setErrors({...newErrors});
        }
    };

    const loginAuth = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!errors?.user_email && !errors?.user_password) {
            setErrors({});
            fetchLogin(authObj);
        }
    };

    return (
        <div className='h-full px-4 py-4'>
            <div className='flex flex-col pt-[60px] h-full bg-white rounded-lg items-center relative'>
                <h2 className='absolute top-[15px] left-[15px] text-black test-2xl'>Авторизация</h2>
                <img src={logo} alt='Plinor' width={512} height={207} className='mb-8' />
                <form onSubmit={loginAuth}>
                    <div className='relative w-[560px]'>
                        <div className='flex flex-col mb-6 relative w-full'>
                            <label className='input-label' htmlFor='user_email'>
                                Email {<span className='text-sm text-red'>&nbsp;*</span>}
                            </label>
                            <input
                                ref={emailInputRef}
                                className='input-default rounded-lg text-black font-bold outline-0 py-[8px] px-[12px] border-borderGray border-[2px] w-full hover:border-blue focus:border-blue placeholder-grayLight text-sm'
                                type='text'
                                id='user_email'
                                name='user_email'
                                placeholder='Введите Email'
                                onChange={handleChange}
                            />
                            {errors && <p className='absolute text-xs text-red top-[40px]'>{errors?.user_email}</p>}
                        </div>
                        <InputPassword
                            label='Пароль'
                            name='user_password'
                            id='user_password'
                            placeholder='Введите пароль'
                            onChange={handleChange}
                            error={errors?.user_password}
                            required
                        />
                        <div className='flex justify-center'>
                            <button
                                type='submit'
                                className='py-2 px-4 bg-blue text-white w-full rounded-lg hover:bg-blueHover'
                            >
                                Авторизоваться
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AuthPage;
