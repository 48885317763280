import React from 'react';
import useSetStateInputsValue from "../../hooks/useSetStateInputsValue";

interface IPhoneInput {
    label?: string;
    required?: boolean;
    className?: string;
    disabled?: boolean;
    storeKey: string;
    value?: string;
    error?: string;
    isTouched?: boolean;
    setIsTouched?: (flag: boolean) => void;
}
const PhoneInput: React.FC<IPhoneInput> = ({isTouched, setIsTouched, label, required, className, disabled, storeKey, value, error}) => {
    const {internalValue, setInternalValue} = useSetStateInputsValue(storeKey, value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        let cleanValue = value.replace(/\D+/g, '');
        let formattedValue = '+7 ';

        if (cleanValue.startsWith('7')) {
            cleanValue = cleanValue.substring(1);
        }

        formattedValue += cleanValue
            .split('')
            .reduce((acc, digit, index) => {
                if (index === 3 || index === 6 || index === 8 || index === 10) {
                    return `${acc} ${digit}`;
                }
                return acc + digit;
            }, '');

        // Ограничение на максимальную длину номера
        if (formattedValue.replace(/\D+/g, '').length <= 11) {
            setInternalValue(formattedValue.trim());
            if (!isTouched && setIsTouched) {
                setIsTouched(true);
            }
        }
    };

    const handleBlur = () => {
        if (setIsTouched) {
            setIsTouched(true);
        }
    };

    return (
        <div className='flex flex-col mb-6 relative w-full'>
            {label && (
                <label
                    className='input-label'
                    htmlFor={storeKey}
                >
                    {label} {required && <span className='text-sm text-red'>&nbsp;*</span>}
                </label>
            )}
        <input
            className={`input-default rounded-lg text-black font-bold outline-0 py-[8px] px-[12px] border-borderGray border-[2px] w-full ${!disabled && 'hover:border-blue'} focus:border-blue placeholder-grayLight text-sm ${disabled && 'cursor-default text-gray'
            } ${className}`}
            type="tel"
            onBlur={handleBlur}
            value={internalValue ?? ''}
            onChange={handleChange}
            placeholder="+7 ___ ___ __ __"
            disabled={disabled}
        />
            {error && <p className='absolute text-xs text-red top-[40px]'>{error}</p>}
        </div>
    );
};

export default PhoneInput;