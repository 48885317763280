import React from 'react';
import DictionarysValue from '../DictionarysValue';

interface IProfileStatus {
    roles: number[];
}

const ProfileRoles = ({roles}: IProfileStatus) => {
    return (
        <div className='flex flex-col mb-4'>
            <span className='text-gray text-xs mb-1'>Роли пользователя</span>
            <DictionarysValue idArray={roles} keyDictionary={'user_roles_list'} keyValue={'role_name_short'} />
        </div>
    );
};

export default ProfileRoles;
