import React from 'react';
import { UserStatusObject } from '../../utils/dictionary';
import UserFilterRoleSelect from './UserFilter/UserFilterRoleSelect';
import ReactRangePickerNew from '../Inputs/RangePickerNew';
import { ListsState } from '../../store/lists/lists.slice';
import FilterSelect from '../Inputs/FilterSelect';
import FilterInput from '../Inputs/FilterInput';
import UserFilterRegionSelect from './UserFilter/UserFilterRegionSelect';
import UserFilterDistrictSelect from './UserFilter/UserFilterDistrictSelect';
import UserFilterCompanySelect from './UserFilter/UserFilterCompanySelect';
import useTableFilter from '../../hooks/useTableUserFilter';

interface ITableFilter {
    setOpen: (flag: boolean) => void;
}
const storeKey: keyof ListsState = 'users';

const UserFilter = ({ setOpen }: ITableFilter) => {
    const { handlerFilterReset, handlerFilterApply } = useTableFilter(storeKey);

    return (
        <div className='flex flex-col justify-between h-full'>
            <div>
                <FilterInput filterKey='user_id' storeKey={storeKey} placeholder='ID пользователя' />
                <FilterInput filterKey='user_full_name' storeKey={storeKey} placeholder='ФИО' />
                <UserFilterRegionSelect />
                <UserFilterDistrictSelect />
                <UserFilterCompanySelect />
                <h3 className='text-sm text-black mb-2'>Дата регистрации</h3>
                <div className='flex gap-4'>
                    <ReactRangePickerNew
                        minKey='user_date_register_min'
                        maxKey='user_date_register_max'
                        storeKey={storeKey}
                        placeholder={['С', 'По']}
                    />
                </div>
                <h3 className='text-sm text-black mb-2'>Дата блокировки</h3>
                <div className='flex gap-4'>
                    <ReactRangePickerNew
                        minKey='user_date_block_min'
                        maxKey='user_date_block_max'
                        storeKey={storeKey}
                        placeholder={['С', 'По']}
                    />
                </div>
                <UserFilterRoleSelect />
                <FilterSelect
                    placeholder='Статус пользователя'
                    storeKey={storeKey}
                    listObject={UserStatusObject}
                    filterKey={'user_status'}
                />
            </div>
            <div>
                <button
                    className='text-sm w-full px-4 py-2 bg-blue rounded-lg text-white mb-4'
                    type='button'
                    onClick={() => {
                        handlerFilterApply();
                        setOpen(false);
                    }}
                >
                    Применить
                </button>
                <button
                    className='text-sm w-full px-4 py-2 bg-grayDisabled rounded-lg hover:bg-gray'
                    type='button'
                    onClick={handlerFilterReset}
                >
                    Очистить
                </button>
            </div>
        </div>
    );
};

export default UserFilter;
