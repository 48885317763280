import React, { useState } from 'react';
import ButtonClearFilter from '../../ButtonClearFilter';
import Drawer from '../../Drawer';
// import { ApiKeysType } from '../../../models/models';
// import useTableFilter from '../../../hooks/useTableUserFilter';
// import AnimalsFilter from "../../Animals/AnimalsTableRegistry/AnimalsFilter";
import AplicationAnimalsFilter from './AplicationAnimalsFilter';
// import {API_KEYS} from '../../../utils/dictionary';

// interface ITableFilter {
//     apiKey: ApiKeysType;
//     initFilter?: { [key: string]: string | number | undefined };
//     delKey?: string;
// }
const storeKey = 'applicationAnimals';
const ApplicationTableFilter = () => {
    // const { open, setOpen, filterObj, handlerFilterReset } = useTableFilter(apiKey, initFilter);
    const [open, setOpen] = useState(false);
    // const tempObj = { ...filterObj };

    // if (delKey) {
    //     delete tempObj[delKey];
    // }

    return (
        <div className='flex items-center'>
            <ButtonClearFilter storeKey={storeKey} />
            <Drawer open={open} setOpen={setOpen} storeKey={storeKey}>
                <AplicationAnimalsFilter
                    setOpen={setOpen}
                    // filter={filterObj}
                    // changeFilter={changeFilter}
                    // filterApply={handlerFilterApply}
                    // filterReset={handlerFilterReset}
                />
            </Drawer>
        </div>
    );
};

export default ApplicationTableFilter;
