import React from 'react';

interface IGroup {
    classSvg?: string;
}

const Group = ({classSvg}:IGroup) => (
    <svg className={classSvg} width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M11.5405 5C13.0458 5 14.4895 5.55948 15.5538 6.55536C16.6182 7.55125 17.2162 8.90195 17.2162 10.3103C17.2162 11.7187 16.6182 13.0694 15.5538 14.0653C14.4895 15.0612 13.0458 15.6207 11.5405 15.6207C10.0353 15.6207 8.59163 15.0612 7.52723 14.0653C6.46284 13.0694 5.86486 11.7187 5.86486 10.3103C5.86486 8.90195 6.46284 7.55125 7.52723 6.55536C8.59163 5.55948 10.0353 5 11.5405 5ZM22.8919 8.7931C24.1821 8.7931 25.4195 9.27266 26.3319 10.1263C27.2442 10.9799 27.7568 12.1376 27.7568 13.3448C27.7568 14.552 27.2442 15.7098 26.3319 16.5634C25.4195 17.417 24.1821 17.8966 22.8919 17.8966C21.0108 17.8966 19.3892 16.8952 18.5784 15.4386C19.973 13.7545 20.6541 11.6 20.4108 9.43035C21.1405 9.02069 21.9838 8.7931 22.8919 8.7931ZM1 24.3448C1 21.2041 5.71892 18.6552 11.5405 18.6552C17.3622 18.6552 22.0811 21.2041 22.0811 24.3448V27H1V24.3448ZM31 27H25.3243V24.3448C25.3243 22.7821 24.7405 21.3559 23.7838 20.3241C27.9351 20.84 31 22.6152 31 24.7241V27Z'
            fill='#BDDDFF'
        />
    </svg>
);

export default Group;
