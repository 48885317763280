export type NotificationType = {
    id: string;
    timeout: number;
    type: 'success' | 'error' | 'info' | 'warning';
    text: string;
    header?: string;
    values?: any;
    total?: number;
};

// Определение enum для типов уведомлений
export enum NotificationTypeEnum {
    Error = 'error',
    Success = 'success',
    Info = 'info',
    Warning = 'warning'
}