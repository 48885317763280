import React from 'react';
import TableTitle from '../TableUser/TableTitle';
import useAppSelector from '../../hooks/redux';
import ModalWrap from './AnimalsMark/ModalWrap';

const AnimalMarking = () => {
    const currentAnimal = useAppSelector((state) => state.animals.currentAnimal);

    return (
        <div className='table-container max-w-full overflow-y-auto'>
            <table className='table-content'>
                <thead className='table-thead'>
                    <tr>
                        <TableTitle title='Вид номера' />
                        <TableTitle title='Номер' />
                        <TableTitle title='Вид маркировки' />
                        <TableTitle title='Тип средства' />
                        <TableTitle title='Место нанесения' />
                        <TableTitle title='Описание' />
                        <TableTitle title='Дата нанесения' />
                        <TableTitle title='Дата выбытия' />
                    </tr>
                </thead>
                <tbody className='table-tbody'>
                    {currentAnimal.mark?.items.map((item) => {
                        return (
                            <tr className='table-tbody-tr-marking' key={item.mark_id}>
                                <td className='table-tbody-td-marking'>{item.mark_type_name}</td>
                                <td className='table-tbody-td-marking'>{item.number}</td>
                                <td className='table-tbody-td-marking'>{item.mark_status_name}</td>
                                <td className='table-tbody-td-marking'>{item.mark_tool_type_name}</td>
                                <td className='table-tbody-td-marking'>{item.mark_tool_location_name}</td>
                                <td className='table-tbody-td-marking'>{item.description}</td>
                                <td className='table-tbody-td-marking'>{item.mark_date_set}</td>
                                <td className='table-tbody-td-marking'>{item.mark_date_out}</td>
                                <td aria-label='modal' className='table-tbody-td-marking'>
                                    <ModalWrap item={item} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default AnimalMarking;
