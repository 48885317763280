import React, { memo, useCallback, useEffect, useState } from 'react';
import NotifyItem from './NotifyItem';
import {
    useLazyCheckAllNotificationsQuery,
    useLazyGetNotificationsQuery,
} from '../../store/notifications/notifications.api';
import useAppSelector from '../../hooks/redux';
import { INotificationData } from '../../models/base/notifications';
import { BellType, NotificationStatus, paramsNotify } from '../../utils/dictionary';

const STEP = 10;
const Notifications = () => {
    const [checkAllNotifications, { isLoading: isLoadingAll }] = useLazyCheckAllNotificationsQuery();
    const [getNotifications, { data, isLoading: isLoadingCount }] = useLazyGetNotificationsQuery();
    const notifications = useAppSelector((state) => state.notifications.notifications);

    useEffect(() => {
        getNotifications(paramsNotify);
    }, [notifications.count_total]);

    const [visible, setVisible] = useState(STEP);
    const sliceNotify: INotificationData[] | undefined  = data?.data.slice(0, visible);

    const showMoreItems = async () => {
        setVisible((prevVisible) => {
            const newVisible = prevVisible + STEP;
            getNotifications({
                ...paramsNotify,
                per_page: newVisible,
            });
            return newVisible;
        });
    };

    const handlerClickCheckAll = async () => {
        await checkAllNotifications();
        getNotifications({
            ...paramsNotify,
            per_page: visible,
        });
    };

    const NotifyItemMemo = memo(NotifyItem);

    const notifyItemType = useCallback(
        (item: INotificationData) => {
            let bellType: BellType;
            switch (item.notification_type) {
                case NotificationStatus.APPLICATION_ANIMAL_DELETE:
                case NotificationStatus.APPLICATION_FINISHED:
                    bellType = BellType.Yellow;
                    break;
                case NotificationStatus.APPLICATION_PASSWORD_CHANGE:
                    bellType = BellType.Green;
                    break;
                default:
                    bellType = BellType.Blue;
            }
            return (
                <NotifyItemMemo key={item.notification_id} item={item} notifyTypeBell={bellType} visible={visible} />
            );
        },
        [visible],
    );

    return (
        <div className='max-h-full h-full pb-[95px] overflow-y-auto'>
            {sliceNotify && sliceNotify?.length > 0 ? (
                sliceNotify.map(notifyItemType)
            ) : (
                <div className='flex items-center h-full justify-center text-sm text-gray'>Нет уведомлений</div>
            )}
            {notifications.count_total > visible && (
                <div className='bg-white w-full flex justify-center items-center mt-4'>
                    <button type='button' className='button-link' onClick={showMoreItems} disabled={isLoadingCount}>
                        Показать еще
                    </button>
                </div>
            )}
            {notifications.count_new >= 2 && (
                <div className='absolute bg-white w-full flex h-[70px] justify-center items-center bottom-[-26px]'>
                    <button
                        onClick={handlerClickCheckAll}
                        type='button'
                        className='button-link'
                        disabled={isLoadingAll}
                    >
                        Пометить как прочитанные
                    </button>
                </div>
            )}
        </div>
    );
};

export default Notifications;
