import React from 'react';
import { ApplicationStatusObject } from '../../utils/dictionary';
import ApplicationFilterDistrictSelect from './ApplicationFilterDistrictSelect';
import FilterInput from '../Inputs/FilterInput';
import { ListsState } from '../../store/lists/lists.slice';
import FilterSelect from '../Inputs/FilterSelect';
import ReactRangePickerNew from '../Inputs/RangePickerNew';
import useTableFilter from '../../hooks/useTableUserFilter';

interface ITableFilter {
    setOpen: (flag: boolean) => void;
}
const storeKey: keyof ListsState = 'applications';
const ApplicationsFilter = ({ setOpen }: ITableFilter) => {
    const { handlerFilterReset, handlerFilterApply } = useTableFilter(storeKey);

    return (
        <div className='flex flex-col justify-between h-full'>
            <div>
                <FilterInput filterKey='user_id' storeKey={storeKey} placeholder='ID пользователя' />
                <FilterInput filterKey='user_full_name' storeKey={storeKey} placeholder='ФИО' />
                <ApplicationFilterDistrictSelect />
                <h3 className='text-sm text-black mb-2'>Дата создания заявки</h3>
                <div className='flex gap-4'>
                    <ReactRangePickerNew
                        minKey='application_date_create_min'
                        maxKey='application_date_create_max'
                        storeKey={storeKey}
                        placeholder={['С', 'По']}
                    />
                </div>
                <h3 className='text-sm text-black mb-2'>Дата регистрации в Хорриот</h3>
                <div className='flex gap-4'>
                    <ReactRangePickerNew
                        minKey='application_date_registration_min'
                        maxKey='application_date_registration_max'
                        storeKey={storeKey}
                        placeholder={['С', 'По']}
                    />
                </div>
                <FilterSelect
                    placeholder='Статус пользователя'
                    storeKey={storeKey}
                    listObject={ApplicationStatusObject}
                    filterKey={'application_status'}
                />
            </div>
            <div>
                <button
                    className='text-sm w-full px-4 py-2 bg-blue rounded-lg text-white mb-4'
                    type='button'
                    onClick={() => {
                        handlerFilterApply();
                        setOpen(false);
                    }}
                >
                    Применить
                </button>
                <button
                    className='text-sm w-full px-4 py-2 bg-grayDisabled rounded-lg hover:bg-gray'
                    type='button'
                    onClick={handlerFilterReset}
                >
                    Очистить
                </button>
            </div>
        </div>
    );
};

export default ApplicationsFilter;
