import React, { useState } from 'react';
import Modal from '../Modal';
import UserRegistrationWrap from "./UserRegistrationWrap";

const UserRegistration = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Modal
            title='Создание пользователя'
            setShowModal={setIsOpen}
            showModal={isOpen}
            buttonStyle='button-blue'
            textButton='Создать пользователя'
        >
            <UserRegistrationWrap setShowModal={setIsOpen}/>
        </Modal>
    );
};

export default UserRegistration;
