import { createSlice } from '@reduxjs/toolkit';
import { IAuthUser } from '../../models/base/user';
import { authApi } from './auth.api';
import { usersApi } from '../users/users.api';

export const LS_AUTH_KEY = 'svr-auth';

interface AuthState {
    user: IAuthUser;
}

const initialState: AuthState = {
    user: {
        user_token: localStorage.getItem(`${LS_AUTH_KEY}-user_token`) || '',
        user_id: localStorage.getItem(`${LS_AUTH_KEY}-user_id`) || '',
        user_first: localStorage.getItem(`${LS_AUTH_KEY}-user_first`) || '',
        user_last: localStorage.getItem(`${LS_AUTH_KEY}-user_last`) || '',
        user_middle: localStorage.getItem(`${LS_AUTH_KEY}-user_middle`) || '',
        user_roles_list: localStorage.getItem(`${LS_AUTH_KEY}-user_roles_list`)
            ? JSON.parse(localStorage.getItem(`${LS_AUTH_KEY}-user_roles_list`) as string)
            : [],
        user_avatar_small: localStorage.getItem(`${LS_AUTH_KEY}-user_avatar_small`) || '',
        user_avatar_big: localStorage.getItem(`${LS_AUTH_KEY}-user_avatar_big`) || '',
        user_companies_locations_list: localStorage.getItem(`${LS_AUTH_KEY}-user_companies_locations_list`)
            ? JSON.parse(localStorage.getItem(`${LS_AUTH_KEY}-user_companies_locations_list`) as string)
            : [],
        user_districts_list: localStorage.getItem(`${LS_AUTH_KEY}-user_districts_list`)
            ? JSON.parse(localStorage.getItem(`${LS_AUTH_KEY}-user_districts_list`) as string)
            : [],
        user_regions_list: localStorage.getItem(`${LS_AUTH_KEY}-user_regions_list`)
            ? JSON.parse(localStorage.getItem(`${LS_AUTH_KEY}-user_regions_list`) as string)
            : [],
        // user_herriot_data: localStorage.getItem(`${LS_AUTH_KEY}-user_herriot_data`)
        //     ? JSON.parse(localStorage.getItem(`${LS_AUTH_KEY}-user_herriot_data`) as string)
        //     : {}
    },
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_token`,
                JSON.stringify(action.payload.data.user_token).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_id`,
                JSON.stringify(action.payload.data.user_id).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_first`,
                JSON.stringify(action.payload.data.user_first).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_last`,
                JSON.stringify(action.payload.data.user_last).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_middle`,
                JSON.stringify(action.payload.data.user_middle).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_avatar_small`,
                JSON.stringify(action.payload.data.user_avatar_small).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_avatar_big`,
                JSON.stringify(action.payload.data.user_avatar_big).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_roles_list`,
                JSON.stringify(action.payload.data.user_roles_list).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_companies_locations_list`,
                JSON.stringify(action.payload.data.user_companies_locations_list).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_districts_list`,
                JSON.stringify(action.payload.data.user_districts_list).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_regions_list`,
                JSON.stringify(action.payload.data.user_regions_list).replace(/"/g, ''),
            );
            state.user = action.payload.data;
        },
        logout(state) {
            state.user = {
                user_id: '',
                user_first: '',
                user_last: '',
                user_middle: '',
                user_token: '',
                user_roles_list: [],
                user_avatar_small: '',
                user_avatar_big: '',
                user_companies_locations_list: [],
                user_districts_list: [],
                user_regions_list: [],
                user_herriot_data: {
                    login: '',
                    password: '',
                    web_login: '',
                },
            };
            localStorage.removeItem(`${LS_AUTH_KEY}-user_token`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_id`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_first`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_last`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_middle`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_avatar_small`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_avatar_big`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_roles_list`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_companies_locations_list`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_districts_list`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_regions_list`);
            localStorage.removeItem(`${LS_AUTH_KEY}-user_herriot_data`);
        },
        setAuthUserRoles(state, action) {
            state.user.user_roles_list = action.payload.data.user_roles_list;

            localStorage.setItem(
                `${LS_AUTH_KEY}-user_roles_list`,
                JSON.stringify(action.payload.data.user_roles_list).replace(/"/g, ''),
            );
        },
        removeAuthUserRole(state, action) {
            state.user.user_roles_list = action.payload.data.user_roles_list;
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_roles_list`,
                JSON.stringify(state.user.user_roles_list).replace(/"/g, ''),
            );
        },
        setAuthUserImages(state, action) {
            state.user.user_avatar_small = action.payload.data.user_avatar_small;
            state.user.user_avatar_big = action.payload.data.user_avatar_big;

            localStorage.setItem(
                `${LS_AUTH_KEY}-user_avatar_small`,
                JSON.stringify(action.payload.data.user_avatar_small).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_avatar_big`,
                JSON.stringify(action.payload.data.user_avatar_big).replace(/"/g, ''),
            );
        },
        setAuthUserCompanies(state, action) {
            state.user.user_companies_locations_list = action.payload.data.user_companies_locations_list;

            localStorage.setItem(
                `${LS_AUTH_KEY}-user_companies_locations_list`,
                JSON.stringify(action.payload.data.user_companies_locations_list).replace(/"/g, ''),
            );
        },
        setAuthUserLocation(state, action) {
            state.user.user_districts_list = action.payload.data.user_districts_list;
            state.user.user_regions_list = action.payload.data.user_regions_list;

            localStorage.setItem(
                `${LS_AUTH_KEY}-user_districts_list`,
                JSON.stringify(action.payload.data.user_districts_list).replace(/"/g, ''),
            );
            localStorage.setItem(
                `${LS_AUTH_KEY}-user_regions_list`,
                JSON.stringify(action.payload.data.user_regions_list).replace(/"/g, ''),
            );
        },
        api_updateRoles(state, action) {
            const currentId = action.payload.data.user_id;
            const authId = state.user.user_id;
            if (+authId === +currentId) {
                AuthSlice.caseReducers.setAuthUserRoles(state, action);
            }
        },
        api_removeAuthUserRole(state, action) {
            const currentId = action.payload.data.user_id;
            const authId = state.user.user_id;
            if (+authId === +currentId) {
                AuthSlice.caseReducers.removeAuthUserRole(state, action);
            }
        },
        api_updateAvatar(state, action) {
            const currentId = action.payload.data.user_id;
            const authId = state.user.user_id;
            if (+authId === +currentId) {
                AuthSlice.caseReducers.setAuthUserImages(state, action);
            }
        },
        api_updateHerriotDate(state, action) {
            const currentId = action.payload.data.user_id;
            const authId = state.user.user_id;
            if (+authId === +currentId) {
                state.user.user_herriot_data = action.payload.data.user_herriot_data;

                localStorage.setItem(
                    `${LS_AUTH_KEY}-user_herriot_data`,
                    JSON.stringify(action.payload.data.user_herriot_data).replace(/"/g, ''),
                );
            }
        },
        api_updateCompanies(state, action: any) {
            const currentId = action.meta.arg.originalArgs.user_id;
            const authId = state.user.user_id;
            if (+authId === +currentId) {
                AuthSlice.caseReducers.setAuthUserCompanies(state, action);
            }
        },
        api_updateLocation(state, action: any) {
            const currentId = action.meta.arg.originalArgs.user_id;
            const authId = state.user.user_id;
            if (+authId === +currentId) {
                AuthSlice.caseReducers.setAuthUserLocation(state, action);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
                AuthSlice.caseReducers.login(state, action);
            })
            .addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
                AuthSlice.caseReducers.logout(state);
            })
            .addMatcher(authApi.endpoints.logout.matchRejected, (state) => {
                AuthSlice.caseReducers.logout(state);
            })
            .addMatcher(usersApi.endpoints.addUserRole.matchFulfilled, (state, action) => {
                // console.log(action)
                AuthSlice.caseReducers.api_updateRoles(state, action);
            })
            .addMatcher(usersApi.endpoints.deleteUserRole.matchFulfilled, (state, action) => {
                AuthSlice.caseReducers.api_removeAuthUserRole(state, action);
            })
            .addMatcher(usersApi.endpoints.getAuthUser.matchFulfilled, (state, action) => {
                AuthSlice.caseReducers.api_updateHerriotDate(state, action);
            })
            .addMatcher(usersApi.endpoints.addUserCompany.matchFulfilled, (state, action) => {
                AuthSlice.caseReducers.api_updateCompanies(state, action);
            })
            .addMatcher(usersApi.endpoints.deleteUserCompany.matchFulfilled, (state, action) => {
                AuthSlice.caseReducers.api_updateCompanies(state, action);
            })
            .addMatcher(usersApi.endpoints.addUserLocation.matchFulfilled, (state, action) => {
                AuthSlice.caseReducers.api_updateLocation(state, action);
            })
            .addMatcher(usersApi.endpoints.deleteUserLocation.matchFulfilled, (state, action) => {
                AuthSlice.caseReducers.api_updateLocation(state, action);
            })
            .addMatcher(usersApi.endpoints.setUserAvatar.matchFulfilled, (state, action) => {
                AuthSlice.caseReducers.api_updateAvatar(state, action);
            })
            .addMatcher(usersApi.endpoints.editUser.matchFulfilled, (state, action) => {
                const currentId = action.payload.data.user_id;
                const authId = state.user.user_id;
                if (+authId === +currentId) {
                    localStorage.setItem(
                        `${LS_AUTH_KEY}-user_first`,
                        JSON.stringify(action.payload.data.user_first).replace(/"/g, ''),
                    );
                    state.user.user_first = action.payload.data.user_first;
                    localStorage.setItem(
                        `${LS_AUTH_KEY}-user_last`,
                        JSON.stringify(action.payload.data.user_last).replace(/"/g, ''),
                    );
                    state.user.user_last = action.payload.data.user_last;
                    localStorage.setItem(
                        `${LS_AUTH_KEY}-user_middle`,
                        JSON.stringify(action.payload.data.user_middle).replace(/"/g, ''),
                    );
                    state.user.user_middle = action.payload.data.user_middle;
                    // AuthSlice.caseReducers.login(state, action);
                }
            });
    },
});

export const authActions = AuthSlice.actions;
export const authReducer = AuthSlice.reducer;
