import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import DotSvg from '../../image/svg/DotSvg';
import Portal from '../Portal';

interface ITableMenu {
    children: React.ReactNode;
}

const TableMenu = ({ children }: ITableMenu) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [top, setTop] = useState(false);
    const [coords, setCoords] = useState({
        top: 0,
        left: 0,
    });
    const closeHandler = (event: MouseEvent | TouchEvent) => {
        if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
            return;
        }
        setIsOpen(false);
    };

    useOnClickOutside(ref, closeHandler);

    const clientHeight = document.getElementById('height')?.clientHeight;
    const windowHeight = window.innerHeight;
    const handleButton = (e: React.MouseEvent<HTMLElement>) => {
        const position = (e.target as HTMLElement).getBoundingClientRect();
        setCoords({
            left: position.x + position.width / 2 - 155,
            top: position.y + window.scrollY + 21,
        });
        setIsOpen(prevState => !prevState);
    };

    useEffect(() => {
        const heightPage = clientHeight && clientHeight > windowHeight ? clientHeight : windowHeight;
        if (heightPage) {
            if (coords.top + 92 > heightPage) {
                setTop(true);
            } else {
                setTop(false);
            }
        }
    }, [coords, clientHeight, windowHeight]);

    return (
        <div>
            <button
                ref={buttonRef}
                className='flex justify-end items-center w-full'
                type='button'
                aria-label='dot'
                onClick={handleButton}
            >
                <DotSvg classSvg='dot-svg' color='#2B6CB0' />
            </button>
            {isOpen && (
                <Portal>
                    <div
                        ref={ref}
                        style={{ top: top ? coords.top - 60 : coords.top, left: coords.left }}
                        className='table-menu'
                    >
                        <ul>{children}</ul>
                    </div>
                </Portal>
            )}
        </div>
    );
};

export default TableMenu;
