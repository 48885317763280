import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from './auth/auth.api';
import { usersApi } from './users/users.api';
import { animalsApi } from './animals/animals.api';
import { notificationsApi } from './notifications/notifications.api';
import { rolesApi } from './roles/roles.api';
import { directoriesApi } from './directories/directories.api';
import { applicationsApi } from './applications/applications.api';

import rtkQueryErrorLogger from './middleware';
import { authReducer } from './auth/auth.slice';
import { usersReducer } from './users/users.slice';
import { animalsReducer } from './animals/animals.slice';
import { notificationsReducer } from './notifications/notifications.slice';
import { applicationsReducer } from './applications/applications.slice';
import { dictionaryReducer } from './dictionary.slice';
import { inputValuesReducer } from './inputValues/inputValues.slice';
import { rolesReducer } from './roles/roles.slice';
import { companyApi } from './companies/company.api';
import { listReducer } from './lists/lists.slice';
// import {companiesReducer} from "./companies/company.slice";

export const store = configureStore({
    reducer: {
        list: listReducer,
        [authApi.reducerPath]: authApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [animalsApi.reducerPath]: animalsApi.reducer,
        [notificationsApi.reducerPath]: notificationsApi.reducer,
        [rolesApi.reducerPath]: rolesApi.reducer,
        [directoriesApi.reducerPath]: directoriesApi.reducer,
        [applicationsApi.reducerPath]: applicationsApi.reducer,
        [companyApi.reducerPath]: companyApi.reducer,
        auth: authReducer,
        users: usersReducer,
        animals: animalsReducer,
        notifications: notificationsReducer,
        applications: applicationsReducer,
        // companies: companiesReducer,
        inputs: inputValuesReducer,
        dictionary: dictionaryReducer,
        roles: rolesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            rtkQueryErrorLogger,
            authApi.middleware,
            usersApi.middleware,
            animalsApi.middleware,
            notificationsApi.middleware,
            applicationsApi.middleware,
            companyApi.middleware,
            rolesApi.middleware,
            directoriesApi.middleware,
        ),
});

setupListeners(store.dispatch);

export type RootStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
