import React, { useCallback } from 'react';
import { ReactComponent as Cross } from '../../../image/svg/cross-profile.svg';
import ModalConfirm from '../../ModalConfirm';
import { useLazyDeleteUserAvatarQuery, useLazySetUserAvatarQuery } from '../../../store/users/users.api';
import useAppSelector from '../../../hooks/redux';
import userPlaceholder from '../../../image/user-big.png';

const UserDownloadPhoto = () => {
    const userId = useAppSelector((state) => state.users.currentUser.user_id);
    const userAvatarBig = useAppSelector((state) => state.users.currentUser.user_avatar_big);

    const [setUserAvatar] = useLazySetUserAvatarQuery();
    const [deleteUserAvatar, {isLoading}] = useLazyDeleteUserAvatarQuery();

    const handleImageChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (e.target.files?.[0]) {
                const file = e.target.files[0];

                const formData = new FormData();
                formData.append('avatar', file);
                formData.append('user_id', userId.toString());
                setUserAvatar(formData);

                e.target.value = '';
            }
        },
        [setUserAvatar, userId],
    );

    const onDelete = useCallback(() => {
        deleteUserAvatar(userId);
    }, [deleteUserAvatar, userId]);

    return (
        <div className='pt-2 pb-5 rounded-lg'>
            <div className='relative'>
                <div className='relative download__container'>
                    <div className='download__box'>
                        <label htmlFor={`input__file_${userId}`} className='label-photo'>
                            <input
                                name='file'
                                type='file'
                                id={`input__file_${userId}`}
                                className='absolute hidden opacity-0'
                                onChange={handleImageChange}
                            />
                            <span className='input__file-icon-wrapper'>
                                <img
                                    className='rounded-[50%] bg-no-repeat shadow-[0_0_7px_-2px_rgba(0,0,0,0.5)] object-cover hover:opacity-80 w-[100px] h-[100px]'
                                    src={userAvatarBig || userPlaceholder}
                                    alt='user'
                                />
                                <span className='text-sm text-center text-blue link leading-[14px]'>Добавить фото</span>
                            </span>
                        </label>
                        {userAvatarBig && (
                            <ModalConfirm
                                textHeader='Подтвердите удаление фотографии'
                                onClick={onDelete}
                                type='danger'
                                textButtonConf='Удалить'
                                isLoading={isLoading}
                            >
                                <button aria-label='button' className='button-delete-photo' type='button'>
                                    <Cross />
                                </button>
                            </ModalConfirm>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDownloadPhoto;
