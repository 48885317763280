import { createApi } from '@reduxjs/toolkit/query/react';
import { INotificationData } from '../../models/base/notifications';
import { IParams, IServerResponse } from '../../models/models';
import { baseQuery } from '../middleware';

export const notificationsApi = createApi({
    reducerPath: 'notifications/api',
    tagTypes: ['Notifications'],
    baseQuery,
    endpoints: (build) => ({
        getNotifications: build.query<IServerResponse<INotificationData[]>, IParams>({
            query: (params) => ({
                url: `notifications/list`,
                method: 'GET',
                params,
            }),
        }),
        checkNotification: build.query<IServerResponse<INotificationData>, number>({
            query: (id: number) => ({
                url: `notifications/data/${id}`,
                method: 'POST',
            }),
        }),
        checkAllNotifications: build.query<IServerResponse<INotificationData[]>, void>({
            query: () => ({
                url: `notifications/read_all`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useLazyGetNotificationsQuery, useLazyCheckAllNotificationsQuery, useLazyCheckNotificationQuery } =
    notificationsApi;
