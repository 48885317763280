import React, { useState } from 'react';
import Modal from '../../../Modal';
import ConfOrCancelEditSelected from './ConfOrCancelEditSelected';
import AnimalsAddInfoTabs from './AnimalsAddInfoTabs';

interface IModalSelectedEdit {
    disabled: boolean;
    selectedAnimalsId: number[];
}

const ModalSelectedEdit: React.FC<IModalSelectedEdit> = ({ disabled, selectedAnimalsId }) => {
    const [showModal, setShowModal] = useState(false);
    const [toggle, setToggle] = useState<'supervise' | 'mark'>('supervise');

    // const [inputList, setInputList] = useState([1]);

    // const handleModalClose = () => {
    //     setShowModal(false);
    //     setInputList([1]);
    // };

    return (
        <Modal
            title='Изменение данные выбранным'
            textButton='Изменить данные выбранным >'
            buttonStyle='button-add'
            setShowModal={setShowModal}
            showModal={showModal}
            disabled={disabled}
            disabledStyle='button-add__disabled'
            buttonElement={
                <ConfOrCancelEditSelected
                    selectedAnimalsId={selectedAnimalsId}
                    handleModalClose={() => setShowModal(false)}
                    tabName={toggle}
                />
            }
        >
            <AnimalsAddInfoTabs toggle={toggle} setToggle={setToggle} />
        </Modal>
    );
};

export default ModalSelectedEdit;
