import React from 'react';

interface IProfileStatus {
    status: string;
}

const ProfileStatus = ({ status }: IProfileStatus) => {
    return (
        <div className='relative flex flex-col mb-6'>
            <span className='absolute top-[-7px] left-0 text-gray text-xs mb-1'>Статус</span>
            <span className={`text-sm bg-inherit py-[10px] duration-200 w-full ${status === 'enabled' ? 'text-green' : 'text-red'}`}>
                {status === 'enabled' ? 'Зарегистрирован' : 'Заблокирован'}
            </span>
        </div>
    );
};

export default ProfileStatus;
