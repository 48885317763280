/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/require-await */
import {NotificationType} from './types';

class NotificationManager {
    listNotify: NotificationType[];

    callback: (listNotify: NotificationType[]) => void;

    showCount: number;

    constructor() {
        this.listNotify = [];
        this.callback = () => { };
        this.showCount = 5;
    }

    /**
     * Добавляет нотификацию в список отображаемых нотификаци.
     *
     * На вход принимает:
     * @param {NotificationType} notify - Нотификация, которую необходимо добавить в список
     */
    async add(notify: NotificationType) {
        const findNotificationIndex = this.listNotify.findIndex((el) => el.id === notify.id);
        if (findNotificationIndex !== -1) {
            return;
        }
        this.listNotify = [notify, ...this.listNotify];
        this.callback([...this.listNotify]);
        if (notify.timeout !== 0) {
            setTimeout(() => {
                this.remove(notify.id);
            }, notify.timeout);
        }
    }

    /**
     * Удаляет нотификацию из списка отображаемых нотификаци по ее уникальному ID.
     *
     * На вход принимает:
     * @param {string} id - Уникальный ID нотификации
     */
    remove(id: string) {
        this.listNotify = this.listNotify.filter((n) => id !== n.id);
        this.callback([...this.listNotify]);
    }

    /**
     * Очищает весь список нотификаций.
     */
    clearNotifyList() {
        this.listNotify = [];
        this.callback([...this.listNotify]);
    }

    /**
     * Добавляет слушателя, следящего за изменениями списка нотификаций.
     *
     * На вход принимает:
     * @param {(listNotify : NotificationType[])=>void} callback - Колбэк, который будет вызван, при
     * каждом изменении списка нотификаций.
     */
    addChangeListener(callback: (listNotify: NotificationType[]) => void) {
        this.callback = callback;
    }
}

export default new NotificationManager();
