import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import TableTitle from '../TableUser/TableTitle';
import TableStatus from '../TableUser/TableStatus';
import TableMenu from '../TableUser/TableMenu';
import { API_KEYS, APPLICATION_STATUS, ROUTES } from '../../utils/dictionary';
import TableDelete from '../TableUser/TableDelete';
import useAppSelector from '../../hooks/redux';
import { useDeleteAnimalFromApplicationMutation } from '../../store/applications/applications.api';
import ModalConfirm from '../ModalConfirm';
import TableLink from '../TableUser/TableLink';
import useDataSourceParams from '../../hooks/useDataSourceParams';
import Pagination from '../Pagination/Pagination';
import ApplicationTableFilter from './ApplicationTable/ApplicationTableFilter';
import AnimalsStatus from '../Animals/AnimalsTableRegistry/AnimalsStatus';
import AplicationAnimalsTableSearch from './ApplicationTable/AplicationAnimalsTableSearch';

const apiKey = API_KEYS.ApplicationAnimals;

const ApplicationTable = () => {
    const applicationStatus = useAppSelector((state) => state.applications.currentApplication.application_status);
    const applicationId = useAppSelector((state) => state.applications.currentApplication.application_id);
    const [deleteAnimalFromApplication, { isLoading }] = useDeleteAnimalFromApplicationMutation();
    const filterParams = useAppSelector((state) => state.list.applicationAnimals.params);
    const {
        loading,
        params: currentParrams,
        list: animalsData,
    } = useAppSelector((state) => state.list.applicationAnimals);

    // const hiddenParam = useAppSelector((state) => state.list.applicationAnimals.hiddenParam);
    const params = useParams<'id'>();
    const hiddenParam = params ? { 'filter[application_id]': params?.id ? params.id : '' } : undefined;

    const { init } = useDataSourceParams(apiKey);

    useEffect(() => {
        init({ ...filterParams, ...hiddenParam });
    }, []);

    const deleteAnimal = async (id: number | undefined) => {
        await deleteAnimalFromApplication({ animal_id: id });
        init({ ...params, ...hiddenParam });
    };

    return (
        <div className='table-container'>
            <div className='table-wrapper'>
                <AplicationAnimalsTableSearch />
                <ApplicationTableFilter />
            </div>
            <Loader isLoading={loading}>
                <div className='max-w-full overflow-y-auto'>
                    <table className='table-content'>
                        <thead className='table-thead'>
                            <tr>
                                <TableTitle classTitle='w-[4%] pl-5' title='№' />
                                <TableTitle classTitle='w-[8%]' title='№ УНСМ' />
                                <TableTitle
                                    classTitle='w-[8%]'
                                    title='Идент. номер'
                                    filterKey='last_name'
                                    apiKey={apiKey}
                                />
                                <TableTitle classTitle='w-[10%]' title='Инв. №' filterKey='region' apiKey={apiKey} />
                                <TableTitle
                                    classTitle='w-[10%]'
                                    title='Вид животного'
                                    filterKey='date_reg'
                                    apiKey={apiKey}
                                />
                                <TableTitle classTitle='w-[8%]' title='Пол' filterKey='date_block' apiKey={apiKey} />
                                <TableTitle classTitle='w-[8%]' title='Д. рожд.' />
                                <TableTitle classTitle='w-[8%]' title='Порода' filterKey='hozs' apiKey={apiKey} />
                                <TableTitle
                                    classTitle='w-[8%]'
                                    title='Дата регистр.'
                                    filterKey='status'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[8%]'
                                    title='Статус карточки'
                                    filterKey='status'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[8%]'
                                    title='Статус записи'
                                    filterKey='status'
                                    apiKey={apiKey}
                                />
                                <th scope='col' className='w-[4%] font-normal px-2 py-0 '>
                                    <span className='hidden'>Операции</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='table-tbody'>
                            {animalsData?.map((animal, animal_id) => {
                                return (
                                    <tr key={animal.main?.animal_id} className='table-tbody-tr'>
                                        <th scope='row' className='table-tbody-th'>
                                            {(currentParrams.cur_page - 1) * currentParrams.per_page + animal_id + 1}
                                        </th>
                                        <td className='table-tbody-td'>{animal.main?.animal_rshn}</td>
                                        <td className='table-tbody-td'>{animal.main?.animal_guid}</td>
                                        <td className='table-tbody-td'>{animal.main?.animal_inv}</td>
                                        <td className='table-tbody-td'>{animal.main?.animal_specie_name}</td>
                                        <td className='table-tbody-td'>{animal.main?.animal_gender_name}</td>
                                        <td className='table-tbody-td'>{animal.main?.animal_date_birth}</td>
                                        <td className='table-tbody-td'>{animal.main?.animal_breed_name}</td>
                                        <td className='table-tbody-td'>{animal.main?.animal_date_reg_herriot}</td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td className='px-1 py-4'>
                                            <TableStatus status={animal.main?.animal_status_card === 'enabled'} />
                                        </td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td className='table-tbody-td'>
                                            <AnimalsStatus
                                                status={animal.main?.animal_status_record}
                                                error={animal.main?.text_error}
                                            />
                                        </td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td>
                                            <div className='flex pr-2 justify-end items-center relative'>
                                                <TableMenu>
                                                    {applicationStatus === APPLICATION_STATUS.Created && (
                                                        <ModalConfirm
                                                            textHeader='Подтвердите удаление животного из заявки'
                                                            type='danger'
                                                            onClick={() => deleteAnimal(animal.main?.animal_id)}
                                                            textButtonConf='Подтверждаю'
                                                            isLoading={isLoading}
                                                        >
                                                            <TableDelete
                                                                textDelete='Удалить из заявки'
                                                                colorLi='text-red'
                                                            />
                                                        </ModalConfirm>
                                                    )}
                                                    <TableLink
                                                        title='Паспорт'
                                                        link={`${ROUTES.Animal}${animal.main?.animal_id}?app=${applicationId}`}
                                                    />
                                                </TableMenu>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Loader>
            <div className='px-5'>{animalsData && <Pagination apiKey={apiKey} />}</div>
        </div>
    );
};

export default ApplicationTable;
