import React, { useEffect, useState } from 'react';
import Pagination from '../../Pagination/Pagination';
import TableTitle from '../../TableUser/TableTitle';
import TableMenu from '../../TableUser/TableMenu';
import Loader from '../../Loader';
import { ANIMAL_STATUS, API_KEYS, ROUTES } from '../../../utils/dictionary';
import useAppSelector from '../../../hooks/redux';
import TableLink from '../../TableUser/TableLink';
import AnimalsAddApplication from './AnimalsAddApplication';
import AnimalsCheckbox from './AnimalsCheckbox';
import AnimalsTableFilter from './AnimalsTableFilter';
import AnimalsStatus from './AnimalsStatus';
import { IAnimal } from '../../../models/base/animals';
import TableStatus from '../../TableUser/TableStatus';
import AnimalsTableSearch from '../AnimalsTableSearch';

const apiKey = API_KEYS.Animals;

const TableAnimals = () => {
    const { loading, params: currentParrams, list: animalsData } = useAppSelector((state) => state.list.animals);
    const [selectedAnimalsId, setSelectedAnimalsId] = useState<Array<number>>([]);
    const [filtredArray, setfiltredArray] = useState<IAnimal[]>([]);

    useEffect(() => {
        const filtredAnimalsArray = animalsData?.filter(
            (item) =>
                item.main?.animal_id &&
                item.main.animal_registration_available &&
                (item.main.animal_status_record === ANIMAL_STATUS.Added ||
                    item.main.animal_status_record === ANIMAL_STATUS.Rejected),
        );
        setfiltredArray(filtredAnimalsArray);
    }, [animalsData]);

    const handlerSelectedRow = (animal: any) => {
        let newArray = [...selectedAnimalsId];
        if (newArray.includes(animal.main.animal_id)) {
            newArray = selectedAnimalsId.filter((item) => item !== animal.main.animal_id);
        } else if (animal.main.animal_registration_available) {
            if (
                animal.main.animal_status_record === ANIMAL_STATUS.Added ||
                animal.main.animal_status_record === ANIMAL_STATUS.Rejected
            ) {
                newArray.push(animal.main.animal_id);
            }
        }
        setSelectedAnimalsId(newArray);
    };

    const handlerSelectedAllRows = () => {
        const tempArray: number[] = [];
        if (selectedAnimalsId.length !== filtredArray.length) {
            filtredArray?.forEach((item) => {
                item.main?.animal_id && tempArray.push(item.main?.animal_id);
            });
            setSelectedAnimalsId(tempArray);
        } else {
            setSelectedAnimalsId([]);
        }
    };

    return (
        <div className='table-container'>
            <div className='table-wrapper'>
                <AnimalsTableSearch />
                <AnimalsTableFilter />
            </div>
            <Loader isLoading={loading}>
                <div className='min-w-full overflow-y-auto'>
                    <table className='table-content'>
                        <thead className='table-thead'>
                            <tr>
                                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                <th className='px-2 w-[5%]' onClick={() => handlerSelectedAllRows()}>
                                    <div className='flex items-center justify-center'>
                                        <AnimalsCheckbox
                                            isActive={
                                                !!selectedAnimalsId.length &&
                                                selectedAnimalsId.length === filtredArray.length
                                            }
                                            enabled
                                        />
                                    </div>
                                </th>
                                <TableTitle classTitle='w-[4%] pl-5' title='№' />
                                <TableTitle classTitle='w-[8%]' title='№ УНСМ' />
                                <TableTitle classTitle='w-[8%]' title='№ Хорриот' />
                                <TableTitle
                                    classTitle='w-[10%]'
                                    title='Инв. №'
                                    filterKey='animal_inv'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[14%]'
                                    title='Вид животного'
                                    filterKey='animal_specie'
                                    apiKey={apiKey}
                                />
                                <TableTitle classTitle='w-[5%]' title='Пол' filterKey='animal_sex' apiKey={apiKey} />
                                <TableTitle
                                    classTitle='w-[7%]'
                                    title='Д. рожд.'
                                    filterKey='animal_date_birth'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[8%]'
                                    title='Порода'
                                    filterKey='animal_breed'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[7%]'
                                    title='Д. рег. СВР'
                                    filterKey='animal_date_create_record_svr'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[5%]'
                                    title='Д. рег. Хорриот'
                                    filterKey='animal_date_create_record_herriot'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[8%]'
                                    title='Статус карточки'
                                    filterKey='animal_status'
                                    apiKey={apiKey}
                                />
                                <TableTitle
                                    classTitle='w-[7%]'
                                    title='Статус записи'
                                    filterKey='application_animal_status'
                                    apiKey={apiKey}
                                />
                                <th scope='col' className='w-[4%] font-normal px-2 py-0'>
                                    <span className='hidden'>Операции</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='table-tbody'>
                            {animalsData?.map((animal: any, index: number) => {
                                const isAvailableToApplication =
                                    animal.main.animal_registration_available &&
                                    (animal.main.animal_status_record === ANIMAL_STATUS.Added ||
                                        animal.main.animal_status_record === ANIMAL_STATUS.Rejected);

                                return (
                                    <tr
                                        key={animal.main.animal_id}
                                        className={
                                            selectedAnimalsId.includes(animal.main.animal_id)
                                                ? 'table-tbody-tr-active'
                                                : 'table-tbody-tr'
                                        }
                                    >
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td
                                            className='table-tbody-td cursor-pointer'
                                            onClick={() => handlerSelectedRow(animal)}
                                        >
                                            <AnimalsCheckbox
                                                isActive={selectedAnimalsId.includes(animal.main.animal_id)}
                                                enabled={isAvailableToApplication}
                                            />
                                        </td>
                                        <th scope='row' className='table-tbody-th'>
                                            {(currentParrams.cur_page - 1) * currentParrams.per_page + index + 1}
                                        </th>
                                        <td className={`table-tbody-td`}>{animal.main.animal_rshn}</td>
                                        <td className='table-tbody-td'>
                                            <div className='flex justify-center'>
                                                {animal.main.animal_horriot_number}
                                            </div>
                                        </td>
                                        <td className='table-tbody-td'>{animal.main.animal_inv}</td>
                                        <td className='table-tbody-td'>{animal.main.animal_specie_name}</td>
                                        <td className='table-tbody-td'>{animal.main.animal_gender_name}</td>
                                        <td className='table-tbody-td'>{animal.main.animal_date_birth}</td>
                                        <td className='table-tbody-td'>{animal.main.animal_breed_name}</td>
                                        <td className='table-tbody-td'>{animal.main.animal_date_reg}</td>
                                        <td className='table-tbody-td'>{animal.main.animal_date_reg_herriot}</td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td className='table-tbody-td'>
                                            <TableStatus status={animal.main?.animal_status_card === 'enabled'} />
                                        </td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td className='px-1 py-4'>
                                            <AnimalsStatus
                                                status={animal.main.animal_status_record}
                                                error={animal.main?.text_error}
                                            />
                                        </td>
                                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <td>
                                            <div className='flex pr-2 justify-end items-center relative'>
                                                <TableMenu>
                                                    <TableLink
                                                        title='Паспорт'
                                                        link={`${ROUTES.Animal}${animal.main.animal_id}`}
                                                    />
                                                </TableMenu>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: '#FFFFFF',
                        borderRadius: '0 0 0.5rem 0.5rem',
                    }}
                >
                    <AnimalsAddApplication
                        selectedAnimalsId={selectedAnimalsId}
                        setSelectedAnimalsId={setSelectedAnimalsId}
                    />
                    <div className='px-5'>{animalsData && <Pagination apiKey={apiKey} countListPagination />}</div>
                </div>
            </Loader>
        </div>
    );
};

export default TableAnimals;
