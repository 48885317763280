import React from 'react';
import useSetStateInputsValue from '../../hooks/useSetStateInputsValue';
import {emojiRegex} from "../../utils/dictionary";

interface IInputDefault {
    storeKey: string;
    placeholder?: string;
    className?: string;
    label?: string;
    type: string;
    disabled?: boolean;
    value?: string | number | number[];
    error?: string;
    required?: boolean;
    title?: string;
    isTouched?: boolean;
    setIsTouched?: (flag: boolean) => void;
}

const InputDefault = ({storeKey, label, className, placeholder, type, disabled, value, required, error, title, isTouched, setIsTouched}: IInputDefault) => {

    const {internalValue, setInternalValue} = useSetStateInputsValue(storeKey, value);

    const handleBlur = () => {
        if (setIsTouched) {
            setIsTouched(true);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(e.target.value.replace(emojiRegex, ''));
        if (!isTouched && setIsTouched) {
            setIsTouched(true);
        }
    }

    return (
        <>
            {title && (
                <span className='block text-sm text-gray mb-3'>
                    {title}
                </span>
            )}
            <div className='flex flex-col mb-6 relative w-full'>
                {label && (
                    <label
                        className='input-label'
                        htmlFor={storeKey}
                    >
                        {label} {required && <span className='text-sm text-red'>&nbsp;*</span>}
                    </label>
                )}
                <input
                    className={`input-default rounded-lg text-black font-bold outline-0 py-[8px] px-[12px] border-borderGray border-[2px] w-full ${!disabled && 'hover:border-blue'} focus:border-blue placeholder-grayLight text-sm ${disabled && 'cursor-default text-gray'
                        } ${className}`}
                    type={type}
                    id={storeKey}
                    onBlur={handleBlur}
                    name={storeKey}
                    placeholder={placeholder}
                    value={internalValue ?? ''}
                    onChange={handleChange}
                    disabled={disabled}
                />
                {/* Отображение валидации */}
                {error && <p className='absolute text-xs text-red top-[40px]'>{error}</p>}
            </div></>
    );
};

export default InputDefault;
