import React from 'react';

interface IAcceptedSvg {
    color: string;
    classSvg: string;
}

const AcceptedSvg = ({color, classSvg}: IAcceptedSvg) => {
    return (
        <svg className={classSvg} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M4.35817 19.9122C3.30756 18.8975 2.46955 17.6837 1.89305 16.3416C1.31656 14.9996 1.01311 13.5562 1.00042 12.0956C0.987723 10.635 1.26604 9.18654 1.81913 7.83468C2.37222 6.48282 3.18901 5.25465 4.22183 4.22183C5.25465 3.18901 6.48282 2.37222 7.83468 1.81913C9.18654 1.26604 10.635 0.987723 12.0956 1.00042C13.5562 1.01311 14.9996 1.31656 16.3416 1.89305C17.6837 2.46955 18.8975 3.30756 19.9122 4.35817C21.9159 6.43279 23.0246 9.21142 22.9996 12.0956C22.9745 14.9797 21.8177 17.7387 19.7782 19.7782C17.7387 21.8177 14.9797 22.9745 12.0956 22.9996C9.21142 23.0246 6.43279 21.9159 4.35817 19.9122ZM18.3612 18.3612C20.0124 16.7099 20.9401 14.4704 20.9401 12.1352C20.9401 9.79997 20.0124 7.56041 18.3612 5.90917C16.7099 4.25793 14.4704 3.33028 12.1352 3.33028C9.79997 3.33028 7.56041 4.25793 5.90917 5.90917C4.25793 7.56041 3.33028 9.79997 3.33028 12.1352C3.33028 14.4704 4.25793 16.7099 5.90917 18.3612C7.56041 20.0124 9.79997 20.9401 12.1352 20.9401C14.4704 20.9401 16.7099 20.0124 18.3612 18.3612Z'
                fill={color}
            />
            <path
                d='M10.0312 16C9.96094 15.9917 9.89453 15.9751 9.83203 15.9503C9.76953 15.9171 9.71484 15.8715 9.66797 15.8135L6.23438 12.1837C6.16406 12.134 6.10547 12.0677 6.05859 11.9848C6.01953 11.9019 6 11.8149 6 11.7238C6 11.5746 6.05078 11.4461 6.15234 11.3384C6.25391 11.2307 6.375 11.1768 6.51562 11.1768C6.60938 11.1768 6.69531 11.2017 6.77344 11.2514C6.85938 11.3011 6.92188 11.3674 6.96094 11.4503L10.0312 14.6823L17.2148 7.08702C17.2539 7.06215 17.2969 7.04144 17.3438 7.02486C17.3906 7.00829 17.4375 7 17.4844 7C17.6328 7 17.7539 7.05387 17.8477 7.1616C17.9492 7.26934 18 7.39779 18 7.54696C18 7.59669 17.9922 7.64641 17.9766 7.69613C17.9688 7.73757 17.957 7.77901 17.9414 7.82044L10.3945 15.8135C10.3477 15.8715 10.293 15.9171 10.2305 15.9503C10.168 15.9751 10.1016 15.9917 10.0312 16Z'
                fill={color}
                stroke={color}
            />
        </svg>
    );
};

export default AcceptedSvg;
