import React from 'react'
import useAppSelector from '../hooks/redux';

interface IDictionarysValue {
    idArray: number[],
    keyDictionary: string,
    keyValue: string
}

const DictionarysValue = ({idArray, keyDictionary, keyValue}: IDictionarysValue) => {
    const dictionary: any = useAppSelector((state) => state.dictionary.current);
    let arr: any = []
    if (dictionary && dictionary[keyDictionary]) {
        arr = idArray.map((item) => {
            return <p className='text-sm text-black' key={item}>{dictionary[keyDictionary][item]?.[keyValue] ? dictionary[keyDictionary][item][keyValue] : <>нет значения</>}</p>
        })
    } else {
        return <>нет справочника</>
    }
    return (
        <>
            {arr.map((item: any) => item)}
        </>
    )
}

export default DictionarysValue