import React from 'react';
import useAppSelector from '../../../../hooks/redux';
import {
    useLazyAnimalsMarkEditGroupQuery,
    useLazyAnimalsSuperviseObjectEditGroupQuery,
} from '../../../../store/animals/animals.api';
import { NotificationManager } from '../../../notification';
import { NOTIF_TIMEOUT } from '../../../../utils/dictionary';

interface IConfOrCalcelEditSelected {
    handleModalClose: () => void;
    selectedAnimalsId: number[];
    tabName: string;
}

const ConfOrCancelEditSelected: React.FC<IConfOrCalcelEditSelected> = ({
    handleModalClose,
    selectedAnimalsId,
    tabName,
}) => {
    const inputs = useAppSelector((state) => state.inputs);

    const [getAnimalGroup, { isLoading }] = useLazyAnimalsMarkEditGroupQuery();
    const [getSuperviseAnimalGroup] = useLazyAnimalsSuperviseObjectEditGroupQuery();

    const handleMarkingConfirm = () => {
        const groupedData: any = {};

        Object.entries(inputs).forEach(([key, value]) => {
            if (key.includes('__all__')) {
                const [field, , id] = key.split('__'); // Разделяем ключ на три части по двойному разделителю '__'
                if (!groupedData[id]) {
                    // Если объект для этого идентификатора еще не создан
                    groupedData[id] = {}; // Создаем новый объект для этого идентификатора
                }
                groupedData[id][field] = value; // Добавляем поле и значение в объект для этого идентификатора
            }
        });

        const formattedData = Object.keys(groupedData)
            .map((id) => {
                const data = groupedData[id];
                const result: { [key: string]: any } = {};
                Object.entries(data).forEach(([key, value]) => {
                    if (value) {
                        result[key] = value;
                    }
                });
                return result;
            })
            .filter(
                (item) =>
                    Object.keys(item).length > 0 &&
                    item.mark_type_id &&
                    (item.mark_status || item.mark_tool_type || item.mark_tool_location || item.description),
            );

        if (formattedData.length > 0) {
            getAnimalGroup({
                animal_id: selectedAnimalsId,
                updates: formattedData,
            });
            // handleModalClose();
        } else {
            NotificationManager.add({
                id: `error`,
                type: 'error',
                timeout: NOTIF_TIMEOUT,
                header: 'Ошибка',
                text: '"Вид номера" обязательное поле и должно быть выбрано хотя бы 1 дополнительное поле',
            });
        }
    };

    const handelSuperviseConfirm = () => {
        const supervisedData: any = {};
        Object.entries(inputs).forEach(([key, value]) => {
            if (key.includes('select_supervised') && value) {
                const newKey = key.split('__')[0];
                supervisedData[newKey] = value;
            }
        });

        if (Object.keys(supervisedData).length > 0) {
            getSuperviseAnimalGroup({
                updates: supervisedData,
                animal_id: selectedAnimalsId,
            });
            // handleModalClose();
        } else {
            NotificationManager.add({
                id: `error`,
                type: 'error',
                timeout: NOTIF_TIMEOUT,
                header: 'Ошибка',
                text: 'Должно быть выбрано хотя бы 1 поле',
            });
        }
    };

    const handleConfirm = () => {
        if (tabName === 'supervise') {
            handelSuperviseConfirm();
        } else if (tabName === 'mark') {
            handleMarkingConfirm();
        }
    };

    return (
        <div className='flex items-center justify-between pb-5 px-5'>
            <button
                className='text-sm text-blue cursor-pointer py-2 hover:text-blueHover'
                type='button'
                onClick={handleModalClose}
            >
                Отмена
            </button>
            <button
                className='bg-blue py-2 px-6 text-white rounded-lg text-sm cursor-pointer hover:bg-blueHover'
                type='button'
                onClick={handleConfirm}
                disabled={isLoading}
            >
                Отправить
            </button>
        </div>
    );
};

export default ConfOrCancelEditSelected;
