import React, {useRef, useState} from 'react';
import {ReactComponent as IconSearch} from '../../image/svg/search.svg';
import Cross from '../../image/svg/Cross';
import {ReactComponent as IconCheck} from '../../image/svg/check.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {ReactComponent as Arrow} from '../../image/svg/arrowBlue.svg';
import {
    IChangeParamsFunc,
    IDirectoriesDistrict,
    IRolesInfo,
    IUserCompany,
    IUserDistrict,
    IUserRole,
} from '../../models/models';

interface IMultiSelect {
    placeholder: string;
    initValue: number[];
    list: IDirectoriesDistrict[] | IRolesInfo[] | any[] | undefined;
    usersDictionaryList:
    | {
        [key: number]: IUserCompany | IUserRole | IUserDistrict | any;
    }
    | undefined;
    dictionaryKey: string;
    dictionaryValue: string;
    callback: IChangeParamsFunc;
    setSearch?: React.Dispatch<React.SetStateAction<string>>;
    searchValue?: string;
    dictionaryTwoKey?: string;
    dictionaryThreeKey?: string;
}

const MultiSelect: React.FC<IMultiSelect> = ({
    placeholder,
    initValue,
    list,
    usersDictionaryList,
    dictionaryKey,
    dictionaryValue,
    callback,
    setSearch,
    searchValue,
    dictionaryTwoKey,
    dictionaryThreeKey,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const onSelected = (id: number) => {
        setIsOpen(true);
        if (initValue.includes(id)) {
            callback(id, false);
        } else {
            callback(id, true);
        }
    };
    const deleteElement = (id: number) => {
        callback(id, false);
    };
    const closeHandler = () => setIsOpen(false);
    useOnClickOutside(ref, closeHandler);

    const handlerAnyKeys = (item: any) => {
        if (dictionaryTwoKey && item?.[dictionaryTwoKey]) {
            if (dictionaryThreeKey && item?.[dictionaryThreeKey]) {
                return (
                    <span className='text-[12px] ml-1.5'>
                        ({item?.[dictionaryTwoKey]}, {item?.[dictionaryThreeKey]})
                    </span>
                );
            }
            return <span className='text-[12px] ml-1.5'>({item?.[dictionaryTwoKey]})</span>;
        }
        if (dictionaryThreeKey && item?.[dictionaryThreeKey]) {
            return <span className='text-[12px] ml-1.5'>({item?.[dictionaryThreeKey]})</span>;
        }
        return <></>;
    };

    return (
        <div ref={ref} className='relative w-full text-sm mb-6'>
            {placeholder && <span className='input-label z-10'>{placeholder}</span>}
            <div
                role='presentation'
                onClick={() => setIsOpen(!isOpen)}
                className={`bg-white ${isOpen ? 'rounded-t-lg border-blue' : 'rounded-lg border-borderGray'
                    } hover:border-blue min-h-[40px] border-[2px] cursor-pointer w-full relative text-sm flex flex-wrap gap-1 py-1 pl-2 pr-8`}
            >
                {initValue.length !== 0 ? (
                    initValue.map((id: number) => (
                        <div
                            className='relative rounded-lg w-fit pt-1 px-2 border-1 border-gray flex items-center gap-1'
                            key={id}
                        >
                            {usersDictionaryList?.[id]?.[dictionaryValue]}
                            <div
                                role='presentation'
                                onClick={() => deleteElement(id)}
                                onMouseDown={(e) => e.preventDefault()}
                                className='cursor-pointer'
                            >
                                <Cross classSvg='icon-cross-hover' width='12' height='12' color='#CBD5E0' />
                            </div>
                        </div>
                    ))
                ) : (
                    <span className='px-2 pt-[5px]'>Ничего не выбрано</span>
                )}
                <div className='absolute cursor-pointer top-[7px] right-[11px]'>
                    <Arrow className={`w-[20px] h-[20px] duration-200 ${isOpen ? 'rotate-90' : 'rotate-[-90deg]'}`} />
                </div>
            </div>
            {isOpen ? (
                <div className='multi-list-container'>
                    <ul className='w-full relative'>
                        {setSearch && (
                            <div className='multi-search-wrapper'>
                                <IconSearch />
                                <input
                                    type='text'
                                    placeholder='Поиск'
                                    value={searchValue}
                                    onChange={(e) => setSearch(e.target.value.trimStart())}
                                    className='bg-white text-sm flex-1 outline-0 h-full py-3'
                                />
                                {inputValue?.length !== 0 && (
                                    <Cross width='12' height='12' color='#CBD5E0' onClick={() => setInputValue('')} />
                                )}
                            </div>
                        )}
                        {/* <div className='flex items-center justify-between mb-2'> */}
                        {/* <span className='text-xs text-grayDisabled'>Всего выбрано: {initValue?.length}</span> */}
                        {/* {selected?.length > 2 && (
                                <span
                                    role='presentation'
                                    className='text-xs text-grayDisabled cursor-pointer'
                                    onClick={() => setSelected([])}
                                >
                                    Снять выбор
                                </span>
                            )} */}
                        {/* </div> */}
                        <div className={`overflow-y-auto max-h-[230px] px-2 ${setSearch ? 'pt-[45px]' : 'pt-2'} pb-2`}>
                            {list?.length ? (
                                list.map((item) => {
                                    return (
                                        <li
                                            key={item?.[dictionaryKey]}
                                            role='presentation'
                                            onMouseDown={(e) => e.preventDefault()}
                                            onClick={() => onSelected(item?.[dictionaryKey])}
                                            className={`min-h-[32px] px-2 flex items-center text-black text-sm cursor-pointer 
                                     hover:bg-ghostBlue w-full ${initValue.includes(item?.[dictionaryKey]) ? 'bg-ghostBlue' : ''
                                                }`}
                                        >
                                            {initValue.includes(item?.[dictionaryKey]) ? (
                                                <div className='flex items-center justify-center w-[24px] h-[24px] min-w-[24px] min-h-[24px] bg-blue rounded mr-2'>
                                                    <IconCheck />
                                                </div>
                                            ) : (
                                                <div className='w-[24px] h-[24px] min-w-[24px] min-h-[24px] border-[2px] rounded mr-2 border-blue' />
                                            )}
                                            {item?.[dictionaryValue]} {handlerAnyKeys(item)}
                                        </li>
                                    );
                                })
                            ) : (
                                <li className='h-[32px] flex items-center text-grayDisabled justify-center w-full'>
                                    Ничего не найдено
                                </li>
                            )}
                        </div>
                    </ul>
                </div>
            ) : null}
        </div>
    );
};

export default MultiSelect;
