import {createSlice} from "@reduxjs/toolkit";
import {INotificationData} from "../../models/base/notifications";
import {notificationsApi} from "./notifications.api";
import {INotification} from "../../models/models";

interface NotificationsState {
    data: INotificationData[]
    notifications: INotification
}

const initialState: NotificationsState = {
    data: [{
        notification_id: 0,
        user_id: 0,
        notification_type: '',
        notification_title: '',
        notification_text: '',
        notification_date_add: '',
        notification_date_view: '',
    }],
    notifications: {
        count_new: 0,
        count_total: 0
    }
}

export const NotificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotificationsInfo(state, action) {
            state.notifications = action.payload.notifications
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(notificationsApi.endpoints.getNotifications.matchFulfilled, (state, action) => {
                state.data = action.payload.data
            })
    }
})

export const notificationsActions = NotificationsSlice.actions
export const notificationsReducer = NotificationsSlice.reducer