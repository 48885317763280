import React, {useState} from 'react';
import {ROLES} from '../../utils/dictionary';
import InputPassword from '../Inputs/InputPassword';
import useAppSelector from "../../hooks/redux";
import {useLazyChangeUserPasswordQuery, useLazySendNewUserPasswordQuery} from "../../store/users/users.api";
import {IChangeUserPassword} from "../../models/base/user";
import validateCheck from "../../utils/validateCheck";
import {adminChangePass, changePasswordSchema} from "../../utils/validation";

const ProfileChangePassword = () => {
    const authId = useAppSelector((state) => state.auth.user.user_id);
    const currentUserId = useAppSelector((state) => state.users.currentUser.user_id);
    const currentUserRoles = useAppSelector((state) => state.users.currentUser.user_roles_list);
    const [isOpen, setIsOpen] = useState(false);
    const heightElement = isOpen && currentUserRoles.includes(ROLES.Admin) ? 'h-[140px]' : 'h-[200px]';

    const handlerChangePassword = (key: string, value: string) => {
        setPasswordsObj((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const [changePassword] = useLazyChangeUserPasswordQuery();
    const [sendNewPassword, {isLoading}] = useLazySendNewUserPasswordQuery();

    const [passwordsObj, setPasswordsObj] = useState<IChangeUserPassword>({
        user_id: currentUserId,
        current_password: '',
        password: '',
        password_confirmation: '',
    });

    const [errors, setErrors] = useState<{[x: string]: string}>();

    const handlerSavePassword = async () => {
        if (isOpen) {
            const errors = await validateCheck({
                validateObj: passwordsObj,
                schema: currentUserRoles.includes(ROLES.Admin) ? adminChangePass : changePasswordSchema,
            });
            if (errors) {
                setErrors(errors);
            } else {
                setIsOpen(false);
                setErrors({});
                changePassword(passwordsObj);
                setPasswordsObj({
                    user_id: currentUserId,
                    current_password: '',
                    password: '',
                    password_confirmation: '',
                })
            }
        } else {
            setIsOpen(!isOpen);
        }
    };

    const handlerSendPassword = async () => {
        sendNewPassword(currentUserId)
    }

    return (
        <>
            {currentUserRoles.includes(ROLES.Admin) || authId === currentUserId ? (
                <div className='flex justify-center mb-6'>
                    <button
                        onClick={handlerSendPassword}
                        className='text-blue text-sm hover:text-blueHover'
                        type='button'
                    >
                        Выслать реквизиты на почту
                    </button>
                </div>
            ) : (
                <></>
            )}
            <div className={`${isOpen ? heightElement : 'invisible h-0'} transition-all duration-500 overflow-hidden`}>
                <div className={`pt-2`}>
                    {currentUserRoles.includes(ROLES.Admin) ? (
                        ''
                    ) : (
                        <InputPassword
                            value={passwordsObj.current_password}
                            label='Старый пароль'
                            name='current_password'
                            id='current_password'
                            placeholder='Старый пароль'
                            onChange={(e) => handlerChangePassword('current_password', e.target.value)}
                            error={errors?.current_password}
                        />
                    )}
                    <InputPassword
                        value={passwordsObj.password}
                        label='Новый пароль'
                        name='password'
                        id='password'
                        placeholder='Новый пароль'
                        onChange={(e) => handlerChangePassword('password', e.target.value)}
                        error={errors?.password}
                        autoComplete='new-password'
                    />
                    <InputPassword
                        value={passwordsObj.password_confirmation}
                        label='Повторите пароль'
                        name='password_confirmation'
                        id='password_confirmation'
                        placeholder='Повторите новый пароль'
                        onChange={(e) => handlerChangePassword('password_confirmation', e.target.value)}
                        error={errors?.password_confirmation}
                    />
                </div>
            </div>
            <div className='flex justify-center'>
                {!passwordsObj.password && !passwordsObj.password_confirmation && !passwordsObj.current_password ? (
                    <button
                        className='button-green w-full justify-center'
                        type='button'
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {isOpen ? 'Закрыть' : 'Изменить пароль'}
                    </button>
                ) : (
                    <button
                        className='button-green w-full justify-center'
                        type='button'
                        onClick={handlerSavePassword}
                        disabled={isLoading}
                    >
                        {isOpen ? 'Сохранить' : 'Изменить пароль'}
                    </button>
                )}
            </div>

        </>
    );
};

export default ProfileChangePassword;
