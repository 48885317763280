import React from 'react';
import Spinner from './Loaders/Spinner';

interface ILoader {
    isLoading: boolean;
    children: React.ReactNode;
}

const Loader = ({ isLoading, children }: ILoader) => {
    return (
        <>
            {isLoading ? (
                <div className='relative min-h-28'>
                    <Spinner />
                    <div className='opacity-40' style={{ filter: 'blur(3px)' }}>
                        {children}
                    </div>
                </div>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

export default Loader;
