import React from 'react'
import {Link} from "react-router-dom";
import {ROUTES} from "../utils/dictionary";
import { ReactComponent as WarningSvg } from "../image/svg/warning-big.svg";

const PageNotFound = () => {
    return (
        <div className='flex m-[0 auto] mt-12 flex-col items-center justify-center'>
            <div className='flex flex-col items-center justify-center'>
                <WarningSvg className='mb-6'/>
                <span className='text-gray text-sm mb-4'>Ошибка 404</span>
                <h2 className='text-xl text-black mb-2'>Страница не найдена</h2>
                <span className='text-sm text-gray mb-6'>По данному адресу ничего не найдено. Попробуйте изменить запрос</span>
                <Link className='w-full h-[40px] rounded-lg flex items-center justify-center bg-blue text-white text-sm' to={ROUTES.Animals}>Перейти на главную</Link>
            </div>
        </div>
    )
}

export default PageNotFound