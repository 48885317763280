import React from 'react';
import { Drawer } from 'antd';
import FilterSvg from '../image/svg/FilterSvg';
import useAppSelector from '../hooks/redux';
import { ApiKeysType } from '../models/models';

interface IDrawer {
    open: boolean;
    setOpen: (flag: boolean) => void;
    storeKey: ApiKeysType;
    children?: React.ReactNode;
}
const AntDrawer = ({ children, storeKey, open, setOpen }: IDrawer) => {
    const filters = useAppSelector((state) => state.list[storeKey].filters);
    const thereIsFilters = !!Object.keys(filters).length;

    // const showDrawer = () => {
    //     setOpen(true);
    // };

    return (
        <>
            <button className='relative' aria-label='filter' type='button' onClick={() => setOpen(true)}>
                {/* Элемент если в фильтрах что-то задано */}
                {thereIsFilters && <span className='absolute top-[2px] w-[10px] h-[10px] rounded-full bg-[#ED8936]' />}
                <FilterSvg classSvg='filter-svg' />
            </button>
            <Drawer closable={false} onClose={() => setOpen(false)} open={open}>
                <h2 className='text-2xl pb-4'>Фильтры</h2>
                <div className=''>{children}</div>
            </Drawer>
        </>
    );
};
export default AntDrawer;
