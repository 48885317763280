import React from 'react';
import { ApplicationAnimalStatusObject, UserStatusObject } from '../../../utils/dictionary';
// import ReactDatePicker from '../../Inputs/RangePicker';
import AnimalBreedsFilterSelect from '../../FilterBreedsSelect';
import AnimalSpeciesFilterSelect from '../../AnimalSpeciesFilterSelect';
import AnimalGenderFilterSelect from '../../AnimalGenderFilterSelect';
import FilterSelect from '../../Inputs/FilterSelect';
import ReactRangePickerNew from '../../Inputs/RangePickerNew';
import { ListsState } from '../../../store/lists/lists.slice';
import useTableFilter from '../../../hooks/useTableUserFilter';

interface ITableFilter {
    setOpen: (flag: boolean) => void;
}
const storeKey: keyof ListsState = 'animals';

const AnimalsFilter = ({ setOpen }: ITableFilter) => {
    const { handlerFilterReset, handlerFilterApply } = useTableFilter(storeKey);

    return (
        <div className='flex flex-col justify-between h-full'>
            <div>
                <FilterSelect
                    placeholder='Статус записи'
                    storeKey={storeKey}
                    listObject={ApplicationAnimalStatusObject}
                    filterKey={'application_animal_status'}
                />
                <AnimalGenderFilterSelect storeKey={storeKey} />
                <AnimalSpeciesFilterSelect storeKey={storeKey} />
                <h3 className='text-sm text-black mb-2'>Дата рождения</h3>
                <div className='flex gap-4'>
                    <ReactRangePickerNew
                        minKey='animal_date_birth_min'
                        maxKey='animal_date_birth_max'
                        storeKey={storeKey}
                        placeholder={['С', 'По']}
                    />
                </div>
                <AnimalBreedsFilterSelect storeKey={storeKey} />
                <FilterSelect
                    placeholder='Статус карточки'
                    storeKey={storeKey}
                    listObject={UserStatusObject}
                    filterKey={'animal_status'}
                />
                <h3 className='text-sm text-black mb-2'>Дата регистрации СВР</h3>
                <div className='flex gap-4'>
                    <ReactRangePickerNew
                        minKey='animal_date_create_record_svr_min'
                        maxKey='animal_date_create_record_svr_max'
                        storeKey={storeKey}
                        placeholder={['С', 'По']}
                    />
                </div>
                <h3 className='text-sm text-black mb-2'>Дата регистрации Хорриот</h3>
                <div className='flex gap-4'>
                    <ReactRangePickerNew
                        minKey='animal_date_create_record_herriot_min'
                        maxKey='animal_date_create_record_herriot_max'
                        storeKey={storeKey}
                        placeholder={['С', 'По']}
                    />
                </div>
            </div>
            <div>
                <button
                    className='text-sm w-full px-4 py-2 bg-blue rounded-lg text-white mb-4 hover:bg-blueHover'
                    type='button'
                    onClick={() => {
                        handlerFilterApply();
                        setOpen(false);
                    }}
                >
                    Применить
                </button>
                <button
                    className='text-sm text-white w-full px-4 py-2 bg-grayDisabled rounded-lg hover:bg-gray'
                    type='button'
                    onClick={handlerFilterReset}
                >
                    Очистить
                </button>
            </div>
        </div>
    );
};

export default AnimalsFilter;
