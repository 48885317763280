import React, { useState } from 'react';
import Modal from '../../../Modal';
import ConfOrCancelEditFiltered from './ConfOrCalcelEditFiltered';
import AnimalsAddInfoTabs from './AnimalsAddInfoTabs';

const ModalFilteredEdit = () => {
    const [showModal, setShowModal] = useState(false);
    const [toggle, setToggle] = useState<'supervise' | 'mark'>('supervise');
    // const [inputList, setInputList] = useState([1]);

    // const handleModalClose = () => {
    // setShowModal(false);
    //     setInputList([1]);
    // };

    return (
        <Modal
            title='Изменение данных отфильтрованным животным'
            textButton='Изменить данные отфильтрованным >'
            buttonStyle='button-add'
            setShowModal={setShowModal}
            showModal={showModal}
            disabledStyle='button-add__disabled'
            buttonElement={<ConfOrCancelEditFiltered handleModalClose={() => setShowModal(false)} tabName={toggle} />}
        >
            <AnimalsAddInfoTabs toggle={toggle} setToggle={setToggle} />
        </Modal>
    );
};

export default ModalFilteredEdit;
