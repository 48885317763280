import React, { useCallback } from 'react';
import AddAnimalsMarking from './AddAnimalsMarking';
import AddAnimalsSupervisedObjects from './AddAnimalsSupervisedObjects';

interface IAnimalsAddInfoTabs {
    toggle: string;
    setToggle: React.Dispatch<React.SetStateAction<'supervise' | 'mark'>>;
}
const AnimalsAddInfoTabs = ({ toggle, setToggle }: IAnimalsAddInfoTabs) => {
    const toggleTab = useCallback((index: 'supervise' | 'mark') => {
        setToggle(index);
    }, []);

    return (
        <div className='flex flex-col bg-white rounded-lg px-4 pt-4 pb-2 overflow-y-auto min-h-[400px]'>
            <div className='flex items-center w-full'>
                <div
                    role='presentation'
                    onClick={() => toggleTab('supervise')}
                    className={`font-bold test-base px-3 py-1 hover:text-blueHover cursor-pointer duration-300 ${
                        toggle === 'supervise' ? 'active-head-tabs' : 'text-blue border-b-[4px] border-white'
                    }`}
                >
                    Поднадзорные объекты
                </div>
                <div
                    role='presentation'
                    onClick={() => toggleTab('mark')}
                    className={`font-bold test-base px-3 py-1 hover:text-blueHover cursor-pointer duration-300 ${
                        toggle === 'mark' ? 'active-head-tabs' : 'text-blue border-b-[4px] border-white'
                    }`}
                >
                    Средства маркирования
                </div>
            </div>
            <div className='px-4 py-6'>
                <div className={`content-tabs ${toggle === 'supervise' ? 'active-tabs' : ''} `}>
                    {toggle === 'supervise' && <AddAnimalsSupervisedObjects />}
                </div>
                <div className={`content-tabs ${toggle === 'mark' ? 'active-tabs' : ''}`}>
                    {toggle === 'mark' && <AddAnimalsMarking />}
                </div>
            </div>
        </div>
    );
};

export default AnimalsAddInfoTabs;
